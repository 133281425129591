/**
 * Creates a download function. When executed, the download function accepts a Response and returns a Promise
 * containing the Response, if download was successful.
 * @param {Function} createObjectUrl - Function which accepts a Blob and returns a Promise<ObjectURL>.
 * Recommended implementation: window.URL.createObjectURL.
 * @param {Function} openUrl - Function which accepts (String url, String target) and returns void.
 * Recommended implementation: window.open
 * @param {Function} revokeObjectUrl - Function which accepts an ObjectUrl and returns void.
 * Recommended implementation: window.URL.revokeObjectURL
 * @return {Function}
 */
export function createOpenResponse(createObjectUrl, openUrl, revokeObjectUrl) {
  return async response => {
  // Read the response content as a Blob...
    const blob = await response.blob();

    // Then create an ObjectURL from it...
    const objectUrl = createObjectUrl(blob);

    // ... then open the URL in a new tab so it can be viewed
    openUrl(objectUrl, "_blank");

    // ...then revoke the ObjectURL. This prevents the in-browser-memory ObjectURL from being reused.
    revokeObjectUrl(objectUrl);

    return response;
  };
}

export default createOpenResponse;
