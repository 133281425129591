import { Button } from "reactstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Creates a send button
 *
 * @constructor
 * @param {Object} props - SendButton properties.
 * @param {Function} props.onClick - On click function.
 */
function SendButton(props) {
  return (
    <Button color="primary" onClick={props.onClick} outline title="Send">
      <FontAwesomeIcon icon={faPaperPlane} />
    </Button>
  );
}

SendButton.propTypes = {
  onClick: PropTypes.func
};

export default SendButton;
