// Third Party
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

export const subjectInputId = "draft-subject";

/**
 * Build the Subject field.
 *
 * @constructor
 * @param {Object} props - Subject properties.
 *
 */
function Subject({ onChange, subject, ...rest }) {
  /* eslint-disable arrow-body-style */
  const handleInputChange = event => {
    return onChange(event.target.value);
  };

  return (
    <Input
      id={subjectInputId}
      onChange={ handleInputChange }
      onKeyUp={ handleInputChange }
      type="text"
      value={ subject }
      {...rest}
    />
  );
}

Subject.propTypes = {
  onChange: PropTypes.func,
  subject: PropTypes.string
};

export default Subject;
