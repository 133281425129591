// Third Party
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

/**
 * Checkbox input.
 *
 * @param {Object} props - Rendering properties.
 * @param {Boolean} props.defaultChecked - Flag indicating if the checkbox is initially checked.
 * @param {Function} props.onChange - Callback executed when checkbox is (un)checked.
 * @param {String} props.value - The string to use as the value of the checkbox if the checkbox is currently toggled on.
 * @constructor
 */
function Checkbox({ defaultChecked, onChange, value, ...rest }) {
  // NOTE (jeremiah.sanders): We use ...rest in the props destructuring and in the Input below
  //   to allow component uses to modify inherited rendering properties without requiring this
  //   component to know or care about them.
  return (
    <Input
      defaultChecked={defaultChecked}
      onChange={onChange}
      value={value}
      type="checkbox"
      {...rest}
    />
  );
}

Checkbox.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default Checkbox;
