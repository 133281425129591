// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { folderNameUpdateFailure, folderNameUpdateSuccess } from "../../actions/messageNavigation/folderEditActions";
import { FOLDER_NAME_UPDATE_START } from "../../constants/ActionTypes.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const folderNameUpdateStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(FOLDER_NAME_UPDATE_START),
  mergeMap(action => from(
    messagingClient.putMailboxFolderName(action.mailboxAddress, action.folderId, action.displayName))
    .pipe(
      map(response => folderNameUpdateSuccess(action.mailboxAddress, response.folderId, response.displayName)),
      catchError(error => of(folderNameUpdateFailure(action.mailboxAddress, action.folderId, error)))
    )
  )
);

export default folderNameUpdateStartEpic;
