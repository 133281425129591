// Display Messages
export const DRAFT_ATTACHMENT_ADD_FAILURE_DISPLAY = "Failed to add attachment to the draft.";
export const DRAFT_ATTACHMENT_INVALID_FILE_SIZE_DISPLAY = "Failed to add attachment - invalid file size.";
export const DRAFT_ATTACHMENT_READ_FILE_FAILURE_DISPLAY = "Failed to add attachment - could not read file.";
export const DRAFT_ATTACHMENT_REMOVE_FAILURE_DISPLAY = "Failed to remove attachment from the draft.";
export const DRAFT_CREATED_ERROR_DISPLAY = "Failed to create a new draft.";
export const DRAFT_DISCARD_FAILURE_DISPLAY = "Failed to discard the draft message.";
export const DRAFT_INVALID_CC_EMAIL_ADDRESS_DISPLAY = "\"CC\" contains an invalid email address.";
export const DRAFT_INVALID_TO_EMAIL_ADDRESS_DISPLAY = "\"To\" contains an invalid email address.";
export const DRAFT_LIST_LOADING_FAILURE_DISPLAY = "Failed to retrieve draft message list.";
export const DRAFT_MOVE_OUTBOX_FAILURE_DISPLAY = "Failed to send message.";
export const DRAFT_RETRIEVAL_ERROR_DISPLAY = "Failed to retrieve requested draft.";
export const DRAFT_UPDATE_FAILURE_DISPLAY = "Failed up update draft message.";
export const FOLDER_CREATE_FAILURE_DISPLAY = "Failed to create folder.";
export const FOLDER_NAME_UPDATE_FAILURE_DISPLAY = "Failed to update folder name.";
export const MAILBOX_FOLDERS_LOADING_FAILURE_DISPLAY = "Failed to load mailbox folders list.";
export const MESSAGE_LIST_RETRIEVAL_ERROR_DISPLAY = "Failed to retrieve message list.";
export const MESSAGE_LOADING_ERROR_DISPLAY = "Failed to load message.";
export const MESSAGES_DELETE_FAILURE_DISPLAY = "Failed to delete messages.";
export const MESSAGES_MOVE_FOLDER_FAILURE_DISPLAY = "Failed to move message(s) to a new folder.";
export const MESSAGES_TRASH_FAILURE_DISPLAY = "Failed to move message(s) to trash.";
