import environment from "./environment.json";
import { postSignOutUnauthenticatedPath, responseHandlingPath } from "../constants/OidcPaths";

const applicationLocation = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

// This object configures OIDC for AADB2C.

// NOTE(jeremiah.sanders): "B2C tenant" refers to the matching Azure terminology. I.e., a B2C "user directory"/IdP.

// Root B2C tenant configuration.
//   These values are common to all applications using the same B2C tenant (i.e., OAuth app clients).
const b2cConfig = {
  // NOTE (jeremiah.sanders): This comes from the "domain name" value in the "overview" tab of the B2C tenant in Azure
  //   Portal. It is the subdomain of onmicrosoft.com.
  b2cTenant: environment.aadb2c.tenantDomain,
  // NOTE (jeremiah.sanders): This comes from the name of the user flow used by this application.
  //   User flows are found in "policies" > "user flows" of the B2C tenant. While this may vary by application, we
  //   should use a limited number of consistent policies across SPAs.
  userFlowPolicy: environment.aadb2c.signupSigninPolicy
};

// Application-specific configuration
//   These values are generally found within or derived from values listed with the application's registration inside
//   the B2C tenant.
//   I.e., Access the B2C tenant (directory), select the B2C blade, select "manage" > "app registrations", select the
//   application.
const appConfig =
{
  // NOTE (jeremiah.sanders): This comes from the "overview" tab of the app registration in Azure Portal.
  clientId: environment.aadb2c.clientId,
  // The local application route where we want users redirected to after logout.
  // NOTE (jeremiah.sanders): The fully-qualified, absolute URL corresponding to this route must be specified as
  //   "front-channel logout URL" found in the "manage" > "authentications" settings of the
  //   application registration.
  postLogoutRedirectRoute: postSignOutUnauthenticatedPath,
  // The local application route where we accept authentication tokens.
  // NOTE (jeremiah.sanders): The fully-qualified, absolute URL corresponding to this route must be included in the
  //   "single-page application" > "redirect uri(s)" list found in the "manage" > "authentications" settings of the
  //   application registration.
  redirectRoute: responseHandlingPath,
  scopes: [environment.aadb2c.scope]
};

const scopes = appConfig.scopes.reduce((previous, current) => `${previous} ${current}`, "").trim();

// NOTE (jeremiah.sanders): The object below must conform to UserManagerSettings interface exposed by oidc-client.
/* eslint-disable camelcase */
const aadB2cUserManagerConfig = {
  authority: `https://${b2cConfig.b2cTenant}.b2clogin.com/${b2cConfig.b2cTenant}.onmicrosoft.com/${b2cConfig.userFlowPolicy}/v2.0`,
  automaticSilentRenew: true,
  client_id: appConfig.clientId,
  // NOTE (jeremiah.sanders): Current AADB2C sign-up sign-in custom policy does not expose userinfo_endpoint.
  loadUserInfo: false,
  post_logout_redirect_uri: `${applicationLocation}/${appConfig.postLogoutRedirectRoute}`,
  redirect_uri: `${applicationLocation}/${appConfig.redirectRoute}`,
  // NOTE (jeremiah.sanders): Response type "code" means we'll use Proof-Key for Code Exchange (PKCE) OAuth flow.
  // See: https://oauth.net/2/pkce/
  response_type: "code",
  revokeAccessTokenOnSignout: true,
  scope: `openid ${scopes}`
};
/* eslint-enable camelcase */

export default aadB2cUserManagerConfig;
