// Third Party
import { combineEpics } from "redux-observable";

// Project
import messagesMoveTrashSuccessClearSelectedMessagesEpic from "./messagesMoveTrashSuccessClearSelectedMessagesEpic";
import messagesMoveTrashSuccessNoClearSelectedMessagesEpic from "./messagesMoveTrashSuccessNoClearSelectedMessagesEpic";

const messagesMoveTrashSuccessEpic = combineEpics(
  messagesMoveTrashSuccessClearSelectedMessagesEpic,
  messagesMoveTrashSuccessNoClearSelectedMessagesEpic
);

export default messagesMoveTrashSuccessEpic;
