// Third Party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { DRAFT_VIEW, MESSAGE_VIEW } from "../../constants/DetailViewPanes";
import DefaultView from "./DefaultView";
import DraftView from "../DraftView";
import MessageView from "../MessageView";

const mapDispatchToProps = {};
const mapStateToProps = state => ({
  pane: state.messaging.detailViewPane
});

/**
 * Determines which detailed view pane to display.
 *
 * @param {Object} props - Detail view properties.
 * @return {Object} React object containing the default-view contents.
 */
function DetailView(props) {
  switch (props.pane) {
    case DRAFT_VIEW: return <DraftView/>;

    case MESSAGE_VIEW: return <MessageView/>;

    default: return <DefaultView/>;
  }
}

DetailView.propTypes = {
  pane: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
