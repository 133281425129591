// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MESSAGE_LOADING_ERRED } from "../constants/ActionTypes";
import { messageLoadingEnd } from "../actions/messageViewActions";

const messageLoadingErredEpic = action$ => action$.pipe(
  ofType(MESSAGE_LOADING_ERRED),
  map(() => messageLoadingEnd())
);

export default messageLoadingErredEpic;
