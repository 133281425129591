// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the postFn with mailboxAddress and message identities.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {Array} messageIdentities - Message identities to move to trash.
 * @return {Promise.<MailboxDraft>}
 */
function postMailboxMessagesTrash(postFn, mailboxAddress, messageIdentities) {
  return mailboxAddress && messageIdentities ?
    postFn(`api/mailbox/${mailboxAddress}/messages/trash`, { messageIdentities })
      .then(response => response.json()) :
    Promise.reject(new Error("Mailbox address and message identities are required"));
}

/**
 * Creates a function which will request messages are moved to trash.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests messages are moved to trash when executed with a mailbox address and some
 * message identities and returns a Promise containing the API response.
 */
function createMessagesMoveTrashRequest(postFn) {
  const hasPostFn = postFn && isFunction(postFn);

  return (mailboxAddress, messageIdentities) => (hasPostFn ?
    postMailboxMessagesTrash(postFn, mailboxAddress, messageIdentities) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createMessagesMoveTrashRequest;
