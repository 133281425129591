// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { FOLDER_CREATE_SUCCESS } from "../constants/ActionTypes";
import { mailboxFoldersLoadingStart } from "../actions/messageNavigationActions";

const folderCreateSuccessEpic = action$ => action$.pipe(
  ofType(FOLDER_CREATE_SUCCESS),
  map(action => mailboxFoldersLoadingStart(action.mailboxAddress))
);

export default folderCreateSuccessEpic;
