// Project
import { formatEmailAddressDisplay } from "./FormatEmailAddressDisplay";

/**
 * Create an array of display strings  from an array of email address objects.
 *
 * @param {Array} emailObjects - Array of email objects.
 * @return {Array} Array of strings.
 */
function formatEmailAddressDisplayAsArray(emailObjects) {
  return Array.isArray(emailObjects) ?
    emailObjects.map(addressee => formatEmailAddressDisplay(addressee)) :
    [formatEmailAddressDisplay(emailObjects)];
}

export default formatEmailAddressDisplayAsArray;
