// Third Party
import isArray from "lodash/fp/isArray";
import isString from "lodash/fp/isString";

// Project
import * as types from "../../constants/ActionTypes";

/**
 * Creates a Request (to) Trash Messages action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {Array} messageIdentities - Some message identities.
 * @return {Object} Action.
 */
function messagesMoveTrashRequest(mailboxAddress, messageIdentities) {
  if (!isString(mailboxAddress)) {
    throw new Error("Mailbox address string is required");
  }

  if (!isArray(messageIdentities)) {
    throw new Error("Message identities must be an array");
  }

  return {
    mailboxAddress,
    messageIdentities,
    type: types.MESSAGES_MOVE_TRASH_REQUEST
  };
}

export default messagesMoveTrashRequest;
