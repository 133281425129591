// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { APPLICATION_INITIALIZED } from "../../constants/ActionTypes.js";
import { userRequested } from "../../actions/userActions.js";

const applicationInitializedEpic = action$ => action$.pipe(
  ofType(APPLICATION_INITIALIZED),
  map(() => userRequested())
);

export default applicationInitializedEpic;
