// Project
import createApplyCommonRequestOptions from "./createApplyCommonRequestOptions";

/**
 * Create an API PUT function (url, body -> Promise<Response>).
 *
 * @param {Function} putFn - Function to perform PUT request.
 * Expected to match the Fetch API (Request -> Promise<Response>).
 * @param {Object} clientParameters - Object containing API client parameters.
 * @param {String} clientParameters.rootUrl - Root URL for API.
 * @param {String} clientParameters.token - Bearer token for API authentication.
 * @return {Function} Function which accepts a URI and returns a request promise.
 */
function createPut(putFn, clientParameters) {
  const optionApplicator = createApplyCommonRequestOptions(clientParameters);

  return (url, body) => {
    const putRequest = optionApplicator(
      new Request(
        `${clientParameters.rootUrl}${url}`,
        {
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "PUT"
        }
      )
    );

    return putFn(putRequest);
  };
}

export default createPut;
