const initialAttachments = [];

const initialCc = {
  cachedAddressees: [],
  containsInvalid: false,
  workingAddress: "",
  workingAddressees: []
};

const initialTo = {
  cachedAddressees: [],
  containsInvalid: false,
  workingAddress: "",
  workingAddressees: []
};

const initialDraftMessageState = {
  attachments: initialAttachments,
  body: "",
  cc: initialCc,
  draftAutosaving: false,
  draftIdentity: "",
  from: {
    address: "",
    displayName: ""
  },
  subject: "",
  to: initialTo
};

/**
 * Converts an API draft attachment list to a draft view model attachment list.
 *
 * @param {Array} attachments - A draft attachment list.
 * @return {Object}
 */
function convertApiAttachmentsToViewModelAttachments(attachments) {
  return initialAttachments.concat(attachments);
}

/**
 * Converts API draft CC addressees to a draft view model CC object.
 *
 * @param {Array.<Object>} ccAddressees - Some CC addressees.
 * @return {Object}
 */
function convertApiCcToViewModelCc(ccAddressees) {
  return {
    ...initialCc,
    cachedAddressees: ccAddressees,
    workingAddressees: ccAddressees
  };
}

/**
 * Converts API draft To addressees to a draft view model To object.
 *
 * @param {Array.<Object>} toAddressees - Some TO addressees.
 * @return {Object}
 */
function convertApiToToViewModelTo(toAddressees) {
  return {
    ...initialTo,
    cachedAddressees: toAddressees,
    workingAddressees: toAddressees
  };
}

/**
 * Converts an API draft to a draft view model.
 *
 * @param {Object} draftFromAddressee - A mailbox addressee.
 * @param {Object} draftFromAddressee.address - A mailbox address.
 * @param {Object} draftFromAddressee.displayName - A display name.
 * @param {Object} apiDraft - A draft object returned from Messaging Service.
 * @return {Object}
 */
export function convertApiDraftToViewModel(draftFromAddressee, apiDraft) {
  return {
    ...initialDraftMessageState,
    addingAttachments: false,
    attachments: convertApiAttachmentsToViewModelAttachments(apiDraft.attachments),
    body: apiDraft.body,
    cc: convertApiCcToViewModelCc(apiDraft.ccAddresses),
    draftIdentity: apiDraft.draftIdentity,
    from: draftFromAddressee,
    subject: apiDraft.subject,
    to: convertApiToToViewModelTo(apiDraft.toAddresses)
  };
}

export default initialDraftMessageState;
