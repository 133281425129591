// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { notificationAdd } from "../../actions/notificationActions";
import { ERROR } from "../../constants/Severity";

/**
 * Creates an epic which emits a NOTIFICATION_ADD action of ERROR severity from actions matching the failureActionType.
 * @param {String} failureActionType - Failure action type constant.
 * @param {Function} getMessage - Function which creates a message for the notification, based upon the failure action.
 * @return {Function} Epic
 */
function fromFailureAction(failureActionType, getMessage) {
  return action$ => action$.pipe(
    ofType(failureActionType),
    map(action => notificationAdd(getMessage(action), ERROR, action))
  );
}

export default fromFailureAction;
