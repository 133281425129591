// Third Party
import { Button } from "reactstrap";
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./ApplicationUserInfo.css";
import Icon from "./Icon";

/**
 * Username container.
 *
 * @private
 * @constructor
 * @param {Object} props - User properties.
 * @para {Object} props.userName - User name.
 */
function UserName(props) {
  return (
    <span aria-label="User name" className="user-name align-self-center">
      { props.userName }
    </span>
  );
}

UserName.propTypes = {
  userName: PropTypes.string.isRequired
};

/**
 * Sign out button.
 *
 * @private
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Function} props.onClick - On click handler.
 */
function SignOutButton(props) {
  return (
    <Button aria-label="Sign Out" className="sign-out-button" onClick={props.onClick} size="xs" title="Sign Out">
      <Icon><FontAwesomeIcon icon={faSignOutAlt} title="Sign Out" /></Icon>
    </Button>
  );
}

SignOutButton.propTypes = {
  onClick: PropTypes.func
};

/**
 * User information container.
 *
 * @constructor
 * @param {Object} props - User properties.
 * @param {Function} props.onSignOutClick - Callback when sign out button is clicked.
 * @param {String} props.userName - User name.
 */
function ApplicationUserInfo(props) {
  return (
    <div id="user-info-wrapper" className="d-flex" aria-label="User session">
      <Icon className="user-icon align-self-center">
        <FontAwesomeIcon icon={faUser} />
      </Icon>
      <UserName userName={props.userName}/>
      <SignOutButton onClick={props.onSignOutClick} />
    </div>
  );
}

ApplicationUserInfo.propTypes = {
  onSignOutClick: PropTypes.func,
  userName: PropTypes.string.isRequired
};

export default ApplicationUserInfo;
