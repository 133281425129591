// Project
import { tryGetUserDisplayName, tryGetUserIdentity } from "../selectors/oidcUser";
import { USER_CHANGED, USER_MAILBOXES_RETRIEVED } from "../constants/ActionTypes.js";
import baseUser from "../config/baseUser";
import initialState from "../config/initialState";

/**
 * Handles user changed actions.
 *
 * @param {Object} state - An application state.
 * @param {Object} action - An action.
 * @return {Object} An application state.
 */
function handleUserChanged(state, action) {
  // NOTE(jeremiah.sanders): The action.user property is an oidc-client User object.
  const oidcUser = action.user;

  return {
    ...state,
    user: {
      ...baseUser,
      displayName: tryGetUserDisplayName(oidcUser),
      identity: tryGetUserIdentity(oidcUser)
    }
  };
}

/**
 * Handle user mailboxes retrieved actions.
 *
 * @param {Object} state - An application state.
 * @param {Object} action - An action.
 * @return {Object} An application state.
 */
function handleUserMailboxesRetrieved(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      mailboxes: action.mailboxes
    }
  };
}

/**
 * Handles all user-related actions.
 *
 * @param {Object} state - An application state.
 * @param {Object} action - An action.
 * @return {Object} An application state.
 */
function userReducer(state = initialState, action) {
  if (state) {
    if (action && action.type) {
      switch (action.type) {
        case USER_CHANGED:
          return handleUserChanged(state, action);

        case USER_MAILBOXES_RETRIEVED:
          return handleUserMailboxesRetrieved(state, action);

        default:
          return state;
      }
    }

    return state;
  }


  return initialState;
}

export default userReducer;
