/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
// Third Party
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import React, { useState } from "react";

// Project
import "./DraftView.css";
import { bodyChanged } from "../../actions/draftBodyActions";
import { ccAddressChanged, ccWorkingAddressInProgressChange, removeCcAddressee } from "../../actions/draftCcActions";
import { draftAttachmentFilesSelected, draftAttachmentRemoveRequest, draftAttachmentSelected } from "../../actions/draftAttachmentsActions";
import { draftDiscardRequested } from "../../actions/draftMessage/draftDeleteActions";
import { draftMoveOutboxRequested } from "../../actions/draftMessage/draftMoveToOutboxActions";
import { removeToAddressee, toAddressChanged, toWorkingAddressInProgressChange } from "../../actions/draftToActions";
import { subjectChanged } from "../../actions/draftSubjectActions";
import * as applicationConfig from "../../config/application";
import Attachments from "./Attachments";
import Body from "./Body";
import Header from "./Header";
import useDebounce from "../../hooks/useDebounce";

/**
 * Draft detail view for the application.
 *
 * @constructor
 * @param {Object} props - Draft view properties.
 */
function DraftView(props) {
  // NOTE(jeremiah.sanders): This local state tracking draftIdentity is needed so that we can detect and reset local
  //   state when the props change. React only initializes local state during the initial render; changing props,
  //   e.g., when the selected draft changes, does not re-initialize state.
  //   See: https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  const [currentDraft, setCurrentDraft] = useState(props.draftIdentity);

  // Initialize local state.
  const [workingSubject, setWorkingSubject] = useState(props.subject);
  const [workingBody, setWorkingBody] = useState(props.body);

  // NOTE(jeremiah.sanders): The `istanbul ignore` below instructs Jest to ignore coverage for the `if`.
  //   The logic in this block only executes when the component is reused by React, but with different
  //   `props`. I'm unaware of how to replicate that in tests.
  //   See: https://github.com/istanbuljs/nyc#parsing-hints-ignoring-lines
  /* istanbul ignore if */
  if (props.draftIdentity !== currentDraft) {
    // Props changed. We must reinitialize local state.

    // Update the props-changed tracking variable.
    setCurrentDraft(props.draftIdentity);

    // Update the dependent, local state.
    setWorkingSubject(props.subject);
    setWorkingBody(props.body);
  }

  const debounceDelayInMs = isNumber(props.debounceDelay) ?
    props.debounceDelay :
    applicationConfig.draftView.debounceTime;

  const debouncedSubjectChange = useDebounce({ callback: newSubject => props.onSubjectChanged(
    props.mailbox.address,
    props.draftIdentity,
    newSubject,
    true
  ),
  delay: debounceDelayInMs,
  dependencies: [props.draftIdentity] });

  const debouncedBodyChange = useDebounce({ callback: newBody => props.onBodyChanged(
    props.mailbox.address,
    props.draftIdentity,
    newBody,
    true
  ),
  delay: debounceDelayInMs,
  dependencies: [props.draftIdentity] });

  const ccAddressees = props.ccAddressees && props.ccAddressees.length ? props.ccAddressees : [];
  const toAddressees = props.toAddressees && props.toAddressees.length ? props.toAddressees : [];
  const onBodyChangedHandler = newBody => {
    debouncedBodyChange(newBody);
    setWorkingBody(newBody);
  };
  const onCcAddressBlurredHandler = newCcAddresses => props.onCcAddressBlurred(
    props.mailbox.address,
    props.draftIdentity,
    newCcAddresses
  );
  const onCcAddressChangedHandler = newCcAddresses => props.onCcAddressChanged(
    props.mailbox.address,
    props.draftIdentity,
    newCcAddresses
  );
  const onCcAddresseeClickedHandler = addressToRemove => props.onCcAddresseeClicked(
    props.mailbox.address,
    props.draftIdentity,
    addressToRemove
  );
  const onDiscardClickedHandler = () => props.onDiscardClicked(props.mailbox.address, [props.draftIdentity]);
  const onSubjectChangedHandler = newSubject => {
    debouncedSubjectChange(newSubject);
    setWorkingSubject(newSubject);
  };
  const onSendClickHandler = () => props.onSendClicked(props.mailbox.address, props.draftIdentity);
  const onToAddressBlurredHandler = newToAddresses => props.onToAddressBlurred(
    props.mailbox.address,
    props.draftIdentity,
    newToAddresses
  );
  const onToAddressChangedHandler = newToAddresses => props.onToAddressChanged(
    props.mailbox.address,
    props.draftIdentity,
    newToAddresses
  );
  const onToAddresseeClickedHandler = addressToRemove => props.onToAddresseeClicked(
    props.mailbox.address,
    props.draftIdentity,
    addressToRemove
  );
  const onAttachmentClick = attachmentIdentifier => props.onAttachmentClick(
    props.mailbox.address,
    props.draftIdentity,
    attachmentIdentifier
  );
  const onAttachmentRemoveClick = attachmentIdentifier => props.onAttachmentRemoveClick(
    props.mailbox.address,
    props.draftIdentity,
    attachmentIdentifier
  );
  const onFilesSelected = files => props.onAttachmentFilesSelected(
    props.mailbox.address,
    props.draftIdentity,
    Array.from(files)
  );

  return (
    <Card aria-label="Draft composer" className="draft-view-wrapper">
      <CardHeader>
        <Header
          ccAddressees={ccAddressees}
          mailbox={props.mailbox}
          onCcAddressBlur={onCcAddressBlurredHandler}
          onCcAddressChange={onCcAddressChangedHandler}
          onCcAddresseeClick={onCcAddresseeClickedHandler}
          onDiscardClick={onDiscardClickedHandler}
          onSendClick={onSendClickHandler}
          onSubjectChange={onSubjectChangedHandler}
          onToAddressBlur={onToAddressBlurredHandler}
          onToAddressChange={onToAddressChangedHandler}
          onToAddresseeClick={onToAddresseeClickedHandler}
          subject={workingSubject}
          toAddressees={toAddressees}
          workingCcAddress={props.workingCcAddress}
          workingToAddress={props.workingToAddress}
        />
      </CardHeader>
      <CardBody>
        <Body
          body={workingBody}
          onChange={onBodyChangedHandler}
        />
      </CardBody>
      <CardFooter>
        <Attachments
          attachments={props.attachments}
          onAttachmentClick={onAttachmentClick}
          onAttachmentRemoveClick={onAttachmentRemoveClick}
          onFilesSelected={onFilesSelected}
        />
      </CardFooter>
    </Card>
  );
}

DraftView.propTypes = {
  attachments: PropTypes.array,
  body: PropTypes.string,
  ccAddressees: PropTypes.array,
  debounceDelay: PropTypes.number,
  draftIdentity: PropTypes.string,
  mailbox: PropTypes.object.isRequired,
  onAttachmentClick: PropTypes.func,
  onAttachmentFilesSelected: PropTypes.func,
  onAttachmentRemoveClick: PropTypes.func,
  onBodyChanged: PropTypes.func,
  onCcAddressBlurred: PropTypes.func,
  onCcAddressChanged: PropTypes.func,
  onCcAddresseeClicked: PropTypes.func,
  onDiscardClicked: PropTypes.func,
  onSendClicked: PropTypes.func,
  onSubjectChanged: PropTypes.func,
  onToAddressBlurred: PropTypes.func,
  onToAddressChanged: PropTypes.func,
  onToAddresseeClicked: PropTypes.func,
  subject: PropTypes.string,
  toAddressees: PropTypes.array,
  workingCcAddress: PropTypes.string,
  workingToAddress: PropTypes.string
};

const mapDispatchToProps = {
  onAttachmentClick: draftAttachmentSelected,
  onAttachmentFilesSelected: draftAttachmentFilesSelected,
  onAttachmentRemoveClick: draftAttachmentRemoveRequest,
  onBodyChanged: bodyChanged,
  onCcAddressBlurred: ccAddressChanged,
  onCcAddressChanged: ccWorkingAddressInProgressChange,
  onCcAddresseeClicked: removeCcAddressee,
  onDiscardClicked: draftDiscardRequested,
  onSendClicked: draftMoveOutboxRequested,
  onSubjectChanged: subjectChanged,
  onToAddressBlurred: toAddressChanged,
  onToAddressChanged: toWorkingAddressInProgressChange,
  onToAddresseeClicked: removeToAddressee
};

// NOTE(jeremiah.sanders): debounceDelay is intentionally omitted to allow it to be populated from props.
const mapStateToProps = state => ({
  attachments: state.messaging.draftView.attachments,
  body: state.messaging.draftView.body,
  ccAddressees: state.messaging.draftView.cc.workingAddressees,
  draftIdentity: state.messaging.draftView.draftIdentity,
  mailbox: state.messaging.mailbox,
  subject: state.messaging.draftView.subject,
  toAddressees: state.messaging.draftView.to.workingAddressees,
  workingCcAddress: state.messaging.draftView.cc.workingAddress,
  workingToAddress: state.messaging.draftView.to.workingAddress
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftView);
