/**
 * Try to get the OIDC User's display name.
 * @param {Object} oidcUser - User from oidc-client.
 * @return {String | undefined}
 */
export function tryGetUserDisplayName(oidcUser) {
  return oidcUser?.profile?.name;
}

/**
 * Try to get the OIDC User's identity (subject claim).
 * @param {Object} oidcUser - User from oidc-client.
 * @return {String | undefined}
 */
export function tryGetUserIdentity(oidcUser) {
  return oidcUser?.profile?.sub;
}
