// Third Party
import { debounceTime, groupBy, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { toAddressUpdateStart } from "../../actions/draftToActions";
import { DRAFT_TO_WORKING_ADDRESS_IN_PROGRESS_CHANGE } from "../../constants/ActionTypes";
import * as applicationConfig from "../../config/application";

const debounceLength = applicationConfig.draftView.debounceTime;
const isAutosaving = true;

/* eslint-disable arrow-body-style */
const toWorkingAddressInProgressChangedEpic = action$ => action$.pipe(
  ofType(DRAFT_TO_WORKING_ADDRESS_IN_PROGRESS_CHANGE),
  groupBy(action => {
    return `${action.mailboxAddress}-${action.draftIdentity}`;
  }),
  mergeMap(group$ => group$.pipe(
    debounceTime(debounceLength),
    map(action => toAddressUpdateStart(action.mailboxAddress, action.draftIdentity, isAutosaving)))
  )
);

export default toWorkingAddressInProgressChangedEpic;
