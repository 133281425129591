// Third Party
import PropTypes from "prop-types";
import React from "react";

// Project
import { formatEmailAddressDisplay } from "../../util/FormatEmailAddressDisplay";

/**
 *  Builder for the From address field
 *
 * @constructor
 * @param {Obect} props - From Address properties.
 */
function FromAddress(props) {
  const emailAddress = formatEmailAddressDisplay(props.mailbox);

  return (
    <span id="draft-from-address">{ emailAddress }</span>
  );
}

FromAddress.propTypes = {
  mailbox: PropTypes.object.isRequired
};

export default FromAddress;

