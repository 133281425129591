// Project
import { DEFAULT_VIEW } from "../../constants/DetailViewPanes";
import { DRAFT_DISCARD_SUCCESS } from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Set the detail view to blank and clear the currently-selected draft identity.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function draftDiscardSuccess(state, action) {
  const deletingCurrentDraft = action.draftIdentities.find(value => state.draftList.currentDraftId === value);
  const viewPane = deletingCurrentDraft ? DEFAULT_VIEW : state.detailViewPane;
  const currentDraftId = deletingCurrentDraft ? "" : state.draftList.currentDraftId;

  return {
    ...state,
    detailViewPane: viewPane,
    draftList: {
      ...state.draftList,
      currentDraftId: currentDraftId
    }
  };
}

/**
 *  Default draft reducer for discarding draft messages.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function draftDeleteReducers(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_DISCARD_SUCCESS: return draftDiscardSuccess(state, action);

      default: return state;
    }
  }

  return state;
}

export default draftDeleteReducers;
