const dataUrlPrefix = "base64,";
const dataUrlPrefixLength = dataUrlPrefix.length;

/**
 * Trim a Data-URL obtained from FileReader to its base-64 component.
 *
 * @param {String} dataUrl - A Data-URL obtained from FileReader.
 * @return {String} - Base-64-encoded content substring.
 * @private
 */
function getBase64FromDataUrl(dataUrl) {
  // .ReadAsDataURL() sets .result to a Data-URL. Real example: "data:text/xml;base64,PFRoaW5nPjwvVGhpbmc+"
  // See: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL

  return dataUrl.substring(dataUrl.indexOf(dataUrlPrefix) + dataUrlPrefixLength);
}

/**
 * Asynchronously loads a File using FileReader API and returns its content as a base-64 string.
 *
 * @param {File} file - A File.
 * @return {Promise<String>} - An asynchronous Promise representing the file load process.
 */
function loadFileContentAsBase64Async(file) {
  return new Promise((resolve, reject) => {
    const errorHandler = () => {
      reject(new Error(`Failed to read ${file.name || "file"}`));
    };

    try {
    // NOTE (jeremiah.sanders): FileReader.readAsDataURL() is used because Safari does not support the Blob.text() API.
      const fileReader = new FileReader();

      // Arrange the loading finished callback.
      fileReader.onload = () => {
        resolve(getBase64FromDataUrl(fileReader.result));
      };

      fileReader.onerror = errorHandler;

      // Initiate the file read.
      fileReader.readAsDataURL(file);
    } catch {
      errorHandler();
    }
  });
}

export default loadFileContentAsBase64Async;
