// Third party
import isFunction from "lodash/fp/isFunction";
import postMailboxMessageDerive from "./postMailboxMessageDerive";

/**
 * Creates a function which will request a reply-all derived draft.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests a reply-all message-derived mailbox draft be created when executed with a
 * mailbox address and message identity and returns a Promise containing the created draft identity.
 */
function createRequestReplyAllDerivedDraft(postFn) {
  const hasPostFn = postFn && isFunction(postFn);

  return (mailboxAddress, messageIdentity) => (hasPostFn ?
    postMailboxMessageDerive(postFn, mailboxAddress, messageIdentity, "replyAll") :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createRequestReplyAllDerivedDraft;
