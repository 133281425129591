// Project
import {
  DRAFTS_MULTI_SELECT_ITEMS_ADD,
  DRAFTS_MULTI_SELECT_ITEMS_CLEAR,
  DRAFTS_MULTI_SELECT_ITEMS_REMOVE
} from "../constants/ActionTypes";

/**
 * Signals that a draft or drafts have been multi-selected.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @param {Array} draftIdentities - Array of draft identities.
 * @return {Object} Action object.
 */
export function draftListMultiSelectItemsAdd(mailboxAddress, draftIdentities) {
  return {
    draftIdentities: draftIdentities,
    mailboxAddress: mailboxAddress,
    type: DRAFTS_MULTI_SELECT_ITEMS_ADD
  };
}

/**
 * Signal the intent to clear the selected drafts.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @return {Object} Action object.
 */
export function draftListMultiSelectItemsClear(mailboxAddress) {
  return {
    mailboxAddress: mailboxAddress,
    type: DRAFTS_MULTI_SELECT_ITEMS_CLEAR
  };
}

/**
 * Signals the intent to deselect a draft or draft that has been multi-selected.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} draftIdentities - Array of draft identities.
 * @return {Object} Action object.
 */
export function draftListMultiSelectItemsRemove(mailboxAddress, draftIdentities) {
  return {
    draftIdentities: draftIdentities,
    mailboxAddress: mailboxAddress,
    type: DRAFTS_MULTI_SELECT_ITEMS_REMOVE
  };
}
