// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_RETRIEVAL_ERROR_DISPLAY } from "../../constants/Errors";
import { DRAFT_RETRIEVAL_FAILURE } from "../../constants/ActionTypes";
import fromFailureAction from "../util/fromFailureAction";

const draftRetrievalFailureEpic = fromFailureAction(
  DRAFT_RETRIEVAL_FAILURE,
  constant(DRAFT_RETRIEVAL_ERROR_DISPLAY)
);

export default draftRetrievalFailureEpic;
