// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the getFn with mailboxAddress and draftIdentity, if they are provided.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft identity to retrieve.
 * @return {Promise.<MailboxDraft>}
 */
function getMailboxDraft(getFn, mailboxAddress, draftIdentity) {
  return mailboxAddress && draftIdentity ?
    getFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}`)
      .then(response => response.json())
      .then(body => body.draft) :
    Promise.reject(new Error("Mailbox address and draft identity are required"));
}

/**
 * Creates a function which will get the a mailbox draft.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets a mailbox draft when executed with a mailbox address and draft identity.
 */
function createGetMailboxDraft(getFn) {
  const hasGetFn = getFn && isFunction(getFn);

  return (mailboxAddress, draftIdentity) => (hasGetFn ?
    getMailboxDraft(getFn, mailboxAddress, draftIdentity) :
    Promise.reject(new Error("HTTP GET function is required but was not provided")));
}

export default createGetMailboxDraft;
