
// Project
import * as types from "../constants/ActionTypes";
import * as applicationConfig from "../config/application";

const { defaultPageSize } = applicationConfig.messageList;

/**
 * Creates a message list loading erred action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} folderId - Folder identity.
 * @param {Error} error - An error.
 * @return {Object} Error action.
 */
export function messageListLoadingErred(mailboxAddress, folderId, error) {
  return {
    error,
    folderId,
    mailboxAddress,
    type: types.MESSAGE_LIST_LOADING_ERRED
  };
}

/**
 * Signals that the message-list loading attempt has begun.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} folderId - Folder identity.
 * @param {Number} offset - Number of messages to skip (in descending received date) before selecting pageSize messages.
 * @param {Number} pageSize - Maximum number of message headers.
 * @return {Object} Message list loading action.
 */
export function messageListLoadingStart(mailboxAddress, folderId = "inbox", offset = 0, pageSize = defaultPageSize) {
  return {
    folderId,
    mailboxAddress,
    offset,
    pageSize,
    type: types.MESSAGE_LIST_LOADING_START
  };
}

/**
 * Signals that the message-list loading attempt has completed.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} folderId - Folder identity.
 * @return {Object} Loading action.
 */
export function messageListLoadingEnd(mailboxAddress, folderId) {
  return {
    folderId,
    mailboxAddress,
    type: types.MESSAGE_LIST_LOADING_END
  };
}

/**
 * Creates a message list requested action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} folderId - Folder identity.
 * @param {Number} offset - Number of messages to skip (in descending received date) before selecting pageSize messages.
 * @param {Number} pageSize - Maximum number of message headers.
 * @return {Object} Message list requested action.
 */
export function messageListRequested(mailboxAddress, folderId = "inbox", offset = 0, pageSize = defaultPageSize) {
  return {
    folderId,
    mailboxAddress,
    offset,
    pageSize,
    type: types.MESSAGE_LIST_REQUESTED
  };
}

/* eslint-disable max-params */
/**
 * Creates a message message list retrieved action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} folderId - Folder identity.
 * @param {Number} offset - Requested message offset.
 * @param {Number} pageSize - Requested page size.
 * @param {Array} messageHeaders - Mailbox message headers.
 * @return {Object} Message list action.
 */
export function messageListRetrieved(mailboxAddress, folderId, offset = 0, pageSize = defaultPageSize,
  messageHeaders = []) {
  return {
    folderId,
    mailboxAddress,
    messageHeaders,
    offset: offset,
    pageSize: pageSize,
    type: types.MESSAGE_LIST_RETRIEVED
  };
}
/* eslint-enable max-params */

/**
 * Creates a message selected action (a specific message was selected from the message list).
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - Message id to load.
 * @return {Object} Click action.
 */
export function messageSelected(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_SELECTED
  };
}
