// Third Party
import { filter, mergeMap } from "rxjs/operators";
import { from } from "rxjs";
import { ofType } from "redux-observable";
import { isEmpty } from "lodash";

// Project
import { DRAFT_ATTACHMENT_FILES_SELECTED } from "../../constants/ActionTypes.js";
import { draftAttachmentAddFailure, draftAttachmentAddSave } from "../../actions/draftAttachmentsActions";
import validAttachmentSize from "../../util/validAttachmentSize";
import { DRAFT_ATTACHMENT_INVALID_FILE_SIZE_DISPLAY, DRAFT_ATTACHMENT_READ_FILE_FAILURE_DISPLAY } from "../../constants/Errors";

/**
 * Asynchronously loads a File using FileReader API, converting it to an Add Attachment request object.
 *
 * @param {Function<File,String>} loadFileContentAsBase64Async - A Function which takes a File and returns its content
 * as a base-64 encoded byte string.
 * @param {File} file - A File.
 * @return {Promise} - An asynchronous Promise representing the file load process.
 * @private
 */
function loadAttachmentAsync(loadFileContentAsBase64Async, file) {
  return loadFileContentAsBase64Async(file)
    .then(data => ({
      "contentType": file.type,
      "data": data,
      "fileName": file.name
    }), () => Promise.reject(file));
}

/**
 * Partitions an Array of File contents into multiple arrays, based on domain validation.
 * @param {Function<File,Number>} getFileSize - A Function which takes a File and returns its size.
 * @param {Array} files - An Array of File object.
 * @return {Object} - An object containing invalidSizeFiles, invalidTypeFiles, and validFiles.
 * @private
 */
function partitionFilesByValidation(getFileSize, files) {
  return files.reduce((previousSummary, file) => {
    if (!validAttachmentSize(getFileSize(file))) {
      return { ...previousSummary,
        invalidSizeFiles: [...previousSummary.invalidSizeFiles, file] };
    }

    return { ...previousSummary,
      validFiles: [...previousSummary.validFiles, file] };
  }, { invalidSizeFiles: [],
    validFiles: [] });
}

/**
 * Asynchronously attempts to load each attachment.
 * @param {Function<File,String>} loadFileContentAsBase64Async - A Function which takes a File and returns its content
 * as a base-64 encoded byte string.
 * @param {Array} validFiles - An Array of File objects.
 * @return {Promise} - Returns a promise containing successful and failed file loading results.
 * @private
 */
async function tryLoadAttachments(loadFileContentAsBase64Async, validFiles) {
  const loadResults = await Promise.allSettled(
    validFiles.map(file => loadAttachmentAsync(loadFileContentAsBase64Async, file))
  );

  // NOTE (jeremiah.sanders): Promise.allSettled returns a Promise<Array> of { reason?, status, value? } objects. See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
  return {
    // NOTE (jeremiah.sanders): Since we Promise.reject(file) in loadAttachmentAsync, result.reason is a File.
    failures: loadResults.filter(result => result.reason).map(result => result.reason),
    successes: loadResults.filter(result => result.value).map(result => result.value)
  };
}

const invalidSizeError = new Error(DRAFT_ATTACHMENT_INVALID_FILE_SIZE_DISPLAY);
const fileLoadError = new Error(DRAFT_ATTACHMENT_READ_FILE_FAILURE_DISPLAY);

/**
 * Load attachment files.
 * @param {Object} io - IO dependency facade.
 * @param {Object} action - A draft attachment files selected action.
 * @return {Promise} - A promise which resolves to a draft attachments selected action.
 * @private
 */
async function loadAttachmentFilesAsync(io, { draftIdentity, files, mailboxAddress }) {
  const { invalidSizeFiles, validFiles } = partitionFilesByValidation(io.getFileSize, files);
  const { failures, successes } = await tryLoadAttachments(io.loadFileContentAsBase64Async, validFiles);

  const sizeFailureActions = invalidSizeFiles
    .map(file => draftAttachmentAddFailure(mailboxAddress, draftIdentity, file.name, invalidSizeError));
  const loadFailureActions = failures
    .map(file => draftAttachmentAddFailure(mailboxAddress, draftIdentity, file.name, fileLoadError));
  const successAction = isEmpty(successes) ?
    [] :
    [draftAttachmentAddSave(mailboxAddress, draftIdentity, successes[0], successes.slice(1))];

  return sizeFailureActions
    .concat(loadFailureActions)
    .concat(successAction);
}

const hasFiles = action => action.files && !isEmpty(action.files);

/**
 * Creates an Observable which responds to DRAFT_ATTACHMENT_FILES_SELECTED actions, emitting
 * DRAFT_ATTACHMENT_ADD_SAVE or DRAFT_ATTACHMENT_ADD_FAILURE actions.
 *
 * @param {Observable<Action>} action$ - Action observable stream.
 * @param {Observable<Object>} state$ - State observable stream.
 * @param {Object} dependencies - Dependencies. Corresponds to "dependencies" property on the epic options passed to
 * createEpicMiddleware().
 * @return {Observable<Action>}
 */
function draftAttachmentFilesSelectedEpic(action$, state$, { io }) {
  return action$.pipe(
    ofType(DRAFT_ATTACHMENT_FILES_SELECTED),
    filter(hasFiles),
    mergeMap(action => from(loadAttachmentFilesAsync(io, action)) // Create an Observable from the Promise
      .pipe(
        // Convert each element of the Array (in the Promise) to an Observable and lift them individually to the stream.
        mergeMap(from)
      )
    )
  );
}

export default draftAttachmentFilesSelectedEpic;
