// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { NOTIFICATION_ADD } from "../../constants/ActionTypes.js";
import { notificationDismissDelayed } from "../../actions/notificationActions.js";

const notificationAddEpic = action$ => action$.pipe(
  ofType(NOTIFICATION_ADD),
  map(action => notificationDismissDelayed(action.triggeringAction?.type, action.timestamp))
);

export default notificationAddEpic;
