// Project
import {
  CURRENT_FOLDER_WORKING_NAME_CHANGED,
  EDIT_FOLDER_NAME_ABORT,
  EDIT_FOLDER_NAME_BEGIN,
  FOLDER_NAME_UPDATE_SUCCESS
} from "../../constants/ActionTypes";

/**
 * Set the current working folder name in state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object.
 * @return {Object} Updated application state.
 * @return {Object} Update application state.
 */
function currentFolderWorkingNameChanged(state, action) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderWorkingDisplayName: action.currentFolderWorkingDisplayName
    }
  };
}

/**
 * Set the folder-name flag to false in  state.
 *
 * @param {Object} state - Application state.
 * @return {Object} Updated application state.
 */
function editFolderNameAbort(state) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderWorkingDisplayName: "",
      editingFolderName: false
    }
  };
}

/**
 * Set the editing folder name flag to true in state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action.
 * @return {Object} Update application state.
 */
function editFolderNameBegin(state, action) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderWorkingDisplayName: action.currentFolderName,
      editingFolderName: true
    }
  };
}

/**
 * Set the folder-name edit flag to false and clear the working folder name.
 *
 * @param {Object} state - Application state.
 * @return {Object} Update application state.
 */
function folderNameUpdateSuccess(state) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderWorkingDisplayName: "",
      editingFolderName: false
    }
  };
}

/**
 * Combine folder-editing reducers.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object;
 * @return {Object} Application state.
*/
function editFolderNameReducer(state, action) {
  if (action && action.type) {
    switch (action.type) {
      case CURRENT_FOLDER_WORKING_NAME_CHANGED: return currentFolderWorkingNameChanged(state, action);

      case EDIT_FOLDER_NAME_ABORT: return editFolderNameAbort(state);

      case EDIT_FOLDER_NAME_BEGIN: return editFolderNameBegin(state, action);

      case FOLDER_NAME_UPDATE_SUCCESS: return folderNameUpdateSuccess(state);

      default: return state;
    }
  } else {
    return state;
  }
}

export default editFolderNameReducer;
