// Third Party
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import DerivedDraftButton from "./DerivedDraftButton";

/**
 * Creates a Reply button.
 *
 * @constructor
 * @param {Object} props - Derived draft button properties
 */
function ReplyButton(props) {
  return (
    <DerivedDraftButton onClick={props.onClick} title="Reply">
      <FontAwesomeIcon icon={faReply} />
    </DerivedDraftButton>
  );
}

ReplyButton.propTypes = {
  onClick: PropTypes.func
};

export default ReplyButton;
