// Third Party
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import AddressPillbox from "../AddressPills/AddressPillbox";

export const toAddressInputId = "draft-to-address";

/* eslint-disable max-lines-per-function */
/**
 * Draft To addressees.
 *
 * @constructor
 * @param {Object} props - Rendering properties.
 */
function ToAddressees(props) {
  /* eslint-disable arrow-body-style */
  const handleInputBlur = event => {
    return props.onBlur(event.target.value);
  };

  /* eslint-disable arrow-body-style */
  const handleInputChange = event => {
    return props.onChange(event.target.value);
  };

  return (
    <div id="draft-to-address-wrapper">
      <AddressPillbox
        addressees={ props.addressees }
        onAddresseeClick={ props.onToAddresseeClick }
      />
      <Input
        value={ props.workingToAddress }
        id={toAddressInputId}
        onBlur = { handleInputBlur }
        onChange={ handleInputChange }
        onKeyUp={ handleInputChange }
        type="text"
      />
    </div>
  );
}
/* eslint-enable max-lines-per-function */

ToAddressees.propTypes = {
  addressees: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onToAddresseeClick: PropTypes.func,
  workingToAddress: PropTypes.string
};

export default ToAddressees;
