// Project
import { DEFAULT_VIEW } from "../../constants/DetailViewPanes";
import { MESSAGES_DELETE_SUCCESS } from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Set the detail view to blank and clear the currently-selected message identity.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function messagesDeleteSuccess(state, action) {
  const deletingCurrentMessage = action.messageIdentities.find(value => state.messageList.currentMessageId === value);
  const viewPane = deletingCurrentMessage ? DEFAULT_VIEW : state.detailViewPane;
  const currentMessageId = deletingCurrentMessage ? "" : state.messageList.currentMessageId;

  return {
    ...state,
    detailViewPane: viewPane,
    messageList: {
      ...state.messageList,
      currentMessageId: currentMessageId
    }
  };
}

/**
 * Messages delete reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Application state.
 */
function messagesDeleteReducers(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case MESSAGES_DELETE_SUCCESS: return messagesDeleteSuccess(state, action);

      default: return state;
    }
  }

  return state;
}

export default messagesDeleteReducers;
