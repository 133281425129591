// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_ATTACHMENT_REMOVE_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_ATTACHMENT_REMOVE_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftAttachmentRemoveFailureEpic = fromFailureAction(
  DRAFT_ATTACHMENT_REMOVE_FAILURE,
  constant(DRAFT_ATTACHMENT_REMOVE_FAILURE_DISPLAY)
);

export default draftAttachmentRemoveFailureEpic;
