// Third Party
import { combineEpics } from "redux-observable";

// Project
import draftDiscardSuccessNoClearSelectedDraftsEpic from "./draftDiscardSuccessNoClearSelectedDraftsEpic";
import draftDiscardSuccessClearSelectedDraftsEpic from "./draftDiscardSuccessClearSelectedDraftsEpic";

const draftDiscardSuccessEpic = combineEpics(
  draftDiscardSuccessNoClearSelectedDraftsEpic,
  draftDiscardSuccessClearSelectedDraftsEpic
);

export default draftDiscardSuccessEpic;
