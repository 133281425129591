// Third Party
import { constant } from "lodash";

// Project
import { MESSAGE_LIST_LOADING_ERRED } from "../../../constants/ActionTypes";
import { MESSAGE_LIST_RETRIEVAL_ERROR_DISPLAY } from "../../../constants/Errors";
import fromFailureAction from "../../util/fromFailureAction";

const messageListLoadingErredEpic = fromFailureAction(
  MESSAGE_LIST_LOADING_ERRED,
  constant(MESSAGE_LIST_RETRIEVAL_ERROR_DISPLAY)
);

export default messageListLoadingErredEpic;
