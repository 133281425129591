// Third Party
import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MAILBOX_CHANGED } from "../../constants/ActionTypes.js";
import { mailboxFolderSelected, mailboxFoldersLoadingStart } from "../../actions/messageNavigationActions";
import * as applicationConfig from "../../config/application";

const { defaultFolderId } = applicationConfig.messageNavigation;

const mailboxChangedEpic = action$ => action$.pipe(
  ofType(MAILBOX_CHANGED),
  mergeMap(action => [
    mailboxFoldersLoadingStart(action.mailboxAddress),
    mailboxFolderSelected(action.mailboxAddress, defaultFolderId)
  ])
);

export default mailboxChangedEpic;
