// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the postFn with mailboxAddress, draftIdentity, and attachment, if they are provided.
 *
 * @param {Function} postFn - Function which accepts a URI and body, and returns a Promise.
 * @param {String} mailboxAddress - Mailbox containing the draft.
 * @param {String} draftIdentity - Draft identity to modify.
 * @param {String} attachment - Attachment to add.
 * @return {Promise.<DraftAttachment>}
 */
function postDraftAttachment(postFn, mailboxAddress, draftIdentity, attachment) {
  return mailboxAddress && draftIdentity && attachment ?
    postFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/attachments`, attachment)
    /* eslint-disable no-unused-vars */
      .then(response => attachment) :
    Promise.reject(new Error("Mailbox address, draft identity, and attachment are required"));
}

/**
 * Creates a function that will POST (add) a draft message attachment.
 *
 * @param {Function} postFn - Function which accepts a URI and body, and returns a Promise.
 * @return {Function} Function which posts a draft attachment when executed with a mailbox address and draft identity.
 */
function createAddDraftAttachment(postFn) {
  const hasPostFn = postFn && isFunction(postFn);

  return (mailboxAddress, draftIdentity, attachment) => (hasPostFn ?
    postDraftAttachment(postFn, mailboxAddress, draftIdentity, attachment) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createAddDraftAttachment;
