// Third party
import isFunction from "lodash/fp/isFunction";

// Project
import getDraftIdentityFromLocation from "./getDraftIdentityFromLocation";
import getResponseHeaderLocation from "../../../getResponseHeaderLocation";

/**
 * Executes the postFn with mailboxAddress and draftIdentity, if they are provided.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @return {Promise.<MailboxDraft>}
 */
function postMailboxDraft(postFn, mailboxAddress) {
  return mailboxAddress ?
    postFn(`api/mailbox/${mailboxAddress}/draft`, {})
      .then(getResponseHeaderLocation)
      .then(getDraftIdentityFromLocation) :
    Promise.reject(new Error("Mailbox address is required"));
}

/**
 * Creates a function which will get the a mailbox draft.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests a blank mailbox draft be created when executed with a mailbox address
 * and returns a Promise containing the created draft identity.
 */
function createRequestBlankMailboxDraft(postFn) {
  const hasGetFn = postFn && isFunction(postFn);

  return mailboxAddress => (hasGetFn ?
    postMailboxDraft(postFn, mailboxAddress) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createRequestBlankMailboxDraft;
