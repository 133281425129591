// Third Party
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ConnectedRouter } from "connected-react-router";
import { loadUser, OidcProvider } from "redux-oidc";
import { Provider } from "react-redux";
import { render } from "react-dom";
import React from "react";

// Project
import "./index.css";
import { APPLICATION_INITIALIZED } from "./constants/ActionTypes";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./store";
import userManager from "./userManager";
import Routes from "./Routes";

render(
  <React.StrictMode>
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </OidcProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// Initialize Redux-OIDC.
// This loads cached session information from browser session storage.
loadUser(store, userManager);

// Now that initial render is executed, dispatch the application initialized action.
// This enables middleware to handle any required asynchronous initialization steps.
store.dispatch({ type: APPLICATION_INITIALIZED });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
