// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { DRAFT_SELECTED } from "../constants/ActionTypes.js";
import { draftRetrievalFailure, draftRetrieved } from "../actions/draftMessageActions";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const draftSelectedEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_SELECTED),
  mergeMap(action => from(messagingClient.getMailboxDraft(action.mailboxIdentity, action.draftIdentity))
    .pipe(
      map(response => draftRetrieved(action.mailboxIdentity, action.draftIdentity, response)),
      catchError(error => of(draftRetrievalFailure(action.mailboxIdentity, action.draftIdentity, error)))
    )
  )
);

export default draftSelectedEpic;
