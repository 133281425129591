// Third Party
import PropTypes from "prop-types";
import React from "react";

/**
 * Message list footer container.
 *
 * @param {Object} props - Footer properties.
 * @param {String} props.messageCount - Number of messages.
 * @constructor
 */
function MessageListFooter(props) {
  const messagePluralForm = props.messageCount === 1 ? "message" : "messages";

  return (
    <footer id="message-list-footer">
      <span className="message-count">{ props.messageCount }</span> <span>{ messagePluralForm }</span>
    </footer>
  );
}

MessageListFooter.propTypes = {
  messageCount: PropTypes.number.isRequired
};

export default MessageListFooter;
