
// Third Party
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { isObject } from "lodash";
import { ofType } from "redux-observable";

// Project
import { USER_REQUESTED } from "../../constants/ActionTypes.js";
import { userLoadingErred, userRetrieved } from "../../actions/userActions.js";

const userRequestedEpic = (action$, state$, { userManager }) => action$.pipe(
  ofType(USER_REQUESTED),
  mergeMap(() => from(userManager.getUser())
    .pipe(
      filter(isObject),
      map(user => userRetrieved(user)),
      catchError(error => of(userLoadingErred(error)))
    )
  )
);

export default userRequestedEpic;
