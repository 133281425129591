// Third Party
import { combineEpics } from "redux-observable";

// Project
import applicationLifecycle from "./epics/application-lifecycle";
import draft from "./epics/draft";
import draftCreatedEpic from "./epics/draftCreatedEpic";
import draftListLoadingStartEpic from "./epics/draftListLoadingStartEpic";
import draftMoveOutboxRequestedEpic from "./epics/draftMoveOutboxRequestedEpic";
import draftMoveOutboxStartEpic from "./epics/draftMoveOutboxStartEpic";
import draftMoveOutboxSuccessEpic from "./epics/draftMoveOutboxSuccessEpic";
import draftRequestDerivedForwardEpic from "./epics/draftRequestDerivedForwardEpic";
import draftRequestDerivedReplyAllEpic from "./epics/draftRequestDerivedReplyAllEpic";
import draftRequestDerivedReplyEpic from "./epics/draftRequestDerivedReplyEpic";
import draftRequestNewEpic from "./epics/draftRequestNewEpic";
import draftSelectedEpic from "./epics/draftSelectedEpic";
import draftsSelectedEpic from "./epics/draftsSelectedEpic";
import editFolderNameSaveEpic from "./epics/messageNavigation/editFolderNameSaveEpic";
import folderCreateFailureEpic from "./epics/folderCreateFailureEpic";
import folderCreateStartEpic from "./epics/folderCreateStartEpic";
import folderCreateSuccessEpic from "./epics/folderCreateSuccessEpic";
import folderNameUpdateFailureEpic from "./epics/folderNameUpdateFailureEpic";
import folderNameUpdateStartEpic from "./epics/messageNavigation/folderNameUpdateStartEpic";
import folderNameUpdateSuccessEpic from "./epics/messageNavigation/folderNameUpdateSuccessEpic";
import mailbox from "./epics/mailbox";
import messageFolderSelectedEpic from "./epics/messageFolderSelectedEpic";
import messageLoadingEpic from "./epics/messageLoadingEpic";
import messageLoadingErredEpic from "./epics/messageLoadingErredEpic";
import messageRetrievedEpic from "./epics/messageRetrievedEpic";
import messagesCreateMoveFolderRequestEpic from "./epics/messagesCreateMoveFolderRequestEpic";
import messagesDeleteFailureEpic from "./epics/messagesDeleteFailureEpic";
import messagesDeleteStartEpic from "./epics/messagesDeleteStartEpic";
import messagesDeleteSuccessEpic from "./epics/messagesDeleteSuccessEpic";
import messageSelectedEpic from "./epics/messageSelectedEpic";
import messagesMoveFolderFailureEpic from "./epics/messagesMoveFolderFailureEpic";
import messagesMoveFolderRequestEpic from "./epics/messagesMoveFolderRequestEpic";
import messagesMoveFolderSuccessEpic from "./epics/messagesMoveFolderSuccessEpic";
import messagesMoveTrashFailureEpic from "./epics/messagesMoveTrashFailureEpic";
import messagesMoveTrashRequestEpic from "./epics/messagesMoveTrashRequestEpic";
import messagesMoveTrashSuccessEpic from "./epics/messagesMoveTrashSuccessEpic";
import notifications from "./epics/notifications";
import setMessageStateEpic from "./epics/setMessageStateEpic";
import user from "./epics/user";

// Order of elements passed to combineEpics determines execution order.
// DO NOT sort alphabetically.
export const rootEpic = combineEpics(
  applicationLifecycle,
  user,
  mailbox,
  messageFolderSelectedEpic,
  messageLoadingEpic,
  messageLoadingErredEpic,
  messageRetrievedEpic,
  messageSelectedEpic,
  draftCreatedEpic,
  draftListLoadingStartEpic,
  draftMoveOutboxRequestedEpic,
  draftMoveOutboxStartEpic,
  draftMoveOutboxSuccessEpic,
  draftRequestNewEpic,
  draftRequestDerivedForwardEpic,
  draftRequestDerivedReplyAllEpic,
  draftRequestDerivedReplyEpic,
  draftSelectedEpic,
  draft,
  draftsSelectedEpic,
  editFolderNameSaveEpic,
  folderCreateStartEpic,
  folderCreateSuccessEpic,
  folderCreateFailureEpic,
  folderNameUpdateStartEpic,
  folderNameUpdateSuccessEpic,
  folderNameUpdateFailureEpic,
  setMessageStateEpic,
  messagesCreateMoveFolderRequestEpic,
  messagesDeleteStartEpic,
  messagesDeleteSuccessEpic,
  messagesDeleteFailureEpic,
  messagesMoveFolderRequestEpic,
  messagesMoveFolderSuccessEpic,
  messagesMoveFolderFailureEpic,
  messagesMoveTrashRequestEpic,
  messagesMoveTrashSuccessEpic,
  messagesMoveTrashFailureEpic,
  notifications
);
