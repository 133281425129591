// Third party
import isFunction from "lodash/fp/isFunction";
import isString from "lodash/fp/isString";

/**
 * Executes the putFn with mailboxAddress, folderIdentity, and displayName if they are provided.
 *
 * @param {Function} putFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} folderIdentity - Folder identity.
 * @param {String}displayName - Folder displayName to update.
 * @return {Promise.<FolderName>}
 */
function putMailboxFolderName(putFn, mailboxAddress, folderIdentity, displayName) {
  return mailboxAddress && folderIdentity && isString(displayName) ?
    putFn(`api/mailbox/${mailboxAddress}/folder/${folderIdentity}/displayname`, { displayName: displayName })
      .then(response => response.json()) :
    Promise.reject(new Error("Mailbox address, folder identity, and displayName are required"));
}

/**
 * Creates a function that will PUT (update) a mailbox folder displayName.
 *
 * @param {Function} putFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which puts a folder displayName when executed.
 */
function createPutFolderName(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, folderIdentity, displayName) => (hasPutFn ?
    putMailboxFolderName(putFn, mailboxAddress, folderIdentity, displayName) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutFolderName;
