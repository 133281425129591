// Third Party
import React from "react";

/**
 * Default detail view for the application
 *
 * @constructor
 */
function DefaultView() {
  return (
    <div id="default-view-wrapper"></div>
  );
}

export default DefaultView;
