// Project
import {
  MESSAGE_LOADING_END,
  MESSAGE_LOADING_START,
  MESSAGE_RETRIEVED,
  MESSAGE_STATE_CHANGED
} from "../constants/ActionTypes.js";
import { MESSAGE_VIEW } from "../constants/DetailViewPanes";
import initialMessageViewState from "../config/initialMessageViewState";
import initialState from "../config/initialState";

/**
 * Creates an updated application state, replacing the messageView with a new instance derived from either the existing
 * messageView, if present, or the initialMessageViewState. The messageView's {@link key} property is replaced with
 * {@link value}.
 *
 * @param {Object} state - Application state object.
 * @param {String} key - Hash key for messageView.
 * @param {String} value - New messageView property value.
 * @return {Object} Application state.
 * @private
 */
function updateMessageViewProperty(state, key, value) {
  const baseMessageViewModel = state.messageView ? state.messageView : initialMessageViewState;

  return {
    ...state,
    messageView: {
      ...baseMessageViewModel,
      [key]: value
    }
  };
}

/**
 * Unset the messageLoading flag
 *
 * @param  {Object} state - Application state.
 * @return {Object} Application state.
 * @private
 */
function messageLoadingEnd(state) {
  return updateMessageViewProperty(state, "messageLoading", false);
}

/**
 * Set the messageLoading flag
 *
 * @param  {Object} state - Application state.
 * @return {Object} Application state.
 * @private
 */
function messageLoadingStart(state) {
  return updateMessageViewProperty(state, "messageLoading", true);
}

/**
 * Set the messageView.message object
 *
 * @param {Object} state - Application state.
 * @param {Object} action - A message retrieved action.
 * @return {String} Application state.
 * @private
 */
function messageRetrieved(state, action) {
  return {
    ...state,
    detailViewPane: MESSAGE_VIEW,
    messageView: {
      ...state.messageView,
      message: action.message
    }
  };
}

/**
 * Creates the next MessageList view model for MessageStateChanged actions.
 * @param {Object} messageListModel - A Message List view model.
 * @param {Object} action - A MessageStateChanged action.
 * @return {Object} Updated MessageList view model.
 * @private
 */
function createMessageStateChangedMessageList(messageListModel, action) {
  let updatedMessageList = messageListModel;
  const headerIndex = messageListModel.messageHeaders
    .findIndex(header => header.messageIdentity === action.messageIdentity);

  if (headerIndex > -1) {
    const updatedHeader = {
      ...messageListModel.messageHeaders[headerIndex],
      state: action.state
    };
    const updatedHeaders = [...messageListModel.messageHeaders];

    updatedHeaders[headerIndex] = updatedHeader;
    updatedMessageList = { ...messageListModel,
      messageHeaders: updatedHeaders };
  }

  return updatedMessageList;
}

/**
 * Creates the next MessageView view model for MessageStateChanged actions.
 * @param {Object} messageViewModel - A Message View view model.
 * @param {Object} action - A MessageStateChanged action.
 * @return {Object} Updated MessageView view model.
 * @private
 */
function createMessageStateChangedMessageView(messageViewModel, action) {
  return messageViewModel.message.messageIdentity === action.messageIdentity ?
    { ...messageViewModel,
      message: {
        ...messageViewModel.message,
        state: action.state
      } } :
    messageViewModel;
}

/**
 * Handles Message State Changed actions.
 * @param {Object} state - Application state.
 * @param {Object} action - A Message State Changed action.
 * @return {Object} Updated application state.
 * @private
 */
function messageStateChanged(state, action) {
  return state.mailbox.address === action.mailboxAddress ?
    { ...state,
      messageList: createMessageStateChangedMessageList(state.messageList, action),
      messageView: createMessageStateChangedMessageView(state.messageView, action) } :
    state;
}

/**
 * Entry point for the messageView reducer.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Current action.
 * @return {Object} state
 */
function messageViewReducer(state = initialState, action) {
  if (state) {
    if (action && action.type) {
      switch (action.type) {
        case MESSAGE_LOADING_END: return messageLoadingEnd(state, action);

        case MESSAGE_LOADING_START: return messageLoadingStart(state, action);

        case MESSAGE_RETRIEVED: return messageRetrieved(state, action);

        case MESSAGE_STATE_CHANGED: return messageStateChanged(state, action);

        default: return state;
      }
    } else {
      return state;
    }
  }

  return initialState;
}

export default messageViewReducer;
