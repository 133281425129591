/**
 * (Application Lifecycle) Application is initialized.
 */
export const APPLICATION_INITIALIZED = "APPLICATION_INITIALIZED";

/**
 * (Mailbox) Active mailbox selection changed.
 */
export const MAILBOX_CHANGED = "MAILBOX_CHANGED";
/**
 * (Mailbox) Active mailbox exited (no longer has an active mailbox).
 */
export const MAILBOX_EXITED = "MAILBOX_EXITED";

// Mailbox Folders
export const CURRENT_FOLDER_WORKING_NAME_CHANGED = "CURRENT_FOLDER_WORKING_NAME_CHANGED";
export const EDIT_FOLDER_NAME_ABORT = "EDIT_FOLDER_NAME_ABORT";
export const EDIT_FOLDER_NAME_BEGIN = "EDIT_FOLDER_NAME_BEGIN";
export const EDIT_FOLDER_NAME_SAVE = "EDIT_FOLDER_NAME_SAVE";
export const FOLDER_CREATE_FAILURE = "FOLDER_CREATE_FAILURE";
export const FOLDER_CREATE_START = "FOLDER_CREATE_START";
export const FOLDER_CREATE_SUCCESS = "FOLDER_CREATE_SUCCESS";
export const FOLDER_NAME_UPDATE_FAILURE = "FOLDER_NAME_UPDATE_FAILURE";
export const FOLDER_NAME_UPDATE_START = "FOLDER_NAME_UPDATE_START";
export const FOLDER_NAME_UPDATE_SUCCESS = "FOLDER_NAME_UPDATE_SUCCESS";
export const MAILBOX_FOLDER_SELECTED = "MAILBOX_FOLDER_SELECTED";
export const MAILBOX_FOLDERS_LOADING_FAILURE = "MAILBOX_FOLDERS_LOADING_FAILURE";
export const MAILBOX_FOLDERS_LOADING_START = "MAILBOX_FOLDERS_LOADING_START";
export const MAILBOX_FOLDERS_LOADING_SUCCESS = "MAILBOX_FOLDERS_LOADING_SUCCESS";
export const NEW_FOLDER_NAME_CHANGED = "NEW_FOLDER_NAME_CHANGED";

// Message collection actions
export const CREATE_MESSAGES_MOVE_FOLDER_REQUEST = "CREATE_MESSAGES_MOVE_FOLDER_REQUEST";
export const MESSAGES_DELETE_FAILURE = "MESSAGES_DELETE_FAILURE";
export const MESSAGES_DELETE_START = "MESSAGES_DELETE_START";
export const MESSAGES_DELETE_SUCCESS = "MESSAGES_DELETE_SUCCESS";
export const MESSAGES_MOVE_FOLDER_FAILURE = "MESSAGES_MOVE_FOLDER_FAILURE";
export const MESSAGES_MOVE_FOLDER_REQUEST = "MESSAGES_MOVE_FOLDER_REQUEST";
export const MESSAGES_MOVE_FOLDER_SUCCESS = "MESSAGES_MOVE_FOLDER_SUCCESS";
export const MESSAGES_MOVE_TRASH_FAILURE = "MESSAGES_MOVE_TRASH_FAILURE";
export const MESSAGES_MOVE_TRASH_REQUEST = "MESSAGES_MOVE_TRASH_REQUEST";
export const MESSAGES_MOVE_TRASH_SUCCESS = "MESSAGES_MOVE_TRASH_SUCCESS";

// Message List
export const MESSAGE_LIST_LOADING_END = "MESSAGE_LIST_LOADING_END";
export const MESSAGE_LIST_LOADING_ERRED = "MESSAGE_LIST_LOADING_ERRED";
export const MESSAGE_LIST_LOADING_START = "MESSAGE_LIST_LOADING_START";
export const MESSAGE_LIST_REQUESTED = "MESSAGE_LIST_REQUESTED";
export const MESSAGE_LIST_RETRIEVED = "MESSAGE_LIST_RETRIEVED";

// Message MultiSelect
export const MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD = "MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD";
export const MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR = "MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR";
export const MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE = "MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE";

// Message View
export const MESSAGE_ATTACHMENT_DOWNLOAD_FAILED = "MESSAGE_ATTACHMENT_DOWNLOAD_FAILED";
export const MESSAGE_ATTACHMENT_DOWNLOAD_REQUEST = "MESSAGE_ATTACHMENT_DOWNLOAD_REQUEST";
export const MESSAGE_ATTACHMENT_DOWNLOAD_START = "MESSAGE_ATTACHMENT_DOWNLOAD_START";
export const MESSAGE_ATTACHMENT_DOWNLOAD_SUCCEEDED = "MESSAGE_ATTACHMENT_DOWNLOAD_SUCCEEDED";
export const MESSAGE_ATTACHMENT_OPEN_FAILED = "MESSAGE_ATTACHMENT_OPEN_FAILED";
export const MESSAGE_ATTACHMENT_OPEN_REQUEST = "MESSAGE_ATTACHMENT_OPEN_REQUEST";
export const MESSAGE_ATTACHMENT_OPEN_START = "MESSAGE_ATTACHMENT_OPEN_START";
export const MESSAGE_ATTACHMENT_OPEN_SUCCEEDED = "MESSAGE_ATTACHMENT_OPEN_SUCCEEDED";
export const MESSAGE_LOADING_END = "MESSAGE_LOADING_END";
export const MESSAGE_LOADING_ERRED = "MESSAGE_LOADING_ERRED";
export const MESSAGE_LOADING_START = "MESSAGE_LOADING_START";
export const MESSAGE_LOADING_SUCCESS = "MESSAGE_LOADING_SUCCESS";
export const MESSAGE_RETRIEVED = "MESSAGE_RETRIEVED";
export const MESSAGE_SELECTED = "MESSAGE_SELECTED";
export const MESSAGE_STATE_CHANGED = "MESSAGE_STATE_CHANGED";
export const REQUEST_FORWARD_DERIVED_DRAFT = "REQUEST_FORWARD_DERIVED_DRAFT";
export const REQUEST_REPLY_ALL_DERIVED_DRAFT = "REQUEST_REPLY_ALL_DERIVED_DRAFT";
export const REQUEST_REPLY_DERIVED_DRAFT = "REQUEST_REPLY_DERIVED_DRAFT";
export const SET_MESSAGE_STATE = "SET_MESSAGE_STATE";
export const SET_MESSAGE_STATE_ERRED = "SET_MESSAGE_STATE_ERRED";

// Draft List
export const DRAFT_LIST_LOADING_FAILURE = "DRAFT_LIST_LOADING_FAILURE";
export const DRAFT_LIST_LOADING_START = "DRAFT_LIST_LOADING_START";
export const DRAFT_LIST_LOADING_SUCCESS = "DRAFT_LIST_LOADING_SUCCESS";
export const DRAFTS_SELECTED = "DRAFTS_SELECTED";

// Draft Message
export const DRAFT_ATTACHMENT_ADD_FAILURE = "DRAFT_ATTACHMENT_ADD_FAILURE";
export const DRAFT_ATTACHMENT_ADD_SAVE = "DRAFT_ATTACHMENT_ADD_SAVE";
export const DRAFT_ATTACHMENT_ADD_START = "DRAFT_ATTACHMENT_ADD_START";
export const DRAFT_ATTACHMENT_ADD_SUCCESS = "DRAFT_ATTACHMENT_ADD_SUCCESS";
export const DRAFT_ATTACHMENT_FILES_SELECTED = "DRAFT_ATTACHMENT_FILES_SELECTED";
export const DRAFT_ATTACHMENT_REMOVE_FAILURE = "DRAFT_ATTACHMENT_REMOVE_FAILURE";
export const DRAFT_ATTACHMENT_REMOVE_REQUEST = "DRAFT_ATTACHMENT_REMOVE_REQUEST";
export const DRAFT_ATTACHMENT_REMOVE_START = "DRAFT_ATTACHMENT_REMOVE_START";
export const DRAFT_ATTACHMENT_REMOVE_SUCCESS = "DRAFT_ATTACHMENT_REMOVE_SUCCESS";
export const DRAFT_ATTACHMENT_SELECTED = "DRAFT_ATTACHMENT_SELECTED";
export const DRAFT_CREATED = "DRAFT_CREATED";
export const DRAFT_CREATION_FAILED = "DRAFT_CREATION_FAILED";
export const DRAFT_DISCARD_FAILURE = "DRAFT_DISCARD_FAILURE";
export const DRAFT_DISCARD_REQUESTED = "DRAFT_DISCARD_REQUESTED";
export const DRAFT_DISCARD_START = "DRAFT_DISCARD_START";
export const DRAFT_DISCARD_SUCCESS = "DRAFT_DISCARD_SUCCESS";
export const DRAFT_RETRIEVAL_FAILURE = "DRAFT_RETRIEVAL_FAILURE";
export const DRAFT_RETRIEVED = "DRAFT_RETRIEVED";
export const DRAFT_SELECTED = "DRAFT_SELECTED";
export const REQUEST_NEW_BLANK_DRAFT = "REQUEST_NEW_BLANK_DRAFT";

// Draft Message Move to Outbox
export const DRAFT_MOVE_OUTBOX_FAILURE = "DRAFT_MOVE_OUTBOX_FAILURE";
export const DRAFT_MOVE_OUTBOX_REQUESTED = "DRAFT_MOVE_OUTBOX_REQUESTED";
export const DRAFT_MOVE_OUTBOX_START = "DRAFT_MOVE_OUTBOX_START";
export const DRAFT_MOVE_OUTBOX_SUCCESS = "DRAFT_MOVE_OUTBOX_SUCCESS";

// Draft MultiSelect
export const DRAFTS_MULTI_SELECT_ITEMS_ADD = "DRAFTS_MULTI_SELECT_ITEMS_ADD";
export const DRAFTS_MULTI_SELECT_ITEMS_CLEAR = "DRAFTS_MULTI_SELECT_ITEMS_CLEAR";
export const DRAFTS_MULTI_SELECT_ITEMS_REMOVE = "DRAFTS_MULTI_SELECT_ITEMS_REMOVE";

// Draft Elements
export const DRAFT_BODY_CHANGED = "DRAFT_BODY_CHANGED";
export const DRAFT_BODY_UPDATE_FAILURE = "DRAFT_BODY_UPDATE_FAILURE";
export const DRAFT_BODY_UPDATE_SUCCESS = "DRAFT_BODY_UPDATE_SUCCESS";
export const DRAFT_BODY_UPDATE_START = "DRAFT_BODY_UPDATE_START";

export const DRAFT_CC_ADDRESSEES_UPDATE_FAILURE = "DRAFT_CC_ADDRESSEES_UPDATE_FAILURE";
export const DRAFT_CC_ADDRESSEES_UPDATE_SUCCESS = "DRAFT_CC_ADDRESSEES_UPDATE_SUCCESS";
export const DRAFT_CC_ADDRESSEES_UPDATE_START = "DRAFT_CC_ADDRESSEES_UPDATE_START";
export const DRAFT_CC_CHANGED = "DRAFT_CC_CHANGED";
export const DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE = "DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE";

export const DRAFT_REMOVE_CC_ADDRESSEE = "DRAFT_REMOVE_CC_ADDRESSEE";
export const DRAFT_REMOVE_TO_ADDRESSEE = "DRAFT_REMOVE_TO_ADDRESSEE";

export const DRAFT_SUBJECT_CHANGED = "DRAFT_SUBJECT_CHANGED";
export const DRAFT_SUBJECT_UPDATE_FAILURE = "DRAFT_SUBJECT_UPDATE_FAILURE";
export const DRAFT_SUBJECT_UPDATE_SUCCESS = "DRAFT_SUBJECT_UPDATE_SUCCESS";
export const DRAFT_SUBJECT_UPDATE_START = "DRAFT_SUBJECT_UPDATE_START";

export const DRAFT_TO_ADDRESSEES_UPDATE_FAILURE = "DRAFT_TO_ADDRESSEES_UPDATE_FAILURE";
export const DRAFT_TO_ADDRESSEES_UPDATE_SUCCESS = "DRAFT_TO_ADDRESSEES_UPDATE_SUCCESS";
export const DRAFT_TO_ADDRESSEES_UPDATE_START = "DRAFT_TO_ADDRESSEES_UPDATE_START";
export const DRAFT_TO_CHANGED = "DRAFT_TO_CHANGED";
export const DRAFT_TO_WORKING_ADDRESS_IN_PROGRESS_CHANGE = "DRAFT_TO_WORKING_ADDRESS_IN_PROGRESS_CHANGE";

// Notifications
export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";
export const NOTIFICATION_DISMISS_DELAYED = "NOTIFICATION_DISMISS_DELAYED";
export const NOTIFICATION_DISMISS_DELAYED_ALLOW = "NOTIFICATION_DISMISS_DELAYED_ALLOW";
export const NOTIFICATION_DISMISS_DELAYED_PREVENT = "NOTIFICATION_DISMISS_DELAYED_PREVENT";

/**
 * (User) Application user changed.
 */
export const USER_CHANGED = "USER_CHANGED";
/**
 * (User) Application user failed to load.
 */
export const USER_LOADING_ERRED = "USER_LOADING_ERRED";
/**
 * (User) User's assigned mailboxes loading erred.
 */
export const USER_MAILBOXES_LOADING_ERRED = "USER_MAILBOXES_LOADING_ERRED";
/**
 * (User) User's assigned mailboxes requested.
 */
export const USER_MAILBOXES_REQUESTED = "USER_MAILBOXES_REQUESTED";
/**
 * (User) User's assigned mailboxes were retrieved.
 */
export const USER_MAILBOXES_RETRIEVED = "USER_MAILBOXES_RETRIEVED";
/**
 * (User) User's application-specific profile requested.
 */
export const USER_PROFILE_REQUESTED = "USER_PROFILE_REQUESTED";
/**
 * (User) Application user requested.
 */
export const USER_REQUESTED = "USER_REQUESTED";
/**
 * (User) Application user retrieved.
 */
export const USER_RETRIEVED = "USER_RETRIEVED";
/**
 * (User) Application user sign in requested.
 */
export const USER_SIGN_IN_REQUESTED = "USER_SIGN_IN_REQUESTED";
/**
 * (User) Application user sign out requested.
 */
export const USER_SIGN_OUT_REQUESTED = "USER_SIGN_OUT_REQUESTED";
