// Project
import {
  FOLDER_CREATE_FAILURE,
  FOLDER_CREATE_START,
  FOLDER_CREATE_SUCCESS,
  NEW_FOLDER_NAME_CHANGED
} from "../../constants/ActionTypes";

/**
 * Signals the failure to create a new folder.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function folderCreateFailure(mailboxAddress, error) {
  return {
    error: error,
    mailboxAddress: mailboxAddress,
    type: FOLDER_CREATE_FAILURE
  };
}

/**
 * Signals the start of new-folder creation.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} displayName - Folder display name.
 * @return {Object} Action object.
 */
export function folderCreateStart(mailboxAddress, displayName) {
  return {
    displayName: displayName,
    mailboxAddress: mailboxAddress,
    type: FOLDER_CREATE_START
  };
}

/**
 * Signals folder creation success.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} folderId - Folder identity.
 * @param {Strong} displayName - Folder name.
 * @return {Object} Action object.
 */
export function folderCreateSuccess(mailboxAddress, folderId, displayName) {
  return {
    displayName: displayName,
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    type: FOLDER_CREATE_SUCCESS
  };
}

/**
 * Signals a change to the newDisplayName field.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} displayName - New folder display name.
 * @return {Object} Action object.
 */
export function newFolderNameChanged(mailboxAddress, displayName) {
  return {
    displayName: displayName,
    mailboxAddress: mailboxAddress,
    type: NEW_FOLDER_NAME_CHANGED
  };
}
