// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";
import { USER_FOUND } from "redux-oidc";

// Project
import { userRequested } from "../../actions/userActions.js";

// NOTE(jeremiah.sanders): redux-oidc dispatches USER_FOUND when "a valid user is found (on startup, after token
//   refresh or token callback)". Here we are mapping that to a messaging-ui action which will retrieve the updated
//   value from the oidc-client UserManager.

const applicationInitializedEpic = action$ => action$.pipe(
  ofType(USER_FOUND),
  map(() => userRequested())
);

export default applicationInitializedEpic;
