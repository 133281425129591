// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MESSAGE_LIST_REQUESTED } from "../../../constants/ActionTypes.js";
import { messageListLoadingStart } from "../../../actions/messageListActions.js";

const messageListRequestedEpic = action$ => action$.pipe(
  ofType(MESSAGE_LIST_REQUESTED),
  map(action => messageListLoadingStart(action.mailboxAddress, action.folderId, action.offset, action.pageSize))
);

export default messageListRequestedEpic;
