// Third Party
import { constant } from "lodash";

// Project
import { MESSAGES_MOVE_FOLDER_FAILURE } from "../constants/ActionTypes";
import { MESSAGES_MOVE_FOLDER_FAILURE_DISPLAY } from "../constants/Errors";
import fromFailureAction from "./util/fromFailureAction";

const messagesMoveFolderFailureEpic = fromFailureAction(
  MESSAGES_MOVE_FOLDER_FAILURE,
  constant(MESSAGES_MOVE_FOLDER_FAILURE_DISPLAY)
);

export default messagesMoveFolderFailureEpic;
