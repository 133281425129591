// Third Party
import PropTypes from "prop-types";
import React from "react";

// Project
import "./Icon.css";

/**
 * Creates an Icon.
 * @remarks This element wraps its children with a 'span' which attempts to enforce a 1em sizing upon its content.
 *   It is intended to be used surrounding a FontAwesomeIcon (which renders an SVG).
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Object} props.children - React element children. Expected to be a FontAwesomeIcon.
 * @param {String} props.className - Classes to apply to the outer span.
 * @return {Object}
 */
function Icon(props) {
  const className = props.className ? `icon ${props.className}` : "icon";

  // NOTE(jeremiah.sanders): The ...props syntax within the <span> below allows all additional props to be passed to
  //   the rendered <span> without declaring their use here. Examples: aria-label, onClick, style
  return <span className={className} {...props}>{props.children}</span>;
}

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Icon;
