// Third Party
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

// Project
import "./Body.css";

const minimumRows = 9;

export const bodyTextAreaId = "draft-body-content";

/* eslint-disable max-lines-per-function */
/**
 * Draft body editor.
 *
 * @constructor
 * @param {Object} props - Body properties.
 */
function Body({ body, onChange, ...rest }) {
  const handleInputChange = event => onChange(event.target.value);
  // NOTE (jeremiah.sanders): The 'sr-only' Bootstrap class below makes the element visible only to screen-readers.
  //   The label is included to address in-browser form element validation warnings.

  return <div {...rest}>
    <Label for={bodyTextAreaId} className="sr-only">Body</Label>
    <TextareaAutosize
      aria-label="Draft body"
      className="draft-view-body form-control"
      id={bodyTextAreaId}
      minRows={minimumRows}
      onChange={handleInputChange}
      onKeyUp={handleInputChange}
      value={body}
    />
  </div>;
}

Body.propTypes = {
  body: PropTypes.string,
  onChange: PropTypes.func
};

export default Body;
