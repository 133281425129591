// Project
import {
  DRAFT_DISCARD_FAILURE,
  DRAFT_DISCARD_REQUESTED,
  DRAFT_DISCARD_START,
  DRAFT_DISCARD_SUCCESS
} from "../../constants/ActionTypes";

/**
 * Signals failure while trying to discard a draft message.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} draftIdentities - Draft identities.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function draftDiscardFailure(mailboxAddress, draftIdentities, error) {
  return {
    draftIdentities: draftIdentities,
    error: error,
    mailboxAddress: mailboxAddress,
    type: DRAFT_DISCARD_FAILURE
  };
}

/**
 * Signal the intent to discard a draft message.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @param {Array} draftIdentities - Draft identities.
 * @return {Object} Action object.
 */
export function draftDiscardRequested(mailboxAddress, draftIdentities) {
  return {
    draftIdentities: draftIdentities,
    mailboxAddress: mailboxAddress,
    type: DRAFT_DISCARD_REQUESTED
  };
}

/**
 * Signals the start of the draft-discard process.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @param {Array} draftIdentities - Draft identities.
 * @return {Object} Action object.
 */
export function draftDiscardStart(mailboxAddress, draftIdentities) {
  return {
    draftIdentities: draftIdentities,
    mailboxAddress: mailboxAddress,
    type: DRAFT_DISCARD_START
  };
}

/**
 * Signals the completion of the draft-discard process.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @param {Array} draftIdentities - Draft identities.
 * @return {Object} Action object.
 */
export function draftDiscardSuccess(mailboxAddress, draftIdentities) {
  return {
    draftIdentities: draftIdentities,
    mailboxAddress: mailboxAddress,
    type: DRAFT_DISCARD_SUCCESS
  };
}
