// Third Party
import { Button } from "reactstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Creates a trash button.
 *
 * @constructor
 * @param {Object} props - Trash button properties.
 * @param {Boolean} props.disabled - Flag indicating if the button should be disabled.
 * @param {Function} props.onClick - On click function.
 * @param {String} title - Trash button title.
 */
function TrashButton(props) {
  return (
    <Button
      className="message-list-trash-button"
      color="danger"
      disabled={props.disabled}
      onClick={props.onClick}
      outline
      title={props.title}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>);
}

TrashButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default TrashButton;
