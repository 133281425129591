// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { folderCreateFailure, folderCreateSuccess } from "../actions/messageNavigation/folderCreationActions";
import { FOLDER_CREATE_START } from "../constants/ActionTypes.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const folderCreateStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(FOLDER_CREATE_START),
  mergeMap(action => from(messagingClient.requestNewFolder(action.mailboxAddress, action.displayName))
    .pipe(
      map(newFolder => folderCreateSuccess(action.mailboxAddress, newFolder.folderId, newFolder.displayName)),
      catchError(error => of(folderCreateFailure(action.mailboxAddress, error)))
    )
  )
);

export default folderCreateStartEpic;
