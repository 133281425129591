/**
 * Get a user agent value from the execution environment.
 * @return {String} - A user agent value.
 */
export function getUserAgent() {
  return navigator && navigator.userAgent ? navigator.userAgent : "carealign-user-interface/unknown-user-agent";
}

/**
 * Gets a plain object containing the header values from a Headers object.
 * @param {Headers} headers - A Headers object
 * @return {Object} An object containing the headers.
 */
function getHeadersValues(headers) {
  const headerObject = {};

  for (const [headerName, headerValue] of headers.entries()) {
    headerObject[headerName] = headerValue;
  }

  return headerObject;
}

/**
 * Create an object used as the 'init' value when creating a Request.
 * @param {ClientParameters} clientParameters - API communication parameters.
 * @return {Object} - A Request init value.
 */
export function createRequestInitObject(clientParameters) {
  const userAgent = getUserAgent();
  const authorization = clientParameters && clientParameters.token ? clientParameters.token : null;
  const headers = authorization ?
    {
      "Authorization": `Bearer ${authorization}`,
      "User-Agent": userAgent
    } :
    {
      "User-Agent": userAgent
    };

  return {
    headers,
    mode: "cors"
  };
}

/**
 * Create a function which will apply common request options to a request option object.
 *
 * @param {ClientParameters} clientParameters - API communication parameters.
 * @return {Function} Returns a function which will apply common Request to a Request.
 */
function createApplyCommonRequestOptions(clientParameters) {
  const commonOptions = createRequestInitObject(clientParameters);

  return initialRequest => {
    const requestOptions = initialRequest && initialRequest.headers ?
      {
        ...commonOptions,
        headers: {
          ...commonOptions.headers,
          ...getHeadersValues(initialRequest.headers)
        }
      } :
      commonOptions;

    return new Request(initialRequest, requestOptions);
  };
}

export default createApplyCommonRequestOptions;
