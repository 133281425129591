// Third Party
import isString from "lodash/fp/isString";

// Project
import * as types from "../../constants/ActionTypes";

/**
 * Creates a Trash Messages Failure action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {Error} error - An error.
 * @return {Object} Action.
 */
function messagesMoveTrashFailure(mailboxAddress, error) {
  if (!isString(mailboxAddress)) {
    throw new Error("Mailbox address string is required");
  }

  return {
    error,
    mailboxAddress,
    type: types.MESSAGES_MOVE_TRASH_FAILURE
  };
}

export default messagesMoveTrashFailure;
