// Project
import {
  MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD,
  MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR,
  MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE
} from "../constants/ActionTypes";

/**
 * Add message identities to state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object
 * @return {Object} Modified application state.
 */
function messageListMultiSelectItemsAdd(state, action) {
  return {
    ...state,
    messageList: {
      ...state.messageList,
      selectedMessages: [...state.messageList.selectedMessages.concat(action.messageIdentities)]
    }
  };
}

/**
 * Clear the selected messages from messageList.
 *
 * @param {Object} state - Application state
 * @return {Object} Modified application state.
 */
function messageListMultiSelectItemsClear(state) {
  return {
    ...state,
    messageList: {
      ...state.messageList,
      selectedMessages: []
    }
  };
}

/**
 * Clear the selected messages from messageList.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Modified application state.
 */
function messageListMultiSelectItemsRemove(state, action) {
  const checkSelectedMessages = value => !action.messageIdentities.find(messageId => messageId === value);

  return {
    ...state,
    messageList: {
      ...state.messageList,
      selectedMessages: state.messageList.selectedMessages.filter(checkSelectedMessages)
    }
  };
}

/**
  * Combine multi-select message reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object
 * @return {Object} Modified application state.
 */
function messageListMultiSelectReducers(state, action) {
  if (action && action.type) {
    switch (action.type) {
      case MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD: return messageListMultiSelectItemsAdd(state, action);

      case MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR: return messageListMultiSelectItemsClear(state);

      case MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE: return messageListMultiSelectItemsRemove(state, action);

      default: return state;
    }
  }

  return state;
}

export default messageListMultiSelectReducers;
