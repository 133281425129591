// Third party
import PropTypes from "prop-types";
import React from "react";

// Project
import "./AddressPillbox.css";
import { formatEmailAddressDisplay } from "../../../util/FormatEmailAddressDisplay";
import AddressPill from "./AddressPill";
import formatAddresseeFull from "../../../util/formatAddresseeFull";

/**
 * Addressee display container.
 *
 * @param {Object} props - Rendering properties.
 * @param {Array.<{address: String, displayName: String}>} props.addressees - Addressees.
 * @param {Function} props.onAddresseeClick - Addressee click handler.
 * @return {Object} JSX element.
 */
function AddressPillbox(props) {
  const pills = props.addressees && props.addressees.length ?
    props.addressees
      .map(addressee => {
        const clickHandler = props.onAddresseeClick ? () => props.onAddresseeClick(addressee.address) : null;

        return <AddressPill
          className="m-1 addressee"
          key={ addressee.address }
          onClick={ clickHandler }
          text= { formatEmailAddressDisplay(addressee) }
          title={formatAddresseeFull(addressee)}
        />;
      }) :
    null;

  return (
    <div className="d-flex flex-wrap addressee-pillbox">
      { pills }
    </div>
  );
}

AddressPillbox.propTypes = {
  addressees: PropTypes.array,
  onAddresseeClick: PropTypes.func
};

export default AddressPillbox;
