import { parse } from "content-disposition";
import { saveAs } from "file-saver";

/**
 * Creates a download function. When executed, the download function accepts a Response and returns a Promise
 * containing the Response, if download was successful.
 * @param {Function} saveResponse - A Fetch API Response object.
 * @return {Response} - The provided Fetch API Response object.
 */
export function createDownloadResponse(saveResponse) {
  return async response => {
    const contentDispositionHeader = response.headers.get("Content-Disposition");
    const parsedContentDisposition = parse(contentDispositionHeader);
    const fileName = parsedContentDisposition.parameters.filename;

    const blob = await response.blob();

    saveResponse(blob, fileName);

    return response;
  };
}

export default createDownloadResponse(saveAs);
