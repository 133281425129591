// Third Party
import { CardBody, CardHeader } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";


// Project
import FolderMaintenance from "./FolderMaintenance";
import MailboxFolders from "./MailboxFolders";
import SystemFolders from "./SystemFolders";

// TODO: Make the folders look pretty DEV-3091

/* eslint-disable max-lines-per-function */
/**
 * Constructor for the folder list
 *
 * @constructor
 * @param {Objet} props - Folder list properties.
 * @param {String} props.currentFolderId - Currently select mailbox folder.
 * @param {Array} props.mailboxFolders - User-created mailbox folders.
 * @param {Array} props.newFolderName - User-entered name for a new folder.
 * @param {Function} props.onDraftsClick - Click handler for the drafts navigation item.
 * @param {Function} props.onFolderClick- Click handler for individual folders.
 * @param {Function} props.onNewFolderClick - Click handler for creating a folder.
 * @param {Function} props.onNewFolderNameChange - Change handler for new-folder names.
 * @param {Array} props.systemFolders - Non-user-created mailbox folders.
 */
function Folders(props) {
  return (
    <div>
      <SystemFolders
        currentFolderId={ props.currentFolderId }
        isDraftsSelected={props.draftsItemSelected}
        onDraftsClick={props.onDraftsClick}
        onFolderClick={props.onFolderClick}
        style={({ marginBottom: "1em" })}
        systemFolders={ props.systemFolders }
      />
      <CardHeader>
        Mailbox Folders
      </CardHeader>
      <MailboxFolders
        currentFolderWorkingDisplayName={props.currentFolderWorkingDisplayName}
        currentFolderId={ props.currentFolderId }
        mailboxFolders={ props.mailboxFolders }
        onCurrentFolderNameChange={props.onCurrentFolderNameChange}
        onEditFolderAbortClick={props.onEditFolderAbortClick}
        onEditFolderClick={props.onEditFolderClick}
        onEditFolderSaveClick={props.onEditFolderSaveClick}
        onFolderClick={props.onFolderClick}
        showFolderNameEditor={props.showFolderNameEditor}
      />
      <CardBody>
        <FolderMaintenance
          onNewFolderClick={props.onNewFolderClick}
          onNewFolderNameChange={props.onNewFolderNameChange}
          newFolderName={props.newFolderName}
        />
      </CardBody>
    </div>
  );
}

Folders.propTypes = {
  currentFolderId: PropTypes.string.isRequired,
  currentFolderWorkingDisplayName: PropTypes.string,
  draftsItemSelected: PropTypes.bool,
  mailboxFolders: PropTypes.array.isRequired,
  newFolderName: PropTypes.string,
  onCurrentFolderNameChange: PropTypes.func,
  onDraftsClick: PropTypes.func,
  onEditFolderAbortClick: PropTypes.func,
  onEditFolderClick: PropTypes.func,
  onEditFolderSaveClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  onNewFolderClick: PropTypes.func,
  onNewFolderNameChange: PropTypes.func,
  showFolderNameEditor: PropTypes.bool,
  systemFolders: PropTypes.array.isRequired
};
export default Folders;
