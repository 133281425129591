// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { draftCreated, draftCreationFailed } from "../actions/draftMessageActions";
import { REQUEST_REPLY_ALL_DERIVED_DRAFT } from "../constants/ActionTypes.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const draftRequestDerivedReplyAllEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(REQUEST_REPLY_ALL_DERIVED_DRAFT),
  mergeMap(action => from(messagingClient.requestReplyAllDerivedDraft(action.mailboxAddress, action.messageIdentity))
    .pipe(
      map(createdDraftIdentity => draftCreated(action.mailboxAddress, createdDraftIdentity)),
      catchError(error => of(draftCreationFailed(action.mailboxAddress, error)))
    )
  )
);

export default draftRequestDerivedReplyAllEpic;
