// Third Party
import { InputGroup, InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import NewFolderButton from "./NewFolderButton";
import NewFolderTextInput from "./NewFolderTextInput";

/**
 *  Constructor for the folder creation and update container.
 *
 * @constructor
 * @param {Objet} props - Folder maintenance properties.
 */
function FolderMaintenance(props) {
  const onNewFolderNameChangeHandler = newDisplayName => props.onNewFolderNameChange(newDisplayName);
  const buttonDisabledState = !props.newFolderName;

  return (
    <InputGroup id="new-folder-wrapper" className="d-flex" >
      <NewFolderTextInput
        onChange={onNewFolderNameChangeHandler}
        value={props.newFolderName}
      />
      <InputGroupAddon addonType="append">
        <NewFolderButton
          onNewFolderClick={props.onNewFolderClick}
          disabledState={buttonDisabledState}
        />
      </InputGroupAddon>
    </InputGroup>
  );
}

FolderMaintenance.propTypes = {
  newFolderName: PropTypes.string,
  onNewFolderClick: PropTypes.func,
  onNewFolderNameChange: PropTypes.func
};

export default FolderMaintenance;
