// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { FOLDER_NAME_UPDATE_SUCCESS } from "../../constants/ActionTypes";
import { mailboxFoldersLoadingStart } from "../../actions/messageNavigationActions";

const folderNameUpdateSuccessEpic = action$ => action$.pipe(
  ofType(FOLDER_NAME_UPDATE_SUCCESS),
  map(action => mailboxFoldersLoadingStart(action.mailboxAddress))
);

export default folderNameUpdateSuccessEpic;
