import { tryGetUserDisplayName as getUserDisplayName, tryGetUserIdentity as getUserIdentity } from "./oidcUser";

/**
 * Attempts to retrieve the current OpenID Connect user.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Object | null | undefined}
 */
export function tryGetUser(state) {
  return state?.oidc?.user;
}

/**
 * Attempts to retrieve the current OpenID Connect user profile.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Object | null | undefined}
 */
export function tryGetUserProfile(state) {
  return tryGetUser(state)?.profile;
}

/**
 * Attempts to retrieve the current OpenID Connect user's display name.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {String | undefined}
 */
export function tryGetUserDisplayName(state) {
  return getUserDisplayName(tryGetUser(state));
}

/**
 * Attempts to retrieve the current OpenID Connect user's identity (subject claim).
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {String | undefined}
 */
export function tryGetUserIdentity(state) {
  return getUserIdentity(tryGetUser(state));
}

/**
 * Determines if an OpenID Connect user is signed in, based upon the presence of a user profile.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Boolean}
 */
export function isUserSignedIn(state) {
  return Boolean(tryGetUserProfile(state));
}
