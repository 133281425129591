// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { messageAttachmentDownloadStart } from "../../../../actions/messageViewActions";
import { MESSAGE_ATTACHMENT_DOWNLOAD_REQUEST } from "../../../../constants/ActionTypes.js";

const messageAttachmentDownloadRequestEpic = action$ => action$.pipe(
  ofType(MESSAGE_ATTACHMENT_DOWNLOAD_REQUEST),
  map(action => messageAttachmentDownloadStart(action.mailboxAddress, action.messageIdentity,
    action.attachmentIdentifier))
);

export default messageAttachmentDownloadRequestEpic;
