// Project
import ApiClient from "../ApiClient";
import createAddDraftAttachment from "./mailbox/draft/elements/createAddDraftAttachment";
import createDeleteDraft from "./mailbox/draft/createDeleteDraft";
import createDeleteDraftAttachment from "./mailbox/draft/elements/createDeleteDraftAttachment";
import createDeleteDraftsRequest from "./mailbox/draft/createDeleteDraftsRequest";
import createDeleteMessagesRequest from "./mailbox/messages/createDeleteMessagesRequest";
import createGetMailboxDraft from "./mailbox/draft/createGetMailboxDraft";
import createGetMailboxMessage from "./mailbox/message/createGetMailboxMessage";
import createGetMailboxMessageAttachment from "./mailbox/message/createGetMailboxMessageAttachment";
import createGetMyMailboxes from "./my/createGetMyMailboxes";
import createMessagesMoveFolderRequest from "./mailbox/messages/createMessagesMoveFolderRequest";
import createMessagesMoveTrashRequest from "./mailbox/messages/createMessagesMoveTrashRequest";
import createPutMailboxDraftBody from "./mailbox/draft/elements/createPutMailboxDraftBody";
import createPutMailboxDraftCcAddressees from "./mailbox/draft/elements/createPutMailboxDraftCcAddressees";
import createPutMailboxDraftSubject from "./mailbox/draft/elements/createPutMailboxDraftSubject";
import createPutMailboxDraftToAddressees from "./mailbox/draft/elements/createPutMailboxDraftToAddressees";
import createPutMailboxFolderName from "./mailbox/createPutMailboxFolderName";
import createPutMailboxMessageState from "./mailbox/message/createPutMailboxMessageState";
import createRequestBlankMailboxDraft from "./mailbox/draft/createRequestBlankMailboxDraft";
import createRequestForwardDerivedDraft from "./mailbox/message/createRequestForwardDerivedDraft";
import createRequestMoveDraftOutbox from "./mailbox/draft/createRequestMoveDraftOutbox";
import createRequestNewFolder from "./mailbox/createRequestNewFolder";
import createRequestReplyAllDerivedDraft from "./mailbox/message/createRequestReplyAllDerivedDraft";
import createRequestReplyDerivedDraft from "./mailbox/message/createRequestReplyDerivedDraft";
import getApiHealth from "./getApiHealth";
import getDrafts from "./mailbox/getDrafts";
import getMailboxFolders from "./mailbox/getMailboxFolders";
import getMailboxHeaders from "./mailbox/getMailboxHeaders";
import validateClientParameters from "./validateClientParameters";

/**
 * Client facade for interacting with the Messaging Service API.
 */
class MessagingServiceClient {
  /**
   * Attempts to create a new MessagingServiceClient.
   *
   * @param {Object} apiRequestParameters - Object containing API client parameters.
   * @param {Function} apiRequestParameters.fetch - Function for performing HTTP communication.
   * Expected to match the Fetch API (Request -> Promise<Response>).
   * @param {String} apiRequestParameters.rootUrl - Root URL for API.
   * @param {String} apiRequestParameters.token - Bearer token for API authentication.
   * @return {Either.<Error,MessagingServiceClient>}
   */
  static tryCreate(apiRequestParameters) {
    return validateClientParameters(apiRequestParameters)
      .chain(ApiClient.tryCreate)
      .map(apiClient => new MessagingServiceClient(apiClient));
  }

  /**
   * Attempts to modify a MessagingServiceClient, replacing its ApiClient.
   *
   * @param {Object} apiRequestParameters - Object containing API client parameters.
   * @param {Function} apiRequestParameters.fetch - Function for performing HTTP communication.
   * Expected to match the Fetch API (Request -> Promise<Response>).
   * @param {String} apiRequestParameters.rootUrl - Root URL for API.
   * @param {String} apiRequestParameters.token - Bearer token for API authentication.
   * @param {MessagingServiceClient} messagingClient - Messaging client to modify.
   * @return {Either.<Error,MessagingServiceClient>}
   */
  static tryReplaceApiClient(apiRequestParameters, messagingClient) {
    return validateClientParameters(apiRequestParameters)
      .chain(ApiClient.tryCreate)
      .map(apiClient => {
        messagingClient.useClient(apiClient);

        return messagingClient;
      });
  }

  /* eslint-disable max-lines-per-function, max-statements */
  /**
   * Creates an instance of MessagingServiceClient without validating parameters.
   *
   * @param {ApiClient} apiClient - ApiClient facade.
   */
  constructor(apiClient) {
    this.useClient(apiClient);
  }

  /**
   * Updates the client to use a new ApiClient. This mutability enables setting the authentication token after sign in.
   * @param {ApiClient} apiClient - ApiClient facade.
   */
  useClient(apiClient) {
    this.addDraftAttachment = createAddDraftAttachment(apiClient.post);
    this.deleteDraft = createDeleteDraft(apiClient.delete);
    this.deleteDraftAttachment = createDeleteDraftAttachment(apiClient.delete);
    this.deleteDrafts = createDeleteDraftsRequest(apiClient.post);
    this.deleteMessages = createDeleteMessagesRequest(apiClient.post);
    this.getApiHealth = getApiHealth(apiClient.get);
    this.getDrafts = getDrafts(apiClient.get);
    this.getMailboxDraft = createGetMailboxDraft(apiClient.get);
    this.getMailboxFolders = getMailboxFolders(apiClient.get);
    this.getMailboxHeaders = getMailboxHeaders(apiClient.get);
    this.getMailboxMessage = createGetMailboxMessage(apiClient.get);
    this.getMailboxMessageAttachment = createGetMailboxMessageAttachment(apiClient.get);
    this.getMyMailboxes = createGetMyMailboxes(apiClient.get);
    this.messagesMoveFolderRequest = createMessagesMoveFolderRequest(apiClient.post);
    this.messagesMoveTrashRequest = createMessagesMoveTrashRequest(apiClient.post);
    this.putMailboxDraftBody = createPutMailboxDraftBody(apiClient.put);
    this.putMailboxDraftCcAddressees = createPutMailboxDraftCcAddressees(apiClient.put);
    this.putMailboxDraftSubject = createPutMailboxDraftSubject(apiClient.put);
    this.putMailboxDraftToAddressees = createPutMailboxDraftToAddressees(apiClient.put);
    this.putMailboxFolderName = createPutMailboxFolderName(apiClient.put);
    this.putMailboxMessageState = createPutMailboxMessageState(apiClient.put);
    this.requestBlankMailboxDraft = createRequestBlankMailboxDraft(apiClient.post);
    this.requestForwardDerivedDraft = createRequestForwardDerivedDraft(apiClient.post);
    this.requestMoveDraftOutbox = createRequestMoveDraftOutbox(apiClient.post);
    this.requestNewFolder = createRequestNewFolder(apiClient.post);
    this.requestReplyAllDerivedDraft = createRequestReplyAllDerivedDraft(apiClient.post);
    this.requestReplyDerivedDraft = createRequestReplyDerivedDraft(apiClient.post);
  }
}

export default MessagingServiceClient;
