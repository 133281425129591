/* eslint-disable max-lines-per-function */
// Third Party
import { CallbackComponent } from "redux-oidc";
import { CardSubtitle, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { redirectToMailbox, redirectToSignInFailed } from "../actions/routingActions";
import FullPageCareAlignCard from "../components/FullPageCareAlignCard";
import userManager from "../userManager";

/**
 * Creates a sign in response processing OIDC callback component.
 * @param {Object} props - Rendering properties.
 * @return {Object} JSX element.
 */
function SignInResponseProcessing(props) {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={props.onSuccess}
      errorCallback={props.onError}
    >
      <FullPageCareAlignCard>
        <CardTitle tag="h2">Processing authentication&hellip;</CardTitle>
        <CardSubtitle tag="h3">You will be redirected when finished.</CardSubtitle>
      </FullPageCareAlignCard>
    </CallbackComponent>
  );
}

SignInResponseProcessing.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => (
  { onError: () => dispatch(redirectToSignInFailed()),
    onSuccess: () => dispatch(redirectToMailbox()) });

export default connect(mapStateToProps, mapDispatchToProps)(SignInResponseProcessing);
