// Project
import createApplyCommonRequestOptions from "./createApplyCommonRequestOptions";

/**
 * Create an API DELETE function (url -> Promise<Response>).
 *
 * @param {Function} deleteFn - Function to perform DELETE request.
 * Expected to match the Fetch API (Request -> Promise<Response>).
 * @param {Object} clientParameters - Object containing API client parameters.
 * @param {String} clientParameters.rootUrl - Root URL for API.
 * @param {String} clientParameters.token - Bearer token for API authentication.
 * @return {Function} Function which accepts a URI and returns a request promise.
 */
function createDelete(deleteFn, clientParameters) {
  const optionApplicator = createApplyCommonRequestOptions(clientParameters);

  return url => {
    const deleteRequest = optionApplicator(
      new Request(
        `${clientParameters.rootUrl}${url}`,
        {
          method: "DELETE"
        }
      )
    );

    return deleteFn(deleteRequest);
  };
}

export default createDelete;
