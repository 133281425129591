// Project
import {
  USER_CHANGED,
  USER_LOADING_ERRED,
  USER_MAILBOXES_LOADING_ERRED,
  USER_MAILBOXES_REQUESTED,
  USER_MAILBOXES_RETRIEVED,
  USER_PROFILE_REQUESTED,
  USER_REQUESTED,
  USER_RETRIEVED,
  USER_SIGN_IN_REQUESTED,
  USER_SIGN_OUT_REQUESTED
} from "../constants/ActionTypes";

/**
 * Creates a User Changed action.
 * @param {User} user - An OAuth2 user.
 * @return {Object}
 */
export function userChanged(user) {
  return {
    type: USER_CHANGED,
    user
  };
}

/**
 * Creates a User Loading Erred action.
 * @param {Error} error - A loading error.
 * @return {Object}
 */
export function userLoadingErred(error) {
  return {
    error,
    type: USER_LOADING_ERRED
  };
}

/**
 * Creates a User Mailboxes Loading Erred action.
 * @param {Error} error - A loading error.
 * @return {Object}
 */
export function userMailboxesLoadingErred(error) {
  return {
    error,
    type: USER_MAILBOXES_LOADING_ERRED
  };
}

/**
 * Creates a User Mailboxes Requested action.
 * @return {Object}
 */
export function userMailboxesRequested() {
  return {
    type: USER_MAILBOXES_REQUESTED
  };
}

/**
 * Creates a User Mailboxes Retrieved action.
 * @param {Array} mailboxes - Mailboxes assigned to the user.
 * @return {Object}
 */
export function userMailboxesRetrieved(mailboxes) {
  return {
    mailboxes,
    type: USER_MAILBOXES_RETRIEVED
  };
}

/**
 * Creates a User Profile Requested action.
 * @return {Object}
 */
export function userProfileRequested() {
  return {
    type: USER_PROFILE_REQUESTED
  };
}

/**
 * Creates a User Requested action.
 * @return {Object}
 */
export function userRequested() {
  return {
    type: USER_REQUESTED
  };
}

/**
 * Creates a User Retrieved action.
 * @param {User} user - An OAuth2 user object.
 * @return {Object}
 */
export function userRetrieved(user) {
  return {
    type: USER_RETRIEVED,
    user
  };
}

/**
 * Creates a User Sign In Requested action.
 * @return {Object}
 */
export function userSignInRequested() {
  return {
    type: USER_SIGN_IN_REQUESTED
  };
}

/**
 * Creates a User Sign Out Requested action.
 * @return {Object}
 */
export function userSignOutRequested() {
  return {
    type: USER_SIGN_OUT_REQUESTED
  };
}
