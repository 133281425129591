/* eslint-disable max-lines-per-function */
import { useEffect, useRef, useState } from "react";

/* eslint-disable no-undefined */
/**
 * Convert UseIntersectionObserverOptions to IntersectionObserverInit.
 * @param {Object} options - Optional. Options to convert.
 * @param {Object} options.root - The intersection root definition: the root element used for comparison and an
 * optional margin, modifying the bounding box used when calculating intersections.
 * @param {Element} options.root.element - An Element object which is an ancestor of the intended target, whose
 * bounding rectangle will be considered the viewport. Any part of the target not visible in the visible area of the
 * root is not considered visible.
 * This value corresponds to `IntersectionObserverInit.root`.
 * @param {String} options.root.margin - A string which specifies a set of offsets to add to the root's bounding_box
 * when calculating intersections, effectively shrinking or growing the root for calculation purposes. The syntax is
 * approximately the same as that for the CSS margin property; see The root element and root margin in Intersection
 * Observer API for more information on how the margin works and the syntax. The default is "0px 0px 0px 0px".
 * This value corresponds to `IntersectionObserverInit.rootMargin`.
 * @param {Number|Number[]} options.threshold - Either a single number or an array of numbers between 0.0 and 1.0,
 * specifying a ratio of intersection area to total bounding box area for the observed target. A value of 0.0 means
 * that even a single visible pixel counts as the target being visible. 1.0 means that the entire target element is
 * visible. See Thresholds in Intersection Observer API for a more in-depth description of how thresholds are used.
 * The default is a threshold of 0.0.
 * This value corresponds to `IntersectionObserverInit.threshold`.
 * @return {IntersectionObserverInit}
 */
function toIntersectionObserverInit(options) {
  return typeof options === "undefined" ?
    undefined :
    { root: options.root?.element,
      rootMargin: options.root?.margin,
      threshold: options.threshold };
}
/* eslint-enable no-undefined */

/**
 * React hook enabling use of `IntersectionObserver`.
 * See: https://reactjs.org/docs/hooks-custom.html
 * See: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver
 * @param {Object} params - Hook parameters.
 * @param {Function} params.callback - Callback to execute when the element we're observing is intersecting the root
 * element (viewport if unspecified).
 * @return {UseIntersectionObserverContext}
 */
function useIntersectionObserver(params) {
  // This _local_ state holds a DOM element reference (which should be set by the target's `ref` attribute).
  const [observedElementReference, setObservedElementReference] = useState(null);
  // This mutable React ref object holds an IntersectionObserver.
  // The value must be a mutable React ref so it can be set _after_ the component is constructed (in the effect below).
  const intersectionObserver = useRef(null);

  useEffect(
    // This effect sets up the IntersectionObserver.
    () => {
      const observer = new IntersectionObserver(entries => {
        const [entry] = entries; // This is the element we're observing.

        if (entry.isIntersecting) {
          // The element we're observing is intersecting the root element (viewport if unspecified).
          params.callback();
        }
      },
      toIntersectionObserverInit(params.options)
      );

      intersectionObserver.current = observer; // Set the intersectionObserver React Ref value.
    },
    params.dependencies
  );

  useEffect(
    // This effect (un)observes the visibility of the "bottom" DOM element.
    () => {
      // This executes upon initiation of this effect/component mount.
      const observer = intersectionObserver.current;

      if (observedElementReference) {
        // The last item is set.
        observer?.observe(observedElementReference);
      }

      return () => {
        // Cleanup function. Called by React on unmount.
        if (observedElementReference) {
          // The last item is set.
          observer?.unobserve(observedElementReference);
        }
      };
    },
    // The below effect dependency array causes the then-current observer to (un)observe the "bottom" DOM element
    // when that "bottom" element or a generated users.requested action would change.
    [observedElementReference, ...params.dependencies]
  );

  return { setObservedElementReference };
}

export default useIntersectionObserver;
