// Third party
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./AddressPill.css";

/**
 *  Draft addressee pill.
 *
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {String}props.className - Additional classes.
 * @param {Function} props.onClick - Click event handler.
 * @param {String} props.text - Pill text content.
 * @param {String} props.title - Pill element title.
 * @return {Object} - JSX element.
 */
function AddressPill({ className, onClick, text, title, ...rest }) {
  return (
    <Badge className={`address-pill selectable ${className}`} onClick={ onClick } pill title={title} {...rest}>
      {text}
      <FontAwesomeIcon icon={ faTimesCircle } />
    </Badge>
  );
}

AddressPill.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string
};

export default AddressPill;
