/* eslint-disable max-lines-per-function */

// Third Party
import { catchError, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { MESSAGE_LIST_LOADING_START } from "../../../constants/ActionTypes.js";
import { messageListLoadingEnd, messageListLoadingErred, messageListRetrieved } from "../../../actions/messageListActions.js";

/**
 * Listens for message list loading start. Performs a request for mailbox headers. Successful query results dispatch
 * a message list retrieved action and message list loading end action. Failure query results dispatch a message list
 * loading erred action and message list loading end action.
 *
 * @param {Observable.<Action>} action$ - Action observable.
 * @param {Observable.<Object>} state$ - State observable.
 * @param {Object} dependencies - A dependency injection object.
 * @return {Observable.<Action>}
 */
const messageListLoadingStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MESSAGE_LIST_LOADING_START),
  mergeMap(action => from(messagingClient.getMailboxHeaders({
    folderId: action.folderId,
    mailbox: action.mailboxAddress,
    offset: action.offset,
    pageSize: action.pageSize
  }))
    .pipe(
      mergeMap(headerResponse => [
        messageListRetrieved(action.mailboxAddress, action.folderId, action.offset, action.pageSize,
          headerResponse.headers),
        messageListLoadingEnd(action.mailboxAddress, action.folderId)
      ]),
      catchError(error => of(
        messageListLoadingErred(action.mailboxAddress, action.folderId, error),
        messageListLoadingEnd(action.mailboxAddress, action.folderId)
      ))
    )
  )
);

export default messageListLoadingStartEpic;
