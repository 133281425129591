// Third Party
import PropTypes from "prop-types";
import React from "react";

/**
 * Logo image component.
 *
 * @private
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {String} props.style - Additional styling.
 * @param {String} props.className - Additional class names.
 */
function Logo({ className, style }) {
  return (
    <img
      alt="CareAlign logo"
      aria-label="CareAlign logo"
      className={className}
      src="/carealign.svg"
      style={style}
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default Logo;
