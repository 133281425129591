// Third Party
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import NotificationLevelColorMap from "../../constants/NotificationLevelColorMap";

/**
 * Build the notification item.
 *
 * @constructor
 * @param {Object} props - Notification item properties.
 * @param {String} props.message - Notification text.
 * @param {Function} props.onCloseClick - Callback when notification close button is clicked.
 * @param {Function} props.onMouseEnter - Callback when mouse enters the notification.
 * @param {Function} props.onMouseLeave - Callback when mouse leaves the notification.
 * @param {String} props.severity - Notification severity level.
 */
function NotificationItem({ message, onCloseClick, onMouseEnter, onMouseLeave, severity }) {
  return (
    <Alert
      color={NotificationLevelColorMap[severity]}
      isOpen={true}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      toggle={onCloseClick}
    >
      {message}
    </Alert>
  );
}

NotificationItem.propTypes = {
  message: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  severity: PropTypes.string.isRequired
};

export default NotificationItem;
