// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";


// Project
import { DRAFT_MOVE_OUTBOX_START } from "../constants/ActionTypes";
import { draftMoveOutboxFailure, draftMoveOutboxSuccess } from "../actions/draftMessage/draftMoveToOutboxActions";

const draftMoveOutboxStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_MOVE_OUTBOX_START),
  mergeMap(action => from(messagingClient.requestMoveDraftOutbox(action.mailboxAddress, action.draftIdentity))
    .pipe(
      map(response => draftMoveOutboxSuccess(action.mailboxAddress, response.draftIdentity)),
      catchError(error => of(draftMoveOutboxFailure(action.mailboxAddress, error)))
    )
  )
);

export default draftMoveOutboxStartEpic;
