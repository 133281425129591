// Project
import * as types from "../constants/ActionTypes.js";

/**
 *  Action for signaling the start of the subject-text update process.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingSubject - Unsaved subject of draft message.
 * @param {String} autosave - Flag denoting an automatic versus a user-generated save.
 * @return {Object} Action object.
 */
export function subjectUpdateStart(mailboxAddress, draftIdentity, workingSubject, autosave) {
  /* eslint-disable no-unneeded-ternary */
  return {
    draftAutosaving: Boolean(autosave),
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_SUBJECT_UPDATE_START,
    workingSubject: workingSubject
  };
}

/**
 * Action for signaling a failure to update the subject text.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} message - Error message.
 * @return {Object} Action object.
 */
export function subjectUpdateFailure(mailboxAddress, draftIdentity, message) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    message: message,
    type: types.DRAFT_SUBJECT_UPDATE_FAILURE
  };
}

/**
 * Action for signaling a successful subject-text update.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} cachedSubject - Subject text from the API.
 * @return {Object} Action object.
 */
export function subjectUpdateSuccess(mailboxAddress, draftIdentity, cachedSubject) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    subject: cachedSubject,
    type: types.DRAFT_SUBJECT_UPDATE_SUCCESS
  };
}

/**
 * On change action for the subject text.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingSubject - Draft subject text.
 * @return {Object} Action object.
 */
export function subjectChanged(mailboxAddress, draftIdentity, workingSubject) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_SUBJECT_CHANGED,
    workingSubject: workingSubject
  };
}
