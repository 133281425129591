// Third Party
import { faReplyAll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import DerivedDraftButton from "./DerivedDraftButton";

/**
 * Creates a Reply All button.
 *
 * @constructor
 * @param {Object} props - Reply All button properties
 */
function ReplyAllButton(props) {
  return (
    <DerivedDraftButton onClick={props.onClick} title="Reply All">
      <FontAwesomeIcon icon={faReplyAll} />
    </DerivedDraftButton>
  );
}

ReplyAllButton.propTypes = {
  onClick: PropTypes.func
};

export default ReplyAllButton;
