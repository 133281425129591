const initialMessageListState = {
  currentMessageId: "",
  isListInferredComplete: true,
  messageHeaders: [],
  messageListLoading: false,
  messageListLoadingError: false,
  selectedMessages: []
};

export default initialMessageListState;
