/* eslint-disable new-cap */
// Third Party
import isBlank from "@jlmorgan/lodash-extras/src/isBlank";
import Either from "data.either";

/**
 * Validate presence of API URL.
 *
 * @param {ClientParameters} clientParameters - Parameters to validate.
 * @return {Either.<Error,ClientParameters>} Either an error or the validated parameters.
 * @private
 */
function rejectIfUrlIsMissing(clientParameters) {
  return !clientParameters || isBlank(clientParameters.rootUrl) ?
    Either.Left(Error("'rootUrl' is required")) :
    Either.Right(clientParameters);
}

/**
 * Validate client parameters for presence of required properties.
 *
 * @param {Object} clientParameters - Parameters to validate.
 * @param {String} clientParameters.rootUrl - Root URL for API.
 * @param {String} clientParameters.token - Authorization token for API.
 * @return {Either.<Error,ClientParameters>} Either an error or the validated parameters.
 */
function validateClientParameters(clientParameters) {
  return rejectIfUrlIsMissing(clientParameters);
}


export default validateClientParameters;
