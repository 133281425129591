// Third party
import isFunction from "lodash/fp/isFunction";
import isBoolean from "lodash/fp/isBoolean";

/**
 * Converts truthy values to "Seen" and falsy values to "Unseen".
 * @param {Boolean} isSeen - Message "is seen" state.
 * @return {String} Messaging Service message seen state.
 */
function booleanToSeenState(isSeen) {
  return isSeen ? "Seen" : "Unseen";
}

/**
 * Executes the putFn with the state API URL and a body object containing the new state.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} messageIdentity - Message to modify.
 * @param {Boolean} isSeen - New message "is seen" state.
 * @return {Promise.<Object>} Promise of object with messageIdentity, mailboxAddress, and state.
 */
function putMailboxMessageState(putFn, mailboxAddress, messageIdentity, isSeen) {
  return mailboxAddress && messageIdentity && isBoolean(isSeen) ?
    putFn(`api/mailbox/${mailboxAddress}/message/${messageIdentity}/state`, { state: booleanToSeenState(isSeen) })
      .then(() => (
        { mailboxAddress,
          messageIdentity,
          state: booleanToSeenState(isSeen) }
      )) :
    Promise.reject(new Error("Mailbox address, message identity, and isSeen state are required"));
}

/**
 * Creates a function which will update a mailbox message state.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which updates a mailbox message state when executed and returns a Promise containing the
 * same mailbox address, message identity, and new seen state.
 */
function createPutMailboxMessageState(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, messageIdentity, isSeen) => (hasPutFn ?
    putMailboxMessageState(putFn, mailboxAddress, messageIdentity, isSeen) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutMailboxMessageState;
