/**
 * The relative path used for OpenID Connect response handling.
 * This value matches PageRoutes.signInResponseProcessing, save for the latter's preceding forward-slash.
 * @remarks This is exposed to allow building the OIDC configuration via reference, rather than hard-coding its value.
 */
export const responseHandlingPath = "signin-response";

/**
  * The relative path used for post-sign-out redirects from the OpenID Connect identity provider.
  * This value matches PageRoutes.unauthenticated, save for the latter's preceding forward-slash.
  * @remarks This is exposed to allow building the OIDC configuration via reference, rather than hard-coding its value.
  */
export const postSignOutUnauthenticatedPath = "";
