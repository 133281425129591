// Project
import initialMessageListState from "../config/initialMessageListState";
import initialState from "../config/initialState";
import {
  MESSAGE_LIST_LOADING_END,
  MESSAGE_LIST_LOADING_START,
  MESSAGE_LIST_REQUESTED,
  MESSAGE_LIST_RETRIEVED,
  MESSAGE_SELECTED
} from "../constants/ActionTypes";

/**
 * Gets the message list from state. If it does not exist, it returns the initial message list state.
 * @param {Object} state - An application state.
 * @return {Object} Message list.
 * @private
 */
function getMessageList(state) {
  return state && state.messageList ? state.messageList : initialMessageListState;
}

/**
 * Replace the messageList in state.
 *
 * @param {Object} state - An application state.
 * @param {Object} messageList - A message list.
 * @return {Object} Updated state.
 * @private
 */
function replaceMessageList(state, messageList) {
  return {
    ...state ? state : initialState,
    messageList
  };
}

/**
 * Handles a message list requested action.
 *
 * @param {Object} state - Message list state.
 * @param {Object} action - A message list retrieved action.
 * @return {Object} Updated state.
 * @private
 */
function messageListRequested(state, action) {
  // When there's a falsy action.offset we're loading an initial page.
  return action.offset ?
    state :
    {
      ...state,
      messageHeaders: []
    };
}

/**
 * Handles a message list retrieved action.
 *
 * @param {Object} state - Message list state.
 * @param {String} currentFolderId - Current folderId.
 * @param {Object} action - A message list retrieved action.
 * @return {Object} Updated state.
 * @private
 */
function messageListRetrieved(state, currentFolderId, action) {
  return {
    ...state,
    isListInferredComplete: action.messageHeaders.length < action.pageSize,
    messageHeaders: action.folderId === currentFolderId && action.offset ?
      [...state.messageHeaders, ...action.messageHeaders] :
      action.messageHeaders
  };
}

/**
 * Handles a message list loading end action.
 *
 * @param  {Object} state - Message list state.
 * @param  {Object} action - A message list loading end action.
 * @return {Object} Application state.
 * @private
 */
function messageListLoadingEnd(state) {
  return {
    ...state,
    messageListLoading: false
  };
}

/**
 * Handles a message list loading start action.
 *
 * @param  {Object} state - Message list state.
 * @param  {Object} action - A message list loading start action.
 * @return {Object} Application state.
 * @private
 */
function messageListLoadingStart(state) {
  return {
    ...state,
    messageListLoading: true
  };
}

/**
 * Handles a message selected action.
 *
 * @param  {Object} state - Message list state.
 * @param  {Object} action - A message selected action.
 * @return {Object} Application state.
 * @private
 */
function messageSelected(state, action) {
  return {
    ...state,
    currentMessageId: action.messageIdentity
  };
}

/* eslint-disable max-lines-per-function */
/**
 * Combine the messageView reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action to take.
 * @return {Object} state
 */
export function messageListReducer(state = initialState, action) {
  if (action && action.type) {
    const messageListState = getMessageList(state);
    const currentFolderId = state && state.messageNavigation && state.messageNavigation.currentFolderId ?
      state.messageNavigation.currentFolderId :
      "";

    switch (action.type) {
      case MESSAGE_LIST_LOADING_END:
        return replaceMessageList(state, messageListLoadingEnd(messageListState, action));

      case MESSAGE_LIST_LOADING_START:
        return replaceMessageList(state, messageListLoadingStart(messageListState, action));

      case MESSAGE_LIST_REQUESTED:
        return replaceMessageList(state, messageListRequested(messageListState, action));

      case MESSAGE_LIST_RETRIEVED:
        return replaceMessageList(state, messageListRetrieved(messageListState, currentFolderId, action));

      case MESSAGE_SELECTED:
        return replaceMessageList(state, messageSelected(messageListState, action));

      default: return state;
    }
  } else {
    return state;
  }
}

