/**
 * Attempts to retrieve the current messaging mailbox.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Object | null}
 */
export function tryGetMailbox(state) {
  return state?.messaging?.mailbox;
}

/**
 * Attempts to retrieve the current messaging mailbox address.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Object | null}
 */
export function tryGetMailboxAddress(state) {
  return tryGetMailbox(state)?.address;
}
