// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { MESSAGES_MOVE_FOLDER_REQUEST } from "../constants/ActionTypes.js";
import { messagesMoveFolderFailure, messagesMoveFolderSuccess } from "../actions/messages/messagesMoveFolderActions.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const messagesMoveFolderRequestEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MESSAGES_MOVE_FOLDER_REQUEST),
  mergeMap(action => from(
    messagingClient.messagesMoveFolderRequest(action.mailboxAddress, action.folderId, action.messageIdentities))
    .pipe(
      // eslint-disable-next-line max-len
      map(response => messagesMoveFolderSuccess(
        action.mailboxAddress,
        response.messageIdentitiesMoved,
        response.destination.folderId
      )),
      catchError(error => of(messagesMoveFolderFailure(
        action.mailboxAddress,
        action.messageIdentities,
        action.folderId,
        error
      )))
    )
  )
);

export default messagesMoveFolderRequestEpic;
