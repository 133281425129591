// Third Party
import { Button, CardSubtitle, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { redirectToSignInRequested } from "../actions/routingActions";
import FullPageCareAlignCard from "../components/FullPageCareAlignCard";

/**
 * Unauthenticated landing page.
 *
 * @param {Object} props - Rendering properties.
 * @param {Function} props.onSignInClick - On click callback for Sign In button.
 * @return {Object}
 */
function Unauthenticated(props) {
  return <FullPageCareAlignCard imageSize="lg">
    <CardTitle tag="h1">Welcome to CareAlign Messaging.</CardTitle>
    <CardSubtitle tag="h4">You must sign in to access this application.</CardSubtitle>
    <Button color="primary" onClick={props.onSignInClick} size="lg" title="Sign In">Sign In</Button>
  </FullPageCareAlignCard>;
}

Unauthenticated.propTypes = {
  onSignInClick: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ onSignInClick: () => dispatch(redirectToSignInRequested()) });

export default connect(mapStateToProps, mapDispatchToProps)(Unauthenticated);
