// Third Party
import { connect } from "react-redux";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import { requestNewBlankDraft } from "../../actions/draftMessageActions.js";

// Component Setup
const mapStateToProps = state => ({ mailboxAddress: state.messaging.mailbox.address });

const mapDispatchToProps = {
  "onComposeClicked": requestNewBlankDraft
};

/**
 * Button for new message.
 *
 * @constructor
 * @param {Object} props - Button properties.
 */
function ComposeMessageButton(props) {
  const clickHandler = () => props.onComposeClicked(props.mailboxAddress);

  return (
    <Button aria-label="Compose new draft" onClick={clickHandler}>
        Compose
    </Button>
  );
}

ComposeMessageButton.propTypes = {
  mailboxAddress: PropTypes.string,
  onComposeClicked: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessageButton);
