// Project
import * as types from "../constants/ActionTypes.js";

/**
 * Finished-editing action for the cc addresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingCcAddress - Draft cc text.
 * @return {Object} Action object.
*/
export function ccAddressChanged(mailboxAddress, draftIdentity, workingCcAddress) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_CC_CHANGED,
    workingCcAddress: workingCcAddress
  };
}

/**
 * Action for signaling a failure to update the ccAddresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Object} error - Error object
 * @return {Object} Action object.
 */
export function ccAddressUpdateFailure(mailboxAddress, draftIdentity, error) {
  return {
    draftIdentity: draftIdentity,
    error: error,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_CC_ADDRESSEES_UPDATE_FAILURE
  };
}

/**
 *  Action for signaling the start of the ccAddress update process.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {Boolean} isAutosaving - Flag denoting an automatic versus a user-generated save.
 * @return {Object} Action object.
 */
export function ccAddressUpdateStart(mailboxAddress, draftIdentity, isAutosaving) {
  /* eslint-disable no-unneeded-ternary */
  return {
    draftAutosaving: Boolean(isAutosaving),
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_CC_ADDRESSEES_UPDATE_START
  };
}

/**
 * Action for signaling a successful ccAddress update.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Array} ccAddressees - CC addresses from the API.
 * @return {Object} Action object.
 */
export function ccAddressUpdateSuccess(mailboxAddress, draftIdentity, ccAddressees) {
  return {
    ccAddressees: ccAddressees,
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_CC_ADDRESSEES_UPDATE_SUCCESS
  };
}

/**
 * On change action for the CC addresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingCcAddress - Draft CC text.
 * @return {Object} Action object.
 */
export function ccWorkingAddressInProgressChange(mailboxAddress, draftIdentity, workingCcAddress) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE,
    workingCcAddress: workingCcAddress
  };
}

/**
 * Signals the intent to remove a CC addressee pill.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} ccAddressee  - Addressee to remove
 * @return {Object} Action object.
 */
export function removeCcAddressee(mailboxAddress, draftIdentity, ccAddressee) {
  return {
    ccAddresseeToRemove: ccAddressee,
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_REMOVE_CC_ADDRESSEE
  };
}
