// Third Party
import { Button } from "reactstrap";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Build the folder-name save button.
 *
 * @param {Object} props - Cancel edit button properties.
 * @constructor
 */
function CancelEditButton(props) {
  return (
    <Button
      aria-label="Cancel"
      color="dark"
      id="cancel-edit-button"
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faBan} />
    </Button>
  );
}

CancelEditButton.propTypes = {
  onClick: PropTypes.func
};

export default CancelEditButton;
