/**
 * Compare error code to a given status code.
 *
 * @param {String} statusCode - Status code to compare.
 * @param {Object} error -
 * @return {Boolean}
 */
function isApiStatusCodeError(statusCode, error) {
  return error.message.startsWith(`Received: ${statusCode}`);
}

export default isApiStatusCodeError;
