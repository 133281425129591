// Third party
import { combineEpics } from "redux-observable";

// Project
import mailboxChangedEpic from "./mailboxChangedEpic";
import mailboxExitedEpic from "./mailboxExitedEpic";
import mailboxFoldersLoadingFailureEpic from "./mailboxFoldersLoadingFailureEpic";
import mailboxFoldersLoadingStartEpic from "./mailboxFoldersLoadingStartEpic";
import message from "./message";
import messageList from "./message-list";

export default combineEpics(
  mailboxChangedEpic,
  mailboxFoldersLoadingStartEpic,
  message,
  messageList,
  mailboxExitedEpic,
  mailboxFoldersLoadingFailureEpic);
