// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { subjectUpdateStart } from "../../actions/draftSubjectActions";
import { DRAFT_SUBJECT_CHANGED } from "../../constants/ActionTypes";

/* eslint-disable arrow-body-style */
const subjectChanged = action$ => action$.pipe(
  ofType(DRAFT_SUBJECT_CHANGED),
  map(action => subjectUpdateStart(action.mailboxAddress, action.draftIdentity, action.workingSubject))
);

export default subjectChanged;
