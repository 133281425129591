// Third Party
import { push } from "connected-react-router";

// Project
import { mailbox, signInFailed, signInRequested } from "../constants/PageRoutes";

/**
 * Creates an action which redirects the user to the Mailbox page.
 * @return {Object} Redirect action.
 */
export function redirectToMailbox() {
  return push(mailbox);
}

/**
 * Creates an action which redirects the user to the SignInFailed page.
 * @return {Object} Redirect action.
 */
export function redirectToSignInFailed() {
  return push(signInFailed);
}

/**
 * Creates an action which redirects the user to the SignInRequested page.
 * @return {Object} Redirect action.
 */
export function redirectToSignInRequested() {
  return push(signInRequested);
}
