import * as applicationConfig from "./application";

const { defaultFolderId } = applicationConfig.messageNavigation;

const initialMessageNavigationState = {
  currentFolderId: defaultFolderId,
  currentFolderWorkingDisplayName: "",
  editingFolderName: false,
  mailboxFolders: [],
  newFolderDisplayName: "",
  systemFolders: []
};

export default initialMessageNavigationState;
