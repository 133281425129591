// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_DISCARD_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_DISCARD_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftDiscardFailureEpic = fromFailureAction(
  DRAFT_DISCARD_FAILURE,
  constant(DRAFT_DISCARD_FAILURE_DISPLAY)
);

export default draftDiscardFailureEpic;
