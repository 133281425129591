// Project
import { draftBodyReducer } from "./reducers/draft/draftBodyReducer";
import { draftCcReducer } from "./reducers/draft/draftCcReducer";
import { draftMessageReducer } from "./reducers/draftMessageReducers";
import { draftSubjectReducer } from "./reducers/draft/draftSubjectReducer";
import { draftToReducer } from "./reducers/draft/draftToReducer";
import { messageListReducer } from "./reducers/messageListReducers";
import draftAttachmentsReducer from "./reducers/draft/draftAttachmentsReducer";
import draftDeleteReducers from "./reducers/draft/draftDeleteReducers";
import draftListMultiSelectReducer from "./reducers/draftListMultiSelectReducer";
import draftMoveToOutboxReducers from "./reducers/draftMoveToOutboxReducers";
import draftsReducers from "./reducers/draftsReducers";
import folderCreationReducer from "./reducers/messageNavigation/folderCreationReducer";
import folderEditReducer from "./reducers/messageNavigation/folderEditReducer";
import initialState from "./config/initialState";
import mailboxReducer from "./reducers/mailboxReducer";
import messageListMultiSelectReducers from "./reducers/messageListMultiSelectReducers";
import messageNavigationReducer from "./reducers/messageNavigationReducers";
import messagesDeleteReducers from "./reducers/messages/messagesDeleteReducers";
import messagesMoveFolderReducers from "./reducers/messages/messagesMoveFolderReducers";
import messageViewReducer from "./reducers/messageViewReducer";
import notificationReducer from "./reducers/notificationReducer";
import userReducer from "./reducers/userReducer";

/**
 * Create a reducer pipeline.
 *
 * @param {Object} reducers - Array of reducers.
 * @return {Object} Reducer pipeline.
 */
export function createReducerPipeline(reducers) {
  return (state = {}, action) => reducers.reduce((lastState, reducer) => reducer(lastState, action), state);
}

const combinedReducerPipeline = createReducerPipeline([
  folderCreationReducer,
  folderEditReducer,
  draftAttachmentsReducer,
  draftBodyReducer,
  draftCcReducer,
  draftDeleteReducers,
  draftListMultiSelectReducer,
  draftMessageReducer,
  draftMoveToOutboxReducers,
  draftsReducers,
  draftSubjectReducer,
  draftToReducer,
  messageListReducer,
  messageListMultiSelectReducers,
  messageNavigationReducer,
  messageViewReducer,
  messagesDeleteReducers,
  messagesMoveFolderReducers,
  notificationReducer,
  userReducer,
  mailboxReducer
]);

/**
 *  Root reducer
 *
 * @param {Object} state - State object.
 * @param {Object} action - Action object.
 * @return {Object} state
 */
export default function rootReducer(state = initialState, action) {
  return action ?
    combinedReducerPipeline(state, action) :
    state;
}
