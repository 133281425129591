// Project
import downloadResponse from "./downloadResponse";
import createOpenResponse from "./createOpenResponse";
import getFileSize from "./getFileSize";
import loadFileContentAsBase64Async from "./loadFileContentAsBase64Async";

const io = {
  downloadResponse,
  getFileSize,
  loadFileContentAsBase64Async,
  openResponse: createOpenResponse(window.URL.createObjectURL, window.open, window.URL.revokeObjectURL)
};

export default io;
