// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { messageListRequested } from "../../actions/messageListActions.js";
import { MESSAGES_DELETE_SUCCESS } from "../../constants/ActionTypes.js";

/**
 * Emits a request to (re)load the current message folder headers after trashing messages.
 * @param {Observable} action$ - An action observable.
 * @param {Observable} state$ - State observable.
 * @return {Observable} - An arranged observable.
 */
const messagesDeleteSuccessNoClearSelectedMessagesEpic = (action$, state$) => action$.pipe(
  ofType(MESSAGES_DELETE_SUCCESS),
  map(action => messageListRequested(action.mailboxAddress, state$.value.messaging.messageNavigation.currentFolderId))
);

export default messagesDeleteSuccessNoClearSelectedMessagesEpic;
