// Project
import {
  DRAFT_ATTACHMENT_ADD_FAILURE,
  DRAFT_ATTACHMENT_ADD_SAVE,
  DRAFT_ATTACHMENT_ADD_START,
  DRAFT_ATTACHMENT_ADD_SUCCESS,
  DRAFT_ATTACHMENT_FILES_SELECTED,
  DRAFT_ATTACHMENT_REMOVE_FAILURE,
  DRAFT_ATTACHMENT_REMOVE_REQUEST,
  DRAFT_ATTACHMENT_REMOVE_START,
  DRAFT_ATTACHMENT_REMOVE_SUCCESS,
  DRAFT_ATTACHMENT_SELECTED
} from "../constants/ActionTypes";

/**
 * Signals a failure to add an attachment to a draft.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} fileName - Name of File which failed to be added as an Attachment.
 * @param {Error} error - Error object.
 * @return {Object} Action object.
 */
export function draftAttachmentAddFailure(mailboxAddress, draftIdentity, fileName, error) {
  return {
    draftIdentity: draftIdentity,
    error: error,
    fileName: fileName,
    mailboxAddress: mailboxAddress,
    type: DRAFT_ATTACHMENT_ADD_FAILURE
  };
}

/**
 * Signals the intent to save an attachment.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Object} attachment - File attachment to be processed.
 * @param {Array} pendingAttachments - File attachments.
 * @return {Object} Action object.
 */
export function draftAttachmentAddSave(mailboxAddress, draftIdentity, attachment, pendingAttachments) {
  return {
    attachment: attachment,
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    pendingAttachments: pendingAttachments,
    type: DRAFT_ATTACHMENT_ADD_SAVE
  };
}

/**
 * Signals the intent to add an attachment.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @return {Object} Action object.
 */
export function draftAttachmentAddStart(mailboxAddress, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: DRAFT_ATTACHMENT_ADD_START
  };
}

/**
 * Signals successfully attaching a file to a draft message.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Object} attachment - File that was attached to the draft.
 * @return {Object} Action object.
 */
export function draftAttachmentAddSuccess(mailboxAddress, draftIdentity, attachment) {
  return {
    attachment: attachment,
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: DRAFT_ATTACHMENT_ADD_SUCCESS
  };
}

/**
 * Signals that attachment files have been selected. Intended to trigger loading files as Base64 strings, and
 * subsequent draftAttachmentAddStart.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Array} files - An Array of File objects. Contains any selected attachment files.
 * @return {Object} Action object.
 */
export function draftAttachmentFilesSelected(mailboxAddress, draftIdentity, files) {
  return {
    draftIdentity,
    files,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_FILES_SELECTED
  };
}

/**
 * Signals that the user selected (interacted with) an existing draft attachment.
 * This is currently (2021/03/04) inferred to mean the attachment should be downloaded.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} attachmentIdentifier - Attachment identity.
 * @return {Object} Action object.
 */
export function draftAttachmentSelected(mailboxAddress, draftIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    draftIdentity,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_SELECTED
  };
}

/**
 * Signals that an existing draft attachment should be removed.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} attachmentIdentifier - Attachment identity.
 * @return {Object} Action object.
 */
export function draftAttachmentRemoveRequest(mailboxAddress, draftIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    draftIdentity,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_REMOVE_REQUEST
  };
}

/**
 * Signals that removal of an existing draft attachment is starting.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} attachmentIdentifier - Attachment identity.
 * @return {Object} Action object.
 */
export function draftAttachmentRemoveStart(mailboxAddress, draftIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    draftIdentity,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_REMOVE_START
  };
}

/**
 * Signals that a draft attachment removal succeeded.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} attachmentIdentifier - Attachment identity.
 * @return {Object} Action object.
 */
export function draftAttachmentRemoveSuccess(mailboxAddress, draftIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    draftIdentity,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_REMOVE_SUCCESS
  };
}

/**
 * Signals that a draft attachment removal failed.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} attachmentIdentifier - Attachment identity.
 * @param {Error} error - Triggering error.
 * @return {Object} Action object.
 */
export function draftAttachmentRemoveFailure(mailboxAddress, draftIdentity, attachmentIdentifier, error) {
  return {
    attachmentIdentifier,
    draftIdentity,
    error,
    mailboxAddress,
    type: DRAFT_ATTACHMENT_REMOVE_FAILURE
  };
}
