// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MESSAGES_MOVE_FOLDER_SUCCESS } from "../constants/ActionTypes";
import { messageListLoadingStart } from "../actions/messageListActions";

const messagesMoveFolderSuccessEpic = (action$, state$) => action$.pipe(
  ofType(MESSAGES_MOVE_FOLDER_SUCCESS),
  map(action => messageListLoadingStart(
    action.mailboxAddress, state$.value.messaging.messageNavigation.currentFolderId
  ))
);

export default messagesMoveFolderSuccessEpic;
