// Project
import { convertApiDraftToViewModel } from "../config/initialDraftMessageState";
import {
  DRAFT_RETRIEVED,
  DRAFT_SELECTED
} from "../constants/ActionTypes";
import { DRAFT_VIEW } from "../constants/DetailViewPanes";
import initialState from "../config/initialState";

/**
 * Set the draft message.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - An action.
 * @param {Object} action.draft - A draft.
 * @param {String} action.draftIdentity - A draft identity.
 * @param {String} action.mailboxIdentity - A mailbox address.
 * @return {Object} Application state.
 */
export function draftRetrieved(state = initialState, action) {
  return {
    ...state,
    detailViewPane: DRAFT_VIEW,
    draftView: convertApiDraftToViewModel(state.mailbox, action.draft)
  };
}

/**
 * Set the current draft identity in application state.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - An action.
 * @return {Object} Application state.
 */
function draftSelected(state, action) {
  return {
    ...state,
    draftList: {
      ...state.draftList,
      currentDraftId: action.draftIdentity
    }
  };
}

/**
 * Combine the draft-message reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action type.
 * @return {Object} Application state.
 */
export function draftMessageReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_RETRIEVED: return draftRetrieved(state, action);

      case DRAFT_SELECTED: return draftSelected(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}
