// Project
import initialState from "../../config/initialState";
import {
  DRAFT_BODY_CHANGED,
  DRAFT_BODY_UPDATE_FAILURE,
  DRAFT_BODY_UPDATE_START,
  DRAFT_BODY_UPDATE_SUCCESS
} from "../../constants/ActionTypes";

/**
 * Update state to reflect draft body update failure.
 *
 * @param {Object} state - Application state.
 * @return {Object} Updated application state.
 */
function bodyUpdateFailure(state) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: false }
  };
}

/**
 * Update the draft autosave flag in application state.
 *
 * @param {String} state - Application stated.
 * @param {Object} action - Update start action.
 * @return {Object} Updated application state.
 */
function bodyUpdateStart(state, action) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: action.draftAutosaving }
  };
}

/**
 * Updated application state with body from API
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Body updated action.
 * @return {Objet} Updated application state.
 */
function bodyUpdateSuccess(state, action) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      body: action.body,
      draftAutosaving: false
    }
  };
}

/**
 * Update the state with the changed body.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function bodyChanged(state, action) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      body: action.workingBody
    }
  };
}

/**
 * Combine the draft message reducers.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action type.
 * @return {Object} Updated application state.
 */
export function draftBodyReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_BODY_CHANGED: return bodyChanged(state, action);

      case DRAFT_BODY_UPDATE_FAILURE: return bodyUpdateFailure(state, action);

      case DRAFT_BODY_UPDATE_SUCCESS: return bodyUpdateSuccess(state, action);

      case DRAFT_BODY_UPDATE_START: return bodyUpdateStart(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}
