// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_ATTACHMENT_REMOVE_REQUEST } from "../../constants/ActionTypes.js";
import { draftAttachmentRemoveStart } from "../../actions/draftAttachmentsActions";

const draftAttachmentRemoveRequestEpic = action$ => action$.pipe(
  ofType(DRAFT_ATTACHMENT_REMOVE_REQUEST),
  map(action => draftAttachmentRemoveStart(action.mailboxAddress, action.draftIdentity, action.attachmentIdentifier))
);

export default draftAttachmentRemoveRequestEpic;
