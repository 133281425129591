// Third Party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { mailboxChanged } from "../../actions/mailboxActions";
import { tryGetUserMailboxes } from "../../selectors/messaging/user";
import MailboxSelectionList from "./MailboxSelectionList";
import NoMailboxes from "./NoMailboxes";

/**
 * Creates a mailbox selector.
 * @remarks This element wraps its children with a 'span' which attempts to enforce a 1em sizing upon its content.
 *   It is intended to be used surrounding a FontAwesomeIcon (which renders an SVG).
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Array} props.mailboxes - Mailboxes which the user may select.
 * @param {Array} props.onMailboxSelected - Callback executed when the user selects a mailbox.
 * @return {Object} JSX component.
 */
function MailboxSelector(props) {
  const hasMailboxes = props.mailboxes.length > 0;

  return hasMailboxes ?
    <MailboxSelectionList mailboxes={props.mailboxes} onMailboxSelected={props.onMailboxSelected} /> :
    <NoMailboxes/>;
}

MailboxSelector.propTypes = {
  mailboxes: PropTypes.array.isRequired,
  onMailboxSelected: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  mailboxes: tryGetUserMailboxes(state) || []
});
const mapDispatchToProps = dispatch => ({ onMailboxSelected: mailbox => dispatch(mailboxChanged(mailbox)) });

export default connect(mapStateToProps, mapDispatchToProps)(MailboxSelector);
