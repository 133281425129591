// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the getFn with mailboxAddress and messageIdentity, if they are provided.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} messageIdentity - Message identity to retrieve.
 * @return {Promise.<MailboxMessage>}
 */
function getMailboxMessage(getFn, mailboxAddress, messageIdentity) {
  return mailboxAddress && messageIdentity ?
    getFn(`api/mailbox/${mailboxAddress}/message/${messageIdentity}`)
      .then(response => response.json()) :
    Promise.reject(new Error("Mailbox address and message identity are required"));
}

/**
 * Creates a function which will get the a mailbox message.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets a mailbox message when executed with a mailbox address and message identity.
 */
function createGetMailboxMessage(getFn) {
  const hasGetFn = getFn && isFunction(getFn);

  return (mailboxAddress, messageIdentity) => (hasGetFn ?
    getMailboxMessage(getFn, mailboxAddress, messageIdentity) :
    Promise.reject(new Error("HTTP GET function is required but was not provided")));
}

export default createGetMailboxMessage;
