// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { subjectUpdateFailure, subjectUpdateSuccess } from "../../actions/draftSubjectActions";
import { DRAFT_SUBJECT_UPDATE_START } from "../../constants/ActionTypes";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const subjectUpdateStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_SUBJECT_UPDATE_START),
  mergeMap(action => from(
    messagingClient.putMailboxDraftSubject(action.mailboxAddress, action.draftIdentity, action.workingSubject))
    .pipe(
      map(response => subjectUpdateSuccess(action.mailboxAddress, action.draftIdentity, response.subject)),
      catchError(error => of(subjectUpdateFailure(action.mailboxAddress, action.draftIdentity, error)))
    )
  )
);

export default subjectUpdateStartEpic;
