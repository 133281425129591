// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the postFn with mailboxAddress and message identities.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} folderId - Destination folder identity.
 * @param {Array} messageIdentities - Message identities to move to new folder.
 * @return {Promise.<MailboxDraft>}
 */
function postMailboxMessagesMove(postFn, mailboxAddress, folderId, messageIdentities) {
  return mailboxAddress && messageIdentities && folderId ?
    postFn(`api/mailbox/${mailboxAddress}/messages/move`,
      { folderId: folderId,
        messageIdentities: messageIdentities })
      .then(response => response.json()) :
    Promise.reject(new Error("Mailbox address, folder id, and message identities are required"));
}

/**
 * Creates a function which will request messages are moved to a new folder.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests messages are moved to a new folder when executed with a mailbox address
 *  and some message identities and returns a Promise containing the API response.
 */
function createMessagesMoveFolderRequest(postFn) {
  const hasPostFn = postFn && isFunction(postFn);

  return (mailboxAddress, folderId, messageIdentities) => (hasPostFn ?
    postMailboxMessagesMove(postFn, mailboxAddress, folderId, messageIdentities) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createMessagesMoveFolderRequest;
