// Third Party
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import Checkbox from "../Checkbox";
import "./ListViewItem.css";

/**
 * Determine seen/unseen class name.
 *
 * @private
 * @param {String} seen - State from MessageListItem props.
 * @return {String} className for message list item container.
 */
function determineStateClass(seen) {
  // NOTE(jeremiah.sanders): Messaging Service should only return "Seen".
  return seen === "Seen" || seen === "seen" ? "seen" : "unseen";
}

/* eslint-disable max-lines-per-function */
/**
 * Message-list item container.
 *
 * @constructor
 * @param {Object} props - Message properties.
 * @param {String} props.dateTimeDisplay - List item display date.
 * @param {String} props.dateTimeFull - List item full date (for title/hover/label use).
 * @param {Array.<String>} props.displayAddresses - Preformatted addressees displayed as from (messages) or to (drafts).
 * @param {Boolean} props.itemSelected - Denotes whether the item is selected or not.
 * @param {String} props.state - Message's read state (seen/unseen).
 * @param {String} props.shortBody - First line or two of the message body.
 *   (NOTE(jeremiah.sanders): Short body is not provided by Messaging Service, as of 2021/08/16.)
 * @param {String} props.subject - Message subject.
 * @constructor
 */
function ListViewItem(props) {
  const attachmentIcon = props.hasAttachments ?
    <FontAwesomeIcon icon={faPaperclip} /> :
    "";
  const messageClassName = `${determineStateClass(props.state)} list-view-item selectable`;
  const addressees = (props.displayAddresses || []).join("; ");

  // NOTE(jeremiah.sanders): The Bootstrap text-truncate class below applies ellipsis text overflow truncation.
  return (
    <ListGroupItem action active={props.itemSelected} className={messageClassName} onClick={props.onClick}>
      <div className="list-view-item-checkbox align-self-center">
        <Checkbox
          aria-label={`Select message "${props.subject}"`}
          className="position-static"
          onChange={props.onCheckChanged}
          title={props.checkboxTitle}
        />
      </div>
      <div className="list-view-item-addressees text-truncate" title={addressees}>{addressees}</div>
      <div className="list-view-item-attachment">{ attachmentIcon }</div>
      <div className="list-view-item-subject text-truncate" title={props.subject}>{props.subject}</div>
      <div className="list-view-item-date" title={props.dateTimeFull}>{props.dateTimeDisplay}</div>
      <div className="list-view-item-truncated-body text-truncate" title={props.shortBody}>{props.shortBody}</div>
    </ListGroupItem>
  );
}

ListViewItem.propTypes = {
  checkboxTitle: PropTypes.string,
  dateTimeDisplay: PropTypes.string,
  dateTimeFull: PropTypes.string,
  displayAddresses: PropTypes.array,
  hasAttachments: PropTypes.bool,
  itemSelected: PropTypes.bool,
  onCheckChanged: PropTypes.func,
  onClick: PropTypes.func,
  shortBody: PropTypes.string,
  state: PropTypes.string,
  subject: PropTypes.string
};

export default ListViewItem;
