// Third Party
import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createEpicMiddleware } from "redux-observable";
import { createLogger } from "redux-logger";
import { reducer as oidcReducer } from "redux-oidc";

// Project
import { rootEpic } from "./rootEpic";
import { USER_CHANGED, USER_RETRIEVED } from "./constants/ActionTypes";
import * as Environment from "./config/environment";
import apiRequest from "./api/apiRequest";
import initialState from "./config/initialState";
import io from "./util/io";
import MessagingServiceClient from "./api/messaging-service/MessagingServiceClient";
import rootReducer from "./rootReducer";
import userManager from "./userManager";

const apiFetch = request => apiRequest(window.fetch, request); // Unified request workflow using Fetch API.

const apiConfiguration = {
  fetch: apiFetch,
  rootUrl: Environment.messagingServiceUrl,
  token: ""
};
const fallbackMessagingClient = {};
const messagingClient = MessagingServiceClient.tryCreate(apiConfiguration)
  .getOrElse(fallbackMessagingClient);

export const defaultDependencies = {
  apiConfiguration,
  io,
  messagingClient,
  userManager
};

/**
 * Initial Redux application state.
 *
 * NOTE(jeremiah.sanders): When used with createStore (exported from this module), this MUST match the shape returned
 *   by all reducers, per Redux Toolkit configureStore JSDoc.
 */
export const defaultInitialState =
{
  messaging: initialState,
  // eslint-disable-next-line no-undefined
  oidc: undefined,
  // eslint-disable-next-line no-undefined
  router: undefined
};

// NOTE (jeremiah.sanders): According to https://github.com/supasate/connected-react-router#step-3, the same reference must be passed to
// routerMiddleware, connectRouter, and the ConnectedRouter component loaded in index.tsx.
//
export const defaultHistory = createBrowserHistory();

/* eslint-disable max-lines-per-function */
/**
 * Creates messaging application Redux state storage.
 *
 * @param {Object|undefined} dependencies - Asynchronous and reactive dependencies.
 * @param {Object} dependencies.io - Input/Output dependencies.
 * @param {Object} dependencies.messagingClient - Messaging service client.
 * @param {Object} dependencies.userManager - A user manager.
 * @param {Object|undefined} preloadedState - Asynchronous and reactive dependencies.
 * @param {Object|undefined} history - History object, as retrieved from 'history' NPM package.
 * @return {Object} State store.
 */
export default function createStore(dependencies, preloadedState, history = defaultHistory) {
  // Providing optional epic options enables rudimentary dependency injection.
  // See: https://redux-observable.js.org/docs/recipes/InjectingDependenciesIntoEpics.html#injecting-dependencies
  const epicOptions = {
    dependencies: { ...defaultDependencies,
      ...dependencies }
  };
  const epicMiddleware = createEpicMiddleware(epicOptions);
  const loggerMiddleware = createLogger();
  const store = configureStore({
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: {
          // SEE: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
          ignoredActions: ["redux-oidc/USER_FOUND", USER_CHANGED, USER_RETRIEVED],
          ignoredPaths: ["oidc.user"]
        }
      }),
      routerMiddleware(history),
      epicMiddleware,
      loggerMiddleware
    ],
    preloadedState,
    reducer: {
      messaging: rootReducer,
      oidc: oidcReducer,
      router: connectRouter(history)
    }
  });

  // Initialize redux-observable.
  epicMiddleware.run(rootEpic);

  return store;
}
