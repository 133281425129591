// Project
import {
  DRAFT_ATTACHMENT_ADD_FAILURE,
  DRAFT_ATTACHMENT_ADD_START,
  DRAFT_ATTACHMENT_ADD_SUCCESS,
  DRAFT_ATTACHMENT_REMOVE_FAILURE,
  DRAFT_ATTACHMENT_REMOVE_START,
  DRAFT_ATTACHMENT_REMOVE_SUCCESS
} from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Update state in response to an attachment add failure.
 *
 * @param {Object} state - Application state.
 * @return {Object} Updated application state.
 */
function draftAttachmentAddFailure(state) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      addingAttachments: false,
      draftAutosaving: false
    }
  };
}

/**
 * Indicate in application state that we are adding attachments.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} - Application state.
 */
function draftAttachmentAddStart(state, action) {
  return state.draftList.currentDraftId === action.draftIdentity ?
    { ...state,
      draftView: {
        ...state.draftView,
        addingAttachments: true,
        draftAutosaving: true
      } } :
    state;
}

/**
 * Set the draftAutosaving and addingAttachments properties to false in application state.
 *
 * @param {Object} state - Application state.
 * @return {Object} Application state.
 */
function draftAttachmentAddSuccess(state) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      addingAttachments: false,
      draftAutosaving: false
    }
  };
}

/**
 * Update state in response to an attachment removal failure.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Update failure action.
 * @return {Object} Updated application state.
 */
function draftAttachmentRemoveFailure(state) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: false }
  };
}

/**
 * Indicate in application state that we are removing an attachment.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} - Application state.
 */
function draftAttachmentRemoveStart(state, action) {
  return state.draftList.currentDraftId === action.draftIdentity ?
    { ...state,
      draftView: {
        ...state.draftView,
        draftAutosaving: true
      } } :
    state;
}

/**
 * Indicate in application state that we are successfully removed an attachment.
 *
 * @param {Object} state - Application state.
 * @return {Object} Application state.
 */
function draftAttachmentRemoveSuccess(state) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      draftAutosaving: false
    }
  };
}

/**
 * Combine the draftAttachments reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Application state.
 */
function draftAttachmentsReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_ATTACHMENT_ADD_FAILURE: return draftAttachmentAddFailure(state, action);

      case DRAFT_ATTACHMENT_ADD_START: return draftAttachmentAddStart(state, action);

      case DRAFT_ATTACHMENT_ADD_SUCCESS: return draftAttachmentAddSuccess(state);

      case DRAFT_ATTACHMENT_REMOVE_FAILURE: return draftAttachmentRemoveFailure(state, action);

      case DRAFT_ATTACHMENT_REMOVE_START: return draftAttachmentRemoveStart(state, action);

      case DRAFT_ATTACHMENT_REMOVE_SUCCESS: return draftAttachmentRemoveSuccess(state);

      default: return state;
    }
  } else {
    return state;
  }
}

export default draftAttachmentsReducer;
