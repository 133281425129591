// Project
import {
  DRAFTS_MULTI_SELECT_ITEMS_ADD,
  DRAFTS_MULTI_SELECT_ITEMS_CLEAR,
  DRAFTS_MULTI_SELECT_ITEMS_REMOVE
} from "../constants/ActionTypes";

/**
 * Add draft identities to state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object
 * @return {Object} Modified application state.
 */
function draftListMultiSelectItemsAdd(state, action) {
  return {
    ...state,
    draftList: {
      ...state.draftList,
      selectedDrafts: [...state.draftList.selectedDrafts.concat(action.draftIdentities)]
    }
  };
}

/**
 * Clear the selected drafts from draftList.
 *
 * @param {Object} state - Application state
 * @return {Object} Modified application state.
 */
function draftListMultiSelectItemsClear(state) {
  return {
    ...state,
    draftList: {
      ...state.draftList,
      selectedDrafts: []
    }
  };
}

/**
 * Remove the selected draft(s) from the selected drafts List.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Modified application state.
 */
function draftListMultiSelectItemsRemove(state, action) {
  const checkSelectedDrafts = value => !action.draftIdentities.find(draftId => draftId === value);

  return {
    ...state,
    draftList: {
      ...state.draftList,
      selectedDrafts: state.draftList.selectedDrafts.filter(checkSelectedDrafts)
    }
  };
}

/**
  * Combine multi-select draft reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object
 * @return {Object} Modified application state.
 */
function draftListMultiSelectReducer(state, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFTS_MULTI_SELECT_ITEMS_ADD: return draftListMultiSelectItemsAdd(state, action);

      case DRAFTS_MULTI_SELECT_ITEMS_CLEAR: return draftListMultiSelectItemsClear(state);

      case DRAFTS_MULTI_SELECT_ITEMS_REMOVE: return draftListMultiSelectItemsRemove(state, action);

      default: return state;
    }
  }

  return state;
}

export default draftListMultiSelectReducer;
