// Third party
import { combineEpics } from "redux-observable";

// Project
import messageAttachmentDownloadRequestEpic from "./messageAttachmentDownloadRequestEpic";
import messageAttachmentDownloadStartEpic from "./messageAttachmentDownloadStartEpic";
import messageAttachmentOpenRequestEpic from "./messageAttachmentOpenRequestEpic";
import messageAttachmentOpenStartEpic from "./messageAttachmentOpenStartEpic";

export default combineEpics(messageAttachmentDownloadRequestEpic,
  messageAttachmentDownloadStartEpic,
  messageAttachmentOpenRequestEpic,
  messageAttachmentOpenStartEpic);
