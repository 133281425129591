// Third party
import compact from "lodash/compact";
import pipe from "lodash/fp/pipe";

// Project
import normalizeMailbox from "./normalizeMailbox";

/**
 * Creates a mailbox headers query URL.
 * @param {Object} queryParameters - Mailbox header query parameter object.
 * @return {String}
 * @private
 */
function queryParametersToUrl(queryParameters) {
  const mailbox = normalizeMailbox(queryParameters.mailbox);
  const folderIdParam = queryParameters && queryParameters.folderId ? `folderId=${queryParameters.folderId}` : "";
  const pageSizeParam = queryParameters && queryParameters.pageSize ? `pageSize=${queryParameters.pageSize}` : "";
  const offsetParam = queryParameters && queryParameters.offset ? `offset=${queryParameters.offset}` : "";

  const queryString = compact([folderIdParam, offsetParam, pageSizeParam]).join("&");
  const queryStringPrefix = queryString ? "?" : "";

  return `api/mailbox/${mailbox}/headers${queryStringPrefix}${queryString}`;
}

const defaultQueryParameters = {
  folderId: null,
  mailbox: null,
  offset: null,
  pageSize: null
};

/**
 * Converts a mailbox address or query parameter object to a query parameter object.
 * @param {Object} addressOrQueryParameters - A String or header list query object.
 * @return {Object} - Query parameter object.
 */
function normalizeQueryParameter(addressOrQueryParameters) {
  return typeof addressOrQueryParameters === "string" ?
    {
      ...defaultQueryParameters,
      mailbox: addressOrQueryParameters
    } :
    addressOrQueryParameters;
}

/**
 * Creates a function which will get the API health check result.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets the API health check result when executed.
 */
function getMailboxHeaders(getFn) {
  return mailboxAddress => pipe(
    normalizeQueryParameter,
    queryParametersToUrl, getFn)(mailboxAddress)
    .then(response => response.json());
}

export default getMailboxHeaders;
