// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { USER_MAILBOXES_REQUESTED } from "../../constants/ActionTypes.js";
import { userMailboxesLoadingErred, userMailboxesRetrieved } from "../../actions/userActions.js";

const userMailboxesRequestedEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(USER_MAILBOXES_REQUESTED),
  mergeMap(() => from(messagingClient.getMyMailboxes())
    .pipe(
      map(myMailboxes => userMailboxesRetrieved(myMailboxes.mailboxes)),
      catchError(error => of(userMailboxesLoadingErred(error)))
    )
  )
);

export default userMailboxesRequestedEpic;
