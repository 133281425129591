// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_ATTACHMENT_ADD_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_ATTACHMENT_ADD_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftAttachmentAddFailureEpic = fromFailureAction(
  DRAFT_ATTACHMENT_ADD_FAILURE,
  constant(DRAFT_ATTACHMENT_ADD_FAILURE_DISPLAY)
);

export default draftAttachmentAddFailureEpic;
