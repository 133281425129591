/**
 * Attempts to retrieve the current messaging user.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Object | null}
 */
export function tryGetUser(state) {
  return state?.messaging?.user;
}

/**
 * Attempts to retrieve the current messaging user's display name.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {String | null}
 */
export function tryGetUserDisplayName(state) {
  return tryGetUser(state)?.displayName;
}

/**
 * Attempts to retrieve the current messaging user's identity.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {String | null}
 */
export function tryGetUserIdentity(state) {
  return tryGetUser(state)?.identity;
}

/**
 * Attempts to retrieve the current messaging user's mailboxes.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {String | null}
 */
export function tryGetUserMailboxes(state) {
  return tryGetUser(state)?.mailboxes;
}
