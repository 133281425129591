// Project
import getDraftIdentityFromLocation from "../draft/getDraftIdentityFromLocation";
import getResponseHeaderLocation from "../../../getResponseHeaderLocation";

/**
 * Executes the postFn with a message derive draft service URL and an API request body, if all parameters are provided.
 *
 * @param {Function} postFn - Function which accepts a URL and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} messageIdentity - Message identity to derive a draft from.
 * @param {String} derivedDraftType - Type of derived draft. API supports values 'reply', 'replyAll', and 'forward'.
 * @return {Promise.<MailboxDraft>}
 */
function postMailboxMessageDerive(postFn, mailboxAddress, messageIdentity, derivedDraftType) {
  return mailboxAddress && messageIdentity && derivedDraftType ?
    postFn(`api/mailbox/${mailboxAddress}/message/${messageIdentity}/derive`, { type: derivedDraftType })
      .then(getResponseHeaderLocation)
      .then(getDraftIdentityFromLocation) :
    Promise.reject(new Error("Mailbox address, message identity, and derived draft type are required"));
}

export default postMailboxMessageDerive;
