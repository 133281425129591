// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { ccAddressUpdateStart } from "../../actions/draftCcActions";
import { DRAFT_REMOVE_CC_ADDRESSEE } from "../../constants/ActionTypes";

const isAutosaving = true;

/* eslint-disable arrow-body-style */
const removeCcAddresseeEpic = action$ => action$.pipe(
  ofType(DRAFT_REMOVE_CC_ADDRESSEE),
  map(action => ccAddressUpdateStart(action.mailboxAddress, action.draftIdentity, isAutosaving))
);

export default removeCcAddresseeEpic;
