// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_LIST_LOADING_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_LIST_LOADING_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftListLoadingFailureEpic = fromFailureAction(
  DRAFT_LIST_LOADING_FAILURE,
  constant(DRAFT_LIST_LOADING_FAILURE_DISPLAY)
);

export default draftListLoadingFailureEpic;
