// Third Party
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import CancelEditButton from "./CancelEditButton";
import SaveEditButton from "./SaveEditButton";

/* eslint-disable max-lines-per-function */
/**
 * Build the folder-name editor.
 *
 * @param {Object} props - EditorGroup properties.
 * @constructor
 */
function TextEditor(props) {
  const cancelButton = props.onCancelClick ?
    <InputGroupAddon addonType="append" >
      <CancelEditButton
        onClick={props.onCancelClick}
      />
    </InputGroupAddon> :
    "";
  const saveButtonDisabledState = !props.workingValue;

  /* eslint-disable arrow-body-style */
  const handleInputChange = event => {
    return props.onInputChange(event.target.value);
  };

  return (
    <InputGroup id="editor-group-wrapper" >
      <Input
        id="editor-group-input"
        onChange={handleInputChange}
        placeholder="New Folder Name"
        type="text"
        value={props.workingValue ?? ""} />
      <InputGroupAddon addonType="append" >
        <SaveEditButton
          disabled={saveButtonDisabledState}
          onClick={props.onSaveClick}
        />
      </InputGroupAddon>
      { cancelButton }
    </InputGroup>
  );
}

TextEditor.propTypes = {
  onCancelClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onSaveClick: PropTypes.func,
  workingValue: PropTypes.string
};

export default TextEditor;
