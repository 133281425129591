// Third Party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./PageHeader.css";
import { tryGetUserDisplayName } from "../selectors/messaging/user";
import { userSignOutRequested } from "../actions/userActions";
import ApplicationUserInfo from "./ApplicationUserInfo";
import Logo from "./Logo";

/**
 * Page header component.
 *
 * @constructor
 * @param {Object} props - Page header rendering properties.
 * @param {Function} props.onSignOutClick - Callback when sign out button is clicked.
 * @param {String} props.userDisplayName - Application user display name.
 */
function PageHeader(props) {
  return (
    <header id="page-header">
      <Logo
        className="align-self-center"
        style={({ height: "2em",
          padding: "0.125em" })}
      />
      <ApplicationUserInfo
        onSignOutClick={props.onSignOutClick}
        userName={props.userDisplayName}
      />
    </header>
  );
}

PageHeader.propTypes = {
  onSignOutClick: PropTypes.func,
  userDisplayName: PropTypes.string.isRequired
};

// Component Setup
const mapDispatchToProps = dispatch => ({
  onSignOutClick: () => dispatch(userSignOutRequested())
});

const mapStateToProps = state => ({
  userDisplayName: tryGetUserDisplayName(state) || ""
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
