// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { MESSAGE_LOADING_START } from "../constants/ActionTypes";
import { messageLoadingErred, messageRetrieved } from "../actions/messageViewActions";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const messageLoadingEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MESSAGE_LOADING_START),
  mergeMap(action => from(messagingClient.getMailboxMessage(action.mailboxAddress, action.messageIdentity))
    .pipe(
      map(response => messageRetrieved(action.mailboxAddress, action.messageIdentity, response.message)),
      catchError(error => of(messageLoadingErred(action.mailboxAddress, action.messageIdentity, error)))
    )
  )
);

export default messageLoadingEpic;
