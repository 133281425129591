// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_BODY_UPDATE_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_UPDATE_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftBodyUpdateFailureEpic = fromFailureAction(
  DRAFT_BODY_UPDATE_FAILURE,
  constant(DRAFT_UPDATE_FAILURE_DISPLAY)
);

export default draftBodyUpdateFailureEpic;
