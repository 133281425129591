// Third Party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./index.css";
import { notificationDismiss, notificationDismissDelayed, notificationDismissDelayedAllow, notificationDismissDelayedPrevent } from "../../actions/notificationActions";
import NotificationItem from "./NotificationItem";

/* eslint-disable max-lines-per-function */
/**
 * Build the notification list view.
 *
 * @constructor
 * @param {Object} props - Notification view properties.
 * @param {Array} props.notifications - Notification list.
 */
function NotificationView(props) {
  const notificationItems = props.notifications.map(notification => {
    const key = `${notification.triggeringAction?.type}.${notification.timestamp}`;
    /* eslint-disable max-len */
    const onCloseClick = () => props.onNotificationCloseClick(notification.triggeringAction?.type, notification.timestamp);
    const onMouseEnter = () => props.onNotificationMouseEnter(notification.triggeringAction?.type, notification.timestamp);
    const onMouseLeave = () => props.onNotificationMouseLeave(notification.triggeringAction?.type, notification.timestamp);
    /* eslint-enable max-len */

    return <NotificationItem
      key={key}
      message={notification.message}
      onCloseClick={onCloseClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      severity={notification.severity}
    />;
  });

  return (
    <div className="notification-view">
      {notificationItems}
    </div>
  );
}
/* eslint-enable max-lines-per-function */

NotificationView.propTypes = {
  notifications: PropTypes.array,
  onNotificationCloseClick: PropTypes.func,
  onNotificationMouseEnter: PropTypes.func,
  onNotificationMouseLeave: PropTypes.func
};

/* eslint-disable max-len */
const mapDispatchToProps = dispatch => ({
  onNotificationCloseClick: (triggeringActionType, timestamp) => dispatch(notificationDismiss(triggeringActionType, timestamp)),
  onNotificationMouseEnter: (triggeringActionType, timestamp) => dispatch(notificationDismissDelayedPrevent(triggeringActionType, timestamp)),
  onNotificationMouseLeave: (triggeringActionType, timestamp) => {
    dispatch(notificationDismissDelayedAllow(triggeringActionType, timestamp));
    dispatch(notificationDismissDelayed(triggeringActionType, timestamp));
  }
});
/* eslint-enable max-len */

const mapStateToProps = state => ({
  notifications: state.messaging.notifications
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView);
