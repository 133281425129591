// Third Party
import { constant } from "lodash";

// Project
import { FOLDER_NAME_UPDATE_FAILURE } from "../constants/ActionTypes";
import { FOLDER_NAME_UPDATE_FAILURE_DISPLAY } from "../constants/Errors";
import fromFailureAction from "./util/fromFailureAction";

const folderNameUpdateFailureEpic = fromFailureAction(
  FOLDER_NAME_UPDATE_FAILURE,
  constant(FOLDER_NAME_UPDATE_FAILURE_DISPLAY)
);

export default folderNameUpdateFailureEpic;
