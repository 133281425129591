// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { EDIT_FOLDER_NAME_SAVE } from "../../constants/ActionTypes.js";
import { folderNameUpdateStart } from "../../actions/messageNavigation/folderEditActions.js";

const editFolderNameSaveEpic = (action$, state$) => action$.pipe(
  ofType(EDIT_FOLDER_NAME_SAVE),
  map(action => folderNameUpdateStart(
    action.mailboxAddress,
    action.folderId,
    state$.value.messaging.messageNavigation.currentFolderWorkingDisplayName))
);

export default editFolderNameSaveEpic;
