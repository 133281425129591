// Project
import { MESSAGE_LIST } from "../constants/ListViewPanes";
import { DEFAULT_VIEW } from "../constants/DetailViewPanes";
import initialDraftListState from "./initialDraftListState";
import initialDraftMessageState from "./initialDraftMessageState";
import initialMessageListState from "./initialMessageListState";
import initialMessageNavigationState from "./initialMessageNavigationState";
import initialMessageViewState from "./initialMessageViewState";

const initialState = {
  detailViewPane: DEFAULT_VIEW,
  draftList: initialDraftListState,
  draftView: initialDraftMessageState,
  listViewPane: MESSAGE_LIST,
  mailbox: null,
  messageList: initialMessageListState,
  messageNavigation: initialMessageNavigationState,
  messageView: initialMessageViewState,
  notifications: [],
  user: null
};

export default initialState;
