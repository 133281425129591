/**
 * Convert an address string to an address object.
 *
 * @param {String} address - Email address to be converted.
 * @return {Object} Address object.
 */
export default function convertAddressToAddressee(address) {
  return {
    address: address,
    displayName: ""
  };
}
