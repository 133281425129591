// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { USER_PROFILE_REQUESTED } from "../../constants/ActionTypes.js";
import { userMailboxesRequested } from "../../actions/userActions.js";


const userProfileRequestedEpic = action$ => action$.pipe(
  ofType(USER_PROFILE_REQUESTED),
  map(() => userMailboxesRequested())
);

export default userProfileRequestedEpic;
