// Third Party
import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFTS_SELECTED } from "../constants/ActionTypes.js";
import { draftListLoadingStart } from "../actions/draftsActions";
import { draftListMultiSelectItemsClear } from "../actions/draftListMultiSelectActions";
import { messageListMultiSelectItemsClear } from "../actions/messageListMultiSelectActions";

const draftsSelectedEpic = action$ => action$.pipe(
  ofType(DRAFTS_SELECTED),
  mergeMap(action => [
    draftListMultiSelectItemsClear(action.mailboxAddress),
    messageListMultiSelectItemsClear(action.mailboxAddress),
    draftListLoadingStart(action.mailboxAddress)
  ])
);

export default draftsSelectedEpic;
