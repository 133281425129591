// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { tryGetUserIdentity as tryGetOidcUserIdentity } from "../../selectors/oidcUser";
import { tryGetUserIdentity } from "../../selectors/messaging/user";
import { USER_RETRIEVED } from "../../constants/ActionTypes.js";
import { userChanged } from "../../actions/userActions.js";

/**
 * Checks to see if the user differs from the current application user.
 * @param {Object} applicationState - Application state.
 * @param {User} user - An OAuth2 user object.
 * @return {Boolean}
 * @private
 */
function hasUserChanged(applicationState, user) {
  // NOTE(jeremiah.sanders): oidc-client User object's .profile.sub property is the subject claim.
  const newUserIdentity = tryGetOidcUserIdentity(user);
  const currentUserIdentity = tryGetUserIdentity(applicationState);

  return newUserIdentity !== currentUserIdentity;
}

const userRetrievedEpic = (action$, state$) => action$.pipe(
  ofType(USER_RETRIEVED),
  filter(action => hasUserChanged(state$.value, action.user)),
  map(action => userChanged(action.user))
);

export default userRetrievedEpic;
