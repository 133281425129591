// Third Party
import PropTypes from "prop-types";
import React from "react";

// Project
import TrashButton from "../formElements/TrashButton";
import "./DraftListHeader.css";

/**
 * Draft-list header container.
 *
 * @constructor
 * @param {Object} props - Header properties.
 * @param {Boolean} props.multiItemActionsDisabled - Flag indicating whether the multi-item action elements should be
 *   disabled.
 * @param {Function} props.onClick - Click function for the the trash button.
 */
function DraftListHeader(props) {
  return (
    <header id="draft-list-header">
      <h5 className="folder-name text-truncate align-self-center">Drafts</h5>
      <div className="multi-message-command-buttons">
        <TrashButton
          disabled={props.multiItemActionsDisabled}
          onClick={props.onClick}
          title="Delete selected draft(s)"
        />
      </div>
    </header>
  );
}

DraftListHeader.propTypes = {
  multiItemActionsDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default DraftListHeader;
