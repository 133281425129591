// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { CREATE_MESSAGES_MOVE_FOLDER_REQUEST } from "../constants/ActionTypes";
import { messagesMoveFolderRequest } from "../actions/messages/messagesMoveFolderActions";

const messageSelectedEpic = (action$, state$) => action$.pipe(
  ofType(CREATE_MESSAGES_MOVE_FOLDER_REQUEST),
  map(action => messagesMoveFolderRequest(
    action.mailboxAddress,
    state$.value.messaging.messageList.selectedMessages,
    action.folderId
  ))
);

export default messageSelectedEpic;
