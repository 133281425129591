// Project
import { DEFAULT_VIEW } from "../constants/DetailViewPanes";
import {
  MAILBOX_FOLDER_SELECTED,
  MAILBOX_FOLDERS_LOADING_SUCCESS
} from "../constants/ActionTypes";
import { MESSAGE_LIST } from "../constants/ListViewPanes";
import * as applicationConfig from "../config/application";
import initialState from "../config/initialState";

const { defaultFolderId } = applicationConfig.messageNavigation;

/**
 * Remove message headers from application state.
 *
 * @param {Object} state - Application state.
 * @return {Object} Application state.
 */
function clearMessageList(state) {
  return {
    ...state,
    messageList: {
      ...state.messageList,
      messageHeaders: []
    }
  };
}

/* eslint-disable max-lines-per-function */
/**
 *  Set the current folder identity.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action type.
 * @return {Object} Application state
 */
function mailboxFolderSelected(state, action) {
  if (state.messageNavigation.currentFolderId !== action.folderId) {
    clearMessageList(state);
  }

  return {
    ...state,
    detailViewPane: DEFAULT_VIEW,
    draftList: {
      ...state.draftList,
      currentDraftId: ""
    },
    listViewPane: MESSAGE_LIST,
    messageList: {
      ...state.messageList,
      currentMessageId: ""
    },
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderId: action.folderId
    }
  };
}

/**
 *  Check to see if the current folder exists in the mailbox folders.
 *
 * @param {String} currentFolderId - Application state.
 * @param {Object} action  - An action
 * @return {Boolean} Current folder exists in the mailbox folders.
 */
function currentFolderExists(currentFolderId, action) {
  return action.systemFolders.findIndex(folder => folder.folderId === currentFolderId) >= 0 ||
   action.mailboxFolders.findIndex(folder => folder.folderId === currentFolderId) >= 0;
}

/**
 *  Add the mailbox folder lists to state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action.
 * @return {Object} Application state.
 */
function mailboxFoldersLoadingSuccess(state, action) {
  const currentFolderId = currentFolderExists(state.messageNavigation.currentFolderId, action) ?
    state.messageNavigation.currentFolderId :
    defaultFolderId;
  /* eslint-disable no-confusing-arrow */
  const sortedMailboxFolders = [...action.mailboxFolders].sort(
    (a, b) => a.displayName.toUpperCase() > b.displayName.toUpperCase() ? 1 : -1);
  const sortedSystemFolders = [...action.systemFolders].sort((a, b) => a.displayName > b.displayName ? 1 : -1);

  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderId: currentFolderId,
      mailboxFolders: sortedMailboxFolders,
      systemFolders: sortedSystemFolders
    }
  };
}

/**
 * Combine message navigation reducers.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action.
 * @return {Object} Application state.
 *
 */
function messageNavigationReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case MAILBOX_FOLDER_SELECTED: return mailboxFolderSelected(state, action);

      case MAILBOX_FOLDERS_LOADING_SUCCESS: return mailboxFoldersLoadingSuccess(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}

export default messageNavigationReducer;
