/**
 * Converts a Date to a full (date and time) display format.
 * @param {Date} date - A Date.
 * @return {String}
 */
function getFullDate(date) {
  return date
    .toLocaleString("en-us")
    .replace(",", "");
}

export default getFullDate;
