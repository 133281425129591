// Third Party
import { Route, Switch } from "react-router-dom";
import React from "react";

// Project
import { mailbox, signInFailed, signInRequested, signInResponseProcessing, unauthenticated } from "./constants/PageRoutes";
import Mailbox from "./pages/Mailbox";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import SignInFailed from "./pages/SignInFailed";
import SignInRequested from "./pages/SignInRequested";
import SignInResponseProcessing from "./pages/SignInResponseProcessing";
import Unauthenticated from "./pages/Unauthenticated";

/**
 * Creates the react-router routes component which selectively renders components based upon browser path.
 *
 * @constructor
 * @return {Object} A routes JSX node.
 */
function Routes() {
  return <Switch>
    <Route path={signInResponseProcessing} component={SignInResponseProcessing} />
    <ProtectedRoute exact path={mailbox} component={Mailbox} />
    <Route exact path={signInFailed} component={SignInFailed} />
    <Route exact path={signInRequested} component={SignInRequested} />
    <Route exact path={unauthenticated} component={Unauthenticated} />
    <Route path="*" component={ NotFound } />
  </Switch>;
}

export default Routes;
