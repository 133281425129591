// Project
import { DRAFT_LIST } from "../constants/ListViewPanes";
import { DRAFT_LIST_LOADING_SUCCESS, DRAFTS_SELECTED } from "../constants/ActionTypes";
import initialState from "../config/initialState";

/**
 * Set the draft list in application state.
 *
 * @param {String} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function draftListLoadingSuccess(state, action) {
  const sortedDraftList = [...action.drafts].sort((a, b) => (b.lastUpdatedDateTime > a.lastUpdatedDateTime ? 1 : -1));

  return {
    ...state,
    draftList: {
      ...state.draftList,
      drafts: sortedDraftList
    }
  };
}

/**
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object.
 * @return {Object} Updated application state.
 */
function draftsSelected(state) {
  return {
    ...state,
    listViewPane: DRAFT_LIST,
    messageNavigation: {
      ...state.messageNavigation,
      currentFolderId: ""
    }
  };
}

/**
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action object.
 * @return {Object} Application state.
 */
function draftsReducers(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_LIST_LOADING_SUCCESS: return draftListLoadingSuccess(state, action);

      case DRAFTS_SELECTED: return draftsSelected(state);

      default: return state;
    }
  }

  return state;
}

export default draftsReducers;
