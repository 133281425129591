// Third Party
import { Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

const createMailboxes = ({ mailboxes, onMailboxSelected }) => mailboxes.map(mailbox => {
  const { address } = mailbox;
  const clickHandler = () => onMailboxSelected(address);

  return (
    <ListGroupItem key={address}
      action
      className="selectable"
      aria-label={`Mailbox ${address}`}
      onClick={clickHandler}
      tag="a">
      {address}
    </ListGroupItem>
  );
});

/**
 * Creates a mailbox selection list.
 * @remarks This element wraps its children with a 'span' which attempts to enforce a 1em sizing upon its content.
 *   It is intended to be used surrounding a FontAwesomeIcon (which renders an SVG).
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Array} props.mailboxes - Mailboxes which the user may select. Assumed to be a string array.
 * @param {Array} props.onMailboxSelected - Callback executed when the user selects a mailbox.
 * @return {Object} JSX component.
 */
function MailboxSelectionList(props) {
  const mailboxes = createMailboxes(props);

  return (
    <Card>
      <CardHeader>
        Assigned mailboxes
      </CardHeader>
      <CardBody>
        <CardTitle tag="h5">Please select a mailbox:</CardTitle>
      </CardBody>
      <ListGroup aria-label="Mailbox selections" flush>
        {mailboxes}
      </ListGroup>
    </Card>
  );
}

MailboxSelectionList.propTypes = {
  mailboxes: PropTypes.array.isRequired,
  onMailboxSelected: PropTypes.func.isRequired
};
export default MailboxSelectionList;
