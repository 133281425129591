// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { draftDiscardFailure, draftDiscardSuccess } from "../../actions/draftMessage/draftDeleteActions";
import { DRAFT_DISCARD_START } from "../../constants/ActionTypes.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const draftDiscardStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_DISCARD_START),
  mergeMap(action => from(messagingClient.deleteDrafts(action.mailboxAddress, action.draftIdentities))
    .pipe(
      map(() => draftDiscardSuccess(action.mailboxAddress, action.draftIdentities)),
      catchError(error => of(draftDiscardFailure(action.mailboxAddress, action.draftIdentities, error)))
    )
  )
);

export default draftDiscardStartEpic;
