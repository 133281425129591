// Third party
import { combineEpics } from "redux-observable";

// Project
import oidcUserFoundEpic from "./oidcUserFoundEpic";
import userChangedEpic from "./userChangedEpic";
import userProfileRequestedEpic from "./userProfileRequestedEpic";
import userRequestedEpic from "./userRequestedEpic";
import userRetrievedEpic from "./userRetrievedEpic";
import userSignInRequestedEpic from "./userSignInRequestedEpic";
import userSignOutRequestedEpic from "./userSignOutRequestedEpic";
import userMailboxesRequestedEpic from "./userMailboxesRequestedEpic";
import userMailboxesRetrievedEpic from "./userMailboxesRetrievedEpic";

export default combineEpics(
  oidcUserFoundEpic,
  userRequestedEpic,
  userRetrievedEpic,
  userChangedEpic,
  userProfileRequestedEpic,
  userMailboxesRequestedEpic,
  userMailboxesRetrievedEpic,
  userSignInRequestedEpic,
  userSignOutRequestedEpic
);
