// Project
import { MAILBOX_CHANGED, MAILBOX_EXITED } from "../constants/ActionTypes";

/**
 * Creates a Mailbox Changed action.
 * @param {String} mailboxAddress - A mailbox address.
 * @return {Object}
 */
export function mailboxChanged(mailboxAddress) {
  return {
    mailboxAddress,
    type: MAILBOX_CHANGED
  };
}

/**
 * Creates a Mailbox Exited action. User is leaving their active mailbox.
 * @return {Object}
 */
export function mailboxExited() {
  return {
    type: MAILBOX_EXITED
  };
}
