/**
 * Creates a function which will get the API health check result.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets the API health check result when executed.
 */
function getApiHealth(getFn) {
  return () => getFn("api").then(response => response.json());
}

export default getApiHealth;
