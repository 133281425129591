// Third Party
import { Button, CardSubtitle, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { userSignInRequested } from "../actions/userActions";
import FullPageCareAlignCard from "../components/FullPageCareAlignCard";

/**
 * SignInFailed landing page.
 *
 * @param {Object} props - Rendering properties.
 * @param {Function} props.onSignInClick - On click callback for Sign In button.
 * @return {Object}
 */
function SignInFailed(props) {
  return <FullPageCareAlignCard>
    <CardTitle tag="h2">Your sign in request could not be processed.</CardTitle>
    <CardSubtitle tag="h3">If you believe this is in error, please try signing in again.</CardSubtitle>
    <Button onClick={props.onSignInClick} size="lg" title="Sign In" color="primary" style={({ marginTop: "1em" })}>Sign In</Button>
  </FullPageCareAlignCard>;
}

SignInFailed.propTypes = {
  onSignInClick: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ onSignInClick: () => dispatch(userSignInRequested()) });

export default connect(mapStateToProps, mapDispatchToProps)(SignInFailed);
