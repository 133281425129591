// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { USER_CHANGED } from "../../constants/ActionTypes.js";
import { userProfileRequested } from "../../actions/userActions.js";
import MessagingServiceClient from "../../api/messaging-service/MessagingServiceClient.js";

const userChangedEpic = (action$, _, { apiConfiguration, messagingClient }) => action$.pipe(
  ofType(USER_CHANGED),
  map(userAction => {
    // NOTE(jeremiah.sanders): Though this could return an Error, we are ignoring that because the user cannot
    //   fix/retry and receive a different result (either the deployed configuration is wrong, or the action has an
    //   unexpected shape).
    // NOTE(jeremiah.sanders): Mutating dependencies is undesirable. However, I've found no redux-observable APIs which
    //   allow replacing dependencies. Since we do not have the authentication token until after the application user
    //   is loaded, we must create a MessagingServiceClient which has no authentication at launch and then apply
    //   authentication once it is available.
    MessagingServiceClient.tryReplaceApiClient({ ...apiConfiguration,
      token: userAction.user.access_token }, messagingClient);

    return userProfileRequested();
  })
);

export default userChangedEpic;
