/**
 * Return the folder display name based upon the folder identity.
 *
 * @param {String} folderId - Folder identity.
 * @param {Array} mailboxFolders - Array of folder objects.
 * @return {String} Folder display name or empty string.
 */
function getFolderNameFromId(folderId, mailboxFolders) {
  let foundFolder = null;

  if (folderId && mailboxFolders.length) {
    foundFolder = mailboxFolders.find(folder => folder.folderId === folderId);
  }

  return foundFolder ? foundFolder.displayName : "";
}

export default getFolderNameFromId;
