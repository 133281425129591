// Third Party
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import Icon from "../Icon";

/**
 * Active Mailbox display.
 *
 * @private
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {String} props.className - CSS class(es).
 * @param {String} props.mailbox - Current mailbox.
 * @param {Function} props.onClick - Callback executed when clicked.
 */
function ActiveMailbox(props) {
  // NOTE(jeremiah.sanders): The "mr-1" class below is a Bootstrap spacing utility class. See: https://getbootstrap.com/docs/4.0/utilities/spacing/
  return (
    <span aria-label="Mailbox" className={props.className} onClick={props.onClick}>
      <Icon aria-label="Mailbox icon" className="email-icon mr-1">
        <FontAwesomeIcon icon={faEnvelopeSquare} />
      </Icon>
      { props.mailbox }
    </span>
  );
}

ActiveMailbox.propTypes = {
  className: PropTypes.string,
  mailbox: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default ActiveMailbox;
