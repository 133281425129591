// Third Party
import { catchError, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { isEmpty } from "lodash";
import { ofType } from "redux-observable";


// Project
import { DRAFT_ATTACHMENT_ADD_SAVE } from "../../constants/ActionTypes";
import {
  draftAttachmentAddFailure,
  draftAttachmentAddSave,
  draftAttachmentAddSuccess
} from "../../actions/draftAttachmentsActions";


/* eslint-disable no-unused-vars */
/**
 * Handles successful draft attachment submissions to messaging service.
 * @param {Object} action - A DRAFT_ATTACHMENT_ADD_SAVE action.
 * @param {Object} response - An attachment response from messaging service client.
 * @return {Observable<Action>}
 * @private
 */
function handleSuccessfulServiceResponse(action, response) {
  const addSuccess = draftAttachmentAddSuccess(
    action.mailboxAddress,
    action.draftIdentity,
    action.attachment
  );

  return isEmpty(action.pendingAttachments) ?
    of(addSuccess) :
    of(addSuccess,
      draftAttachmentAddSave(
        action.mailboxAddress,
        action.draftIdentity,
        action.pendingAttachments[0],
        action.pendingAttachments.slice(1)
      )
    );
}
/* eslint-enable no-unused-vars */

/**
 * Handles failed draft attachment submissions to messaging service.
 * @param {Object} action - A DRAFT_ATTACHMENT_ADD_SAVE action.
 * @param {Error} error - An error returned from messaging service client.
 * @return {Observable<Action>}
 * @private
 */
function handleErrorServiceResponse(action, error) {
  return isEmpty(action.pendingAttachments) ?
    of(draftAttachmentAddFailure(action.mailboxAddress, action.draftIdentity, action.attachment.fileName, error)) :
    of(
      draftAttachmentAddFailure(action.mailboxAddress, action.draftIdentity, action.attachment.fileName, error),
      draftAttachmentAddSave(
        action.mailboxAddress,
        action.draftIdentity,
        action.pendingAttachments[0],
        action.pendingAttachments.slice(1)
      )
    );
}

/**
 * Create an Observable which responds to DRAFT_ATTACHMENT_ADD_SAVE, submitting attachments to Messaging Service.
 * @param {Observable<Action>} action$ - An action stream.
 * @param {Observable<Object>} state$ - A state stream.
 * @param {Object} dependencies - Epic dependencies.
 * @return {Observable<Action>}
 */
function draftAttachmentAddSaveEpic(action$, state$, { messagingClient }) {
  return action$.pipe(
    ofType(DRAFT_ATTACHMENT_ADD_SAVE),
    mergeMap(action => from(messagingClient.addDraftAttachment(
      action.mailboxAddress,
      action.draftIdentity,
      action.attachment))
      .pipe(
        mergeMap(response => handleSuccessfulServiceResponse(action, response)),
        catchError(error => handleErrorServiceResponse(action, error)
        )
      )
    )
  );
}

export default draftAttachmentAddSaveEpic;
