// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { draftListMultiSelectItemsClear } from "../../../actions/draftListMultiSelectActions";
import { DRAFT_DISCARD_SUCCESS } from "../../../constants/ActionTypes.js";

/**
 * Emits a request to (re)load the current draft folder headers after trashing drafts.
 * @param {Observable} action$ - An action observable.
 * @param {Observable} state$ - State observable.
 * @return {Observable} - An arranged observable.
 */
const draftDiscardSuccessClearSelectedDraftsEpic = (action$, state$) => action$.pipe(
  ofType(DRAFT_DISCARD_SUCCESS),
  filter(() => state$.value.messaging.draftList.selectedDrafts.length !== 1 ||
    state$.value.messaging.draftList.selectedDrafts[0] !== state$.value.messaging.draftList.currentDraftId),
  map(action => draftListMultiSelectItemsClear(action.mailboxAddress))
);

export default draftDiscardSuccessClearSelectedDraftsEpic;
