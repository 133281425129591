// Third Party
import { constant } from "lodash";

// Project
import { MAILBOX_FOLDERS_LOADING_FAILURE } from "../../constants/ActionTypes";
import { MAILBOX_FOLDERS_LOADING_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const mailboxFoldersLoadingFailureEpic = fromFailureAction(
  MAILBOX_FOLDERS_LOADING_FAILURE,
  constant(MAILBOX_FOLDERS_LOADING_FAILURE_DISPLAY)
);

export default mailboxFoldersLoadingFailureEpic;
