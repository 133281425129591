// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { messageAttachmentDownloadFailed, messageAttachmentDownloadSucceeded } from "../../../../actions/messageViewActions";
import { MESSAGE_ATTACHMENT_DOWNLOAD_START } from "../../../../constants/ActionTypes";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const messageAttachmentDownloadStartEpic = (action$, state$, { io, messagingClient }) => action$.pipe(
  ofType(MESSAGE_ATTACHMENT_DOWNLOAD_START),
  mergeMap(action => from(
    messagingClient.getMailboxMessageAttachment(action.mailboxAddress, action.messageIdentity,
      action.attachmentIdentifier)
      .then(io.downloadResponse))
    .pipe(
      map(() => messageAttachmentDownloadSucceeded(action.mailboxAddress, action.messageIdentity,
        action.attachmentIdentifier)),
      catchError(error => of(messageAttachmentDownloadFailed(action.mailboxAddress, action.messageIdentity, error)))
    )
  )
);

export default messageAttachmentDownloadStartEpic;
