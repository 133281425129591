/* eslint-disable max-lines-per-function */
// Third Party
import { CustomFileInput, Label } from "reactstrap";
import { noop } from "lodash";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./Attachments.css";
import AttachmentListItem from "../formElements/AttachmentListItem";

const fileUploadId = "draft-attachments-file-selector";

/**
 * Create an AddAttachment component.
 * @constructor
 * @param {Object} props - Component props.
 * @param {Function} props.onChange - Callback when the file selection changes.
 * @return {Component} - Returns an AddAttachment JSX Component.
 * @private
 */
function AddAttachment({ onChange, ...rest }) {
  // NOTE (jeremiah.sanders): Id specified because Reactstrap CustomFileInput component requires an id property.
  return (
    <div {...rest}>
      <CustomFileInput
        id={fileUploadId}
        multiple
        onChange={onChange}
        title="Select attachment(s)"
      />
    </div>
  );
}

AddAttachment.propTypes = {
  onChange: PropTypes.func
};

/**
 * Draft attachments component.
 *
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Array} props.attachments - Attachments.
 * @param {Function} props.onAttachmentClick - Callback when an attachment is clicked.
 * @param {Function} props.onAttachmentRemoveClick - Callback when an attachment's remove button is clicked.
 * @param {Function} props.onFilesSelected - Callback when new attachment files are selected.
 * @return {Component} - Returns an Attachments JSX Component.
 */
function Attachments({ attachments, onAttachmentClick, onAttachmentRemoveClick, onFilesSelected, ...rest }) {
  const fileSelectedHandler = onFilesSelected || noop;
  const attachmentClickHandler = onAttachmentClick || noop;
  const attachmentRemoveClickHandler = onAttachmentRemoveClick || noop;
  /* eslint-disable arrow-body-style */
  const handleFileInputOnChange = event => {
    return fileSelectedHandler(event.target.files);
  };

  const attachmentComponents = attachments
    .map(attachment => {
      const clickHandler = () => attachmentClickHandler(attachment.attachmentIdentifier);
      const removeHandler = () => attachmentRemoveClickHandler(attachment.attachmentIdentifier);

      return <AttachmentListItem
        className="attachment-item"
        fileName={attachment.fileName}
        hideDownload={true}
        key={attachment.attachmentIdentifier}
        onAttachmentClick={clickHandler}
        onRemoveClick={removeHandler}
      />;
    });

  return (
    <div {...rest}>
      <div className="d-flex justify-content-between">
        <Label className="align-self-center">Attachments:&nbsp;{attachments.length}</Label>
        <AddAttachment onChange={handleFileInputOnChange} />
      </div>
      <div className="d-flex flex-wrap">{attachmentComponents}</div>
    </div>
  );
}

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  onAttachmentClick: PropTypes.func,
  onAttachmentRemoveClick: PropTypes.func,
  onFilesSelected: PropTypes.func
};

export default Attachments;
