// Third Party
import { CardSubtitle, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

// Project
import { userSignInRequested } from "../actions/userActions";
import FullPageCareAlignCard from "../components/FullPageCareAlignCard";

/**
 * SignInRequested page.
 * Interstitial page which indicates we're preparing to send the user to their identity provider.
 *
 * @param {Object} props - Rendering properties.
 * @param {Function} props.onFirstRenderComplete - Callback which React will execute once first render is completed.
 * @return {Object}
 */
function SignInRequested({ onFirstRenderComplete }) {
  // NOTE(jeremiah.sanders): The first parameter of useEffect is a side-effect callback.
  //   This callback, by default, is executed _after_ each render execution of this component.
  //     This function is effectively equivalent to implementing the same body in _both_ .componentDidMount() and
  //     .componentDidUpdate() in a class (rather than functional) component.
  //   The second parameter (optional) is an array of parameters which may change between render executions, which
  //   React uses to determine if the callback (first param) needs to be executed again.
  //   Omitting the second parameter causes the callback to be executed after each render cycle (both after initial
  //   render and after each props change) for this component.
  // See: https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
  useEffect(() => onFirstRenderComplete(), []);

  return <FullPageCareAlignCard>
    <CardTitle tag="h2">Sign in requested. Please wait.</CardTitle>
    <CardSubtitle tag="h3">We are requesting your sign in details. You will be redirected shortly.</CardSubtitle>
  </FullPageCareAlignCard>;
}

SignInRequested.propTypes = {
  onFirstRenderComplete: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ onFirstRenderComplete: () => dispatch(userSignInRequested()) });

export default connect(mapStateToProps, mapDispatchToProps)(SignInRequested);
