// Third Party
import { Button } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { messageListRequested } from "../../actions/messageListActions";

export const title = "Check for more results";

/**
 * Renders a button for requesting the next page of results.
 *
 * @param {Object} props - Rendering properties.
 * @param {String} props.currentFolderId - Current folderId.
 * @param {Number} props.currentMessageHeadersLength - Length of currently-loaded message headers.
 * @param {String} props.mailboxAddress - Current mailbox address.
 * @param {Function} props.onMessageListRequested - Dispatches a messageListRequested action.
 * @return {ReactElement}
 */
function RequestNextPageButton(props) {
  const requestNextPage = () => {
    props.onMessageListRequested(props.mailboxAddress, props.currentFolderId, props.currentMessageHeadersLength);
  };

  return <Button onClick={requestNextPage} title={title}>{title}</Button>;
}

RequestNextPageButton.propTypes = {
  currentFolderId: PropTypes.string.isRequired,
  currentMessageHeadersLength: PropTypes.number,
  mailboxAddress: PropTypes.string.isRequired,
  onMessageListRequested: PropTypes.func
};

const mapDispatchToProps = {
  onMessageListRequested: messageListRequested
};
const mapStateToProps = state => ({
  currentFolderId: state.messaging.messageNavigation.currentFolderId,
  currentMessageHeadersLength: state.messaging.messageList.messageHeaders.length,
  mailboxAddress: state.messaging.mailbox.address
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestNextPageButton);
