/**
 * Format an addressee according to RFC 5322 section 3.4: $DisplayName <$Address>
 *
 * @param  {Object} addressee - Addressee object
 * @param  {String} addressee.address - Addressee email address
 * @param  {String} addressee.displayName - Addressee display name
 * @return {String}
 */
function formatAddresseeFull({ address, displayName } = {}) {
  return address && displayName ? `${displayName} <${address}>` : address;
}

export default formatAddresseeFull;
