// Third Party
import { useCallback } from "react";
import debounce from "lodash/debounce";

const defaultOptions = { delay: 250,
  leading: false,
  trailing: true };

/**
 * React hook which performs a lodash `debounce`.
 * @param {Object} params - Hook parameters.
 * @param {Function} params.callback - Function which will be debounced.
 * @param {Number} params.delay - Timeout duration, in milliseconds.
 * @param {Array} params.dependencies - Dependencies which invalidate the debounce, e.g., a local useState value.
 * @param {Boolean} params.leading - Specify invoking on the leading edge of the timeout.
 * @param {Boolean} params.trailing - Specify invoking on the trailing edge of the timeout.
 * @return {Function}
 */
function useDebounce(params) {
  const options = { ...defaultOptions,
    ...params };
  const debouncedCallback = debounce(options.callback, options.delay, { leading: Boolean(options.leading),
    trailing: Boolean(options.trailing) });

  return useCallback(debouncedCallback, params.dependencies);
}

export default useDebounce;
