// Project
import {
  DRAFT_CC_ADDRESSEES_UPDATE_FAILURE,
  DRAFT_CC_ADDRESSEES_UPDATE_START,
  DRAFT_CC_ADDRESSEES_UPDATE_SUCCESS,
  DRAFT_CC_CHANGED,
  DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE,
  DRAFT_REMOVE_CC_ADDRESSEE
} from "../../constants/ActionTypes";
import * as applicationConfig from "../../config/application";
import containsDelimiter from "./util/containsDelimiter";
import convertAddressToAddressee from "./util/convertAddressToAddressee";
import initialState from "../../config/initialState";
import isApiStatusCodeError from "./util/isApiStatusCodeError";
import lastIndexOfRegex from "../../util/lastIndexOfRegex";
import parseDelimitedAddressString from "./util/parseDelimitedAddressString";
import uniqBy from "lodash/fp/uniqBy";

/**
 * Update the state with the changed cc addressees.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function ccAddressChanged(state, action) {
  const addressesToBeUpdated = parseDelimitedAddressString(action.workingCcAddress)
    .filter(Boolean)
    .map(address => convertAddressToAddressee(address));

  return {
    ...state,
    draftView: {
      ...state.draftView,
      cc: {
        ...state.draftView.cc,
        workingAddress: "",
        workingAddressees: uniqBy("address", state.draftView.cc.workingAddressees.concat(addressesToBeUpdated))
      }
    }
  };
}

/**
 * Add update error notification to application state.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Update failure action.
 * @return {Object} Updated application state.
 */
function ccUpdateFailure(state, action) {
  const invalidEmailCode = "400";
  const containsInvalid = isApiStatusCodeError(invalidEmailCode, action.error);

  return {
    ...state,
    draftView: {
      ...state.draftView,
      cc: {
        ...state.draftView.cc,
        containsInvalid: containsInvalid
      },
      draftAutosaving: false
    }
  };
}

/**
 * Update the draft autosave flag in application state.
 *
 * @param {String} state - Application stated.
 * @param {Object} action - Update start action.
 * @return {Object} Updated application state.
 */
function ccAddressUpdateStart(state, action) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: action.draftAutosaving }
  };
}

/**
 * Update application state with CC from API.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - CC updated action.
 * @return {Objet} Updated application state.
 */
function ccAddressUpdateSuccess(state, action) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      cc: {
        ...state.draftView.cc,
        cachedAddressees: action.ccAddressees,
        containsInvalid: false,
        workingAddressees: action.ccAddressees
      },
      draftAutosaving: false
    }
  };
}

/* eslint-disable max-lines-per-function */
/**
 * Update the state with the changed cc.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function ccWorkingAddressInProgressChange(state, action) {
  const delimiterRegex = new RegExp(applicationConfig.draftView.addressDelimiters, "u");
  const lastDelimiterIndex = lastIndexOfRegex(action.workingCcAddress, delimiterRegex);

  if (containsDelimiter(action.workingCcAddress)) {
    const addressesToBeUpdated = parseDelimitedAddressString(
      action.workingCcAddress.substring(0, lastDelimiterIndex)
    ).filter(Boolean)
      .map(address => convertAddressToAddressee(address));

    return {
      ...state,
      draftView: {
        ...state.draftView,
        cc: {
          ...state.draftView.cc,
          workingAddress: action.workingCcAddress.substring(lastDelimiterIndex + 1),
          workingAddressees: uniqBy("address", state.draftView.cc.workingAddressees.concat(addressesToBeUpdated))
        }
      }
    };
  }

  return {
    ...state,
    draftView: {
      ...state.draftView,
      cc: {
        ...state.draftView.cc,
        workingAddress: action.workingCcAddress.substring(lastDelimiterIndex + 1)
      }
    }
  };
}

/**
 * Update the state with the changed cc addressees.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function removeCcAddressee(state, action) {
  const checkAddressee = value => value.address !== action.ccAddresseeToRemove;

  return {
    ...state,
    draftView: {
      ...state.draftView,
      cc: {
        ...state.draftView.cc,
        workingAddressees: state.draftView.cc.workingAddressees.filter(checkAddressee)
      }
    }
  };
}

/**
 * Combine the draft message reducers.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action type.
 * @return {Object} Updated application state.
 */
export function draftCcReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_CC_ADDRESSEES_UPDATE_FAILURE: return ccUpdateFailure(state, action);

      case DRAFT_CC_ADDRESSEES_UPDATE_START: return ccAddressUpdateStart(state, action);

      case DRAFT_CC_ADDRESSEES_UPDATE_SUCCESS: return ccAddressUpdateSuccess(state, action);

      case DRAFT_CC_CHANGED: return ccAddressChanged(state, action);

      case DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE: return ccWorkingAddressInProgressChange(state, action);

      case DRAFT_REMOVE_CC_ADDRESSEE: return removeCcAddressee(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}
