// Third party
import { combineEpics } from "redux-observable";

// Project
import bodyChangedEpic from "./bodyChangedEpic";
import bodyUpdateStartEpic from "./bodyUpdateStartEpic";
import ccAddressUpdateStartEpic from "./ccAddressUpdateStartEpic";
import ccWorkingAddressInProgressChangedEpic from "./ccWorkingAddressInProgressChangedEpic";
import draftAttachmentAddFailureEpic from "./draftAttachmentAddFailureEpic";
import draftAttachmentAddSaveEpic from "./draftAttachmentAddSaveEpic";
import draftAttachmentAddSuccessEpic from "./draftAttachmentAddSuccessEpic";
import draftAttachmentFilesSelectedEpic from "./draftAttachmentFilesSelectedEpic";
import draftAttachmentRemoveFailureEpic from "./draftAttachmentRemoveFailureEpic";
import draftAttachmentRemoveRequestEpic from "./draftAttachmentRemoveRequestEpic";
import draftAttachmentRemoveStartEpic from "./draftAttachmentRemoveStartEpic";
import draftAttachmentRemoveSuccessEpic from "./draftAttachmentRemoveSuccessEpic";
import draftBodyUpdateFailureEpic from "./draftBodyUpdateFailureEpic";
import draftCcUpdateFailureEpic from "./draftCcUpdateFailureEpic";
import draftCreationFailedEpic from "./draftCreationFailedEpic";
import draftDiscardFailureEpic from "./draftDiscardFailureEpic";
import draftDiscardRequestedEpic from "./draftDiscardRequestedEpic";
import draftDiscardStartEpic from "./draftDiscardStartEpic";
import draftDiscardSuccessEpic from "./draftDiscardSuccessEpic";
import draftListLoadingFailureEpic from "./draftListLoadingFailureEpic";
import draftMoveOutboxFailureEpic from "./draftMoveOutboxFailureEpic";
import draftRetrievalFailureEpic from "./draftRetrievalFailureEpic";
import draftSubjectUpdateFailureEpic from "./draftSubjectUpdateFailureEpic";
import draftToUpdateFailureEpic from "./draftToUpdateFailureEpic";
import removeCcAddresseeEpic from "./removeCcAddresseeEpic";
import removeToAddresseeEpic from "./removeToAddresseeEpic";
import subjectChangedEpic from "./subjectChangedEpic";
import subjectUpdateStartEpic from "./subjectUpdateStartEpic";
import toAddressUpdateStartEpic from "./toAddressUpdateStartEpic";
import toWorkingAddressInProgressChangedEpic from "./toWorkingAddressInProgressChangedEpic";

export default combineEpics(
  bodyChangedEpic,
  bodyUpdateStartEpic,
  ccAddressUpdateStartEpic,
  ccWorkingAddressInProgressChangedEpic,
  draftAttachmentAddSaveEpic,
  draftAttachmentAddSuccessEpic,
  draftAttachmentFilesSelectedEpic,
  draftAttachmentRemoveSuccessEpic,
  draftAttachmentRemoveRequestEpic,
  draftAttachmentRemoveStartEpic,
  draftDiscardRequestedEpic,
  draftDiscardStartEpic,
  draftDiscardSuccessEpic,
  removeCcAddresseeEpic,
  removeToAddresseeEpic,
  subjectChangedEpic,
  subjectUpdateStartEpic,
  toAddressUpdateStartEpic,
  toWorkingAddressInProgressChangedEpic,
  draftMoveOutboxFailureEpic,
  draftCreationFailedEpic,
  draftRetrievalFailureEpic,
  draftListLoadingFailureEpic,
  draftAttachmentAddFailureEpic,
  draftAttachmentRemoveFailureEpic,
  draftBodyUpdateFailureEpic,
  draftCcUpdateFailureEpic,
  draftToUpdateFailureEpic,
  draftSubjectUpdateFailureEpic,
  draftDiscardFailureEpic
);
