// Third Party
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./DraftList.css";
import { draftDiscardStart } from "../../actions/draftMessage/draftDeleteActions";
import {
  draftListMultiSelectItemsAdd,
  draftListMultiSelectItemsRemove
} from "../../actions/draftListMultiSelectActions";
import { draftSelected } from "../../actions/draftMessageActions";
import DraftListHeader from "./DraftListHeader";
import formatEmailAddressDisplayAsArray from "../../util/formatEmailAddressDisplayAsArray";
import getFullDate from "../../util/getFullDate";
import getListItemDisplayDate from "../../util/getListItemDisplayDate";
import ListViewItem from "../formElements/ListViewItem";

const mapDispatchToProps = {
  onDraftSelected: draftSelected,
  onListViewItemsChecked: draftListMultiSelectItemsAdd,
  onListViewItemsUnchecked: draftListMultiSelectItemsRemove,
  onTrashClicked: draftDiscardStart
};

const mapStateToProps = state => ({
  currentDraftId: state.messaging.draftList.currentDraftId,
  drafts: state.messaging.draftList.drafts,
  mailbox: state.messaging.mailbox,
  selectedDrafts: state.messaging.draftList.selectedDrafts
});

/* eslint-disable max-lines-per-function */
/**
 * Creates a ListViewItem.
 *
 * @param {Object} props - Function properties.
 * @return {Object} ListViewItem.
 */
function ToDraftListItem(props) {
  const checkElement = value => value === props.draftIdentity;
  const checkboxIsSelected = props.selectedDrafts.find(checkElement);
  const checkboxTitle = checkboxIsSelected ? "Select message" : "Deselect message";
  const checkChangedHandler = () => (checkboxIsSelected ?
    props.onListViewItemsUnchecked(props.mailboxAddress, [props.draftIdentity]) :
    props.onListViewItemsChecked(props.mailboxAddress, [props.draftIdentity]));
  const clickHandler = () => props.onClick(props.mailboxAddress, props.draftIdentity);
  const draftDate = new Date(props.lastUpdatedDateTime);

  return <ListViewItem
    checkboxTitle={checkboxTitle}
    displayAddresses={formatEmailAddressDisplayAsArray(props.toAddresses)}
    dateTimeDisplay={getListItemDisplayDate(draftDate, props.draftComparisonDate)}
    dateTimeFull={getFullDate(draftDate)}
    hasAttachments={props.hasAttachments}
    itemSelected={props.isSelected}
    key={props.draftIdentity}
    onCheckChanged={checkChangedHandler}
    onClick={clickHandler}
    shortBody={props.shortBody}
    state="Seen"
    subject={props.subject}
  />;
}

ToDraftListItem.propTypes = {
  draftComparisonDate: PropTypes.object,
  draftIdentity: PropTypes.string,
  hasAttachments: PropTypes.bool,
  isSelected: PropTypes.bool,
  lastUpdatedDateTime: PropTypes.string,
  mailboxAddress: PropTypes.string,
  onClick: PropTypes.func,
  onListViewItemsChecked: PropTypes.func,
  onListViewItemsUnchecked: PropTypes.func,
  selectedDrafts: PropTypes.array,
  shortBody: PropTypes.string,
  state: PropTypes.string,
  subject: PropTypes.string,
  toAddresses: PropTypes.array
};

/**
 * Build the draft-list container.
 *
 * @constructor
 * @param {Object} props - Draft list properties.
 * @param {Array} props.drafts - Draft message list.
 */
function DraftList(props) {
  const draftComparisonDate = new Date(); // NOTE(jeremiah.sanders): This is equivalent to C# DateTime.UtcNow
  const selectedDrafts = props.selectedDrafts || [];
  const draftListItems = props.drafts
    .map(draft => (
      { ...draft,
        draftComparisonDate,
        hasAttachments: !isEmpty(draft.attachments),
        isSelected: props.currentDraftId === draft.draftIdentity,
        mailboxAddress: props.mailbox.address,
        onClick: props.onDraftSelected,
        onListViewItemsChecked: props.onListViewItemsChecked,
        onListViewItemsUnchecked: props.onListViewItemsUnchecked,
        selectedDrafts: selectedDrafts,
        state: "" }
    ))
    .map(ToDraftListItem);
  const trashClickHandler = () => props.onTrashClicked(props.mailbox.address, props.selectedDrafts);
  const multiItemActionsDisabled = !selectedDrafts.length;

  return (
    <div id="draft-list-wrapper">
      <DraftListHeader multiItemActionsDisabled={multiItemActionsDisabled} onClick={trashClickHandler} />
      <ListGroup id="draft-list">{draftListItems}</ListGroup>
    </div>
  );
}

DraftList.propTypes = {
  currentDraftId: PropTypes.string,
  drafts: PropTypes.array,
  mailbox: PropTypes.object,
  onDraftSelected: PropTypes.func,
  onListViewItemsChecked: PropTypes.func,
  onListViewItemsUnchecked: PropTypes.func,
  onTrashClicked: PropTypes.func,
  selectedDrafts: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftList);
