// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_CREATION_FAILED } from "../../constants/ActionTypes";
import { DRAFT_CREATED_ERROR_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftCreationFailedEpic = fromFailureAction(
  DRAFT_CREATION_FAILED,
  constant(DRAFT_CREATED_ERROR_DISPLAY)
);

export default draftCreationFailedEpic;
