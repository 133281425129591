// Third Party
import { Button } from "reactstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Build the folder-name save button.
 *
 * @param {Object} props - Button properties.
 * @constructor
 */
function SaveEditButton(props) {
  return (
    <Button
      aria-label="Save"
      color="success"
      disabled={props.disabled}
      id="save-edit-button"
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faSave} />
    </Button>
  );
}

SaveEditButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default SaveEditButton;
