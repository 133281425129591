/**
 * Attempts to retrieve the current navigations value.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @return {Array.<{
    excludeFromDelayedDismissal: (Boolean|undefined),
    message: String,
    severity: String,
    timestamp: Number,
    triggeringAction: ({type: String}|undefined)
  }> | null}
 */
export function tryGetNotifications(state) {
  return state?.messaging?.notifications;
}

/**
 * Determines if the notification is of the specified action type and timestamp.
 * @param {Object} notification - A notification.
 * @param {String?} triggeringActionType - A triggering action type.
 * @param {Number} timestamp - A timestamp.
 * @return {Boolean}
 */
export function isTargetNotification(notification, triggeringActionType, timestamp) {
  return timestamp === notification.timestamp &&
    triggeringActionType === notification.triggeringAction?.type;
}

/**
 * Attempts to retrieve a specific notification.
 * @param {Object} state - Application state, as maintained in Redux store.
 * @param {String?} triggeringActionType - Triggering action type.
 * @param {Number} timestamp - Notification timestamp.
 * @return {Array.<{
    excludeFromDelayedDismissal: (Boolean|undefined),
    message: String,
    severity: String,
    timestamp: Number,
    triggeringAction: ({type: String}|undefined)
  }> | null} - All matching notifications.
 */
export function tryGetNotification(state, triggeringActionType, timestamp) {
  return tryGetNotifications(state)
    .filter(notification => isTargetNotification(notification, triggeringActionType, timestamp));
}
