// Third Party
import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import CurrentFolderButtonGroup from "./CurrentFolderButtonGroup";
import TextEditor from "../../formElements/TextEditor";
import "./FolderListItem.css";

// Classes applied to NotEditing folder list items irrespective of .isSelected.
const folderListItemClasses = "d-flex selectable";

/* eslint-disable max-lines-per-function */
const NotEditing = props => {
  const folderItemId = `folder-${props.folderId}`;
  const nameDisplayId = `${folderItemId}-name`;
  const editClickHandler = clickSyntheticEvent => {
    // NOTE(jeremiah.sanders): This .stopPropagation() execution is required to prevent the enclosing
    //   ListGroupItem's .onClick() being triggered (which would "select" the folder, canceling editing).
    clickSyntheticEvent.stopPropagation();
    props.onEditClick();
  };
  const buttonGroup = props.showButtons ?
    <CurrentFolderButtonGroup
      displayName={props.displayName}
      folderId={props.folderId}
      onEditClick={editClickHandler}
    /> :
    null;

  return (
    <ListGroupItem
      action
      active={props.isSelected}
      className={folderListItemClasses}
      id={folderItemId}
      onClick={props.onFolderClick}
    >
      <div
        aria-label={props.displayName}
        id={nameDisplayId}
        className="folder-text align-self-center" >
        { props.displayName }
      </div>
      { buttonGroup }
    </ListGroupItem>
  );
};
/* eslint-enable max-lines-per-function */

NotEditing.propTypes = {
  displayName: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onEditClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  showButtons: PropTypes.bool
};

const Editing = props => <ListGroupItem active={props.isSelected}>
  <TextEditor
    onCancelClick={props.onEditFolderAbortClick}
    onInputChange={props.onCurrentFolderNameChange}
    onSaveClick={props.onEditFolderSaveClick}
    workingValue={props.currentFolderWorkingDisplayName} />
</ListGroupItem>;

Editing.propTypes = {
  currentFolderWorkingDisplayName: PropTypes.string,
  isSelected: PropTypes.bool,
  onCurrentFolderNameChange: PropTypes.func,
  onEditFolderAbortClick: PropTypes.func,
  onEditFolderSaveClick: PropTypes.func
};

/* eslint-disable max-lines-per-function */
/**
 *  Constructor for a folder-list item.
 *
 * @constructor
 * @param {Object} props - Folder list item properties.
 *
 */
function FolderListItem(props) {
  const isEditing = props.showFolderNameEditor && props.isSelected;

  return isEditing ?
    <Editing
      currentFolderWorkingDisplayName={props.currentFolderWorkingDisplayName}
      isSelected={props.isSelected}
      onCurrentFolderNameChange={props.onCurrentFolderNameChange}
      onEditFolderAbortClick={props.onEditFolderAbortClick}
      onEditFolderSaveClick={props.onEditFolderSaveClick}
    /> :
    <NotEditing
      displayName={props.displayName}
      folderId={props.folderId}
      isSelected={props.isSelected}
      onEditClick={props.onEditClick}
      onFolderClick={props.onFolderClick}
      showButtons={props.showButtons}
    />;
}

FolderListItem.propTypes = {
  currentFolderWorkingDisplayName: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onCurrentFolderNameChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onEditFolderAbortClick: PropTypes.func,
  onEditFolderSaveClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  showButtons: PropTypes.bool,
  showFolderNameEditor: PropTypes.bool
};

export default FolderListItem;
