// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { REQUEST_NEW_BLANK_DRAFT } from "../constants/ActionTypes.js";
import { draftCreated, draftCreationFailed } from "../actions/draftMessageActions";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const draftRequestNewEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(REQUEST_NEW_BLANK_DRAFT),
  mergeMap(action => from(messagingClient.requestBlankMailboxDraft(action.mailboxIdentity))
    .pipe(
      map(response => draftCreated(action.mailboxIdentity, response)),
      catchError(error => of(draftCreationFailed(action.mailboxIdentity, error)))
    )
  )
);

export default draftRequestNewEpic;
