// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { SET_MESSAGE_STATE } from "../constants/ActionTypes";
import { messageStateChanged, setMessageStateErred } from "../actions/messageViewActions";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const setMessageStateEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(SET_MESSAGE_STATE),
  mergeMap(action => from(messagingClient.putMailboxMessageState(
    action.mailboxAddress,
    action.messageIdentity,
    action.state))
    .pipe(
      map(response => messageStateChanged(action.mailboxAddress, action.messageIdentity, response.state)),
      catchError(error => of(setMessageStateErred(action.mailboxAddress, action.messageIdentity, error)))
    ))
);

export default setMessageStateEpic;
