// Third Party
import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MAILBOX_FOLDER_SELECTED } from "../constants/ActionTypes";
import { draftListMultiSelectItemsClear } from "../actions/draftListMultiSelectActions";
import { editFolderNameAbort } from "../actions/messageNavigation/folderEditActions";
import { messageListMultiSelectItemsClear } from "../actions/messageListMultiSelectActions";
import { messageListRequested } from "../actions/messageListActions";

const messageFolderSelectedEpic = action$ => action$.pipe(
  ofType(MAILBOX_FOLDER_SELECTED),
  mergeMap(action => [
    editFolderNameAbort(action.mailboxAddress, action.folderId),
    draftListMultiSelectItemsClear(action.mailboxAddress),
    messageListMultiSelectItemsClear(action.mailboxAddress),
    messageListRequested(action.mailboxAddress, action.folderId)
  ])
);

export default messageFolderSelectedEpic;
