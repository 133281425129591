// Third Party
import { Button } from "reactstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import Icon from "../../Icon";

/**
 * Build the folder-edit button group.
 *
 * @param {Object} props - CurrentFolderButtonGroup properties.
 * @param {String} props.displayName - Folder display name.
 * @param {String} props.folderId - Folder identity.
 * @param {Function} props.onEditClick - Click event for edit button.
 * @constructor
 */
function CurrentFolderButtonGroup({ displayName, folderId, onEditClick }) {
  return (
    <Button
      aria-label={`Edit ${displayName}`}
      color="light"
      id={`edit-${folderId}`}
      onClick={onEditClick}
      size="sm"
    >
      <Icon className="email-icon">
        <FontAwesomeIcon icon={faEdit} />
      </Icon>
    </Button>
  );
}

CurrentFolderButtonGroup.propTypes = {
  displayName: PropTypes.string,
  folderId: PropTypes.string,
  onEditClick: PropTypes.func
};

export default CurrentFolderButtonGroup;
