// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_DISCARD_SUCCESS } from "../../../constants/ActionTypes";
import { DRAFT_LIST } from "../../../constants/ListViewPanes";
import { draftListLoadingStart } from "../../../actions/draftsActions";

const draftDiscardSuccessNoClearSelectedDraftsEpic = (action$, state$) => action$.pipe(
  ofType(DRAFT_DISCARD_SUCCESS),
  filter(() => state$.value.messaging.listViewPane === DRAFT_LIST),
  map(action => draftListLoadingStart(action.mailboxAddress))
);

export default draftDiscardSuccessNoClearSelectedDraftsEpic;
