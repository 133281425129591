// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_CREATED } from "../constants/ActionTypes.js";
import { draftSelected } from "../actions/draftMessageActions";

const draftCreatedEpic = action$ => action$.pipe(
  ofType(DRAFT_CREATED),
  map(action => draftSelected(action.mailboxIdentity, action.draftIdentity))
);

export default draftCreatedEpic;
