// Third Party
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle } from "reactstrap";
import React from "react";

const cardStyle = { marginTop: "1em" };

/**
 * Creates a no mailboxes component.
 * @constructor
 * @return {Object} JSX component.
 */
function NoMailboxes() {
  return (
    <Card style={cardStyle}>
      <CardHeader>
        Assigned mailboxes
      </CardHeader>
      <CardBody aria-label="No mailboxes">
        <CardTitle tag="h5">You do not have any assigned mailboxes.</CardTitle>
        <CardSubtitle>Please contact your administrator to obtain access.</CardSubtitle>
      </CardBody>
    </Card>
  );
}

export default NoMailboxes;
