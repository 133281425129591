// Third Party
import { Card, CardBody, CardImg } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./FullPageCareAlignCard.css";
import FullPageContent from "./FullPageContent";

const getImageStyle = props => (props?.imageSize === "lg" ? {} : { height: "5em" });

/**
 * A full-page CareAlign-branded Bootstrap Card.
 * Intended for top-level pages.
 * @param {Object} props - Rendering properties.
 * @param {Object} props.children - React element children. Rendered within Bootstrap CardBody.
 * @param {String} props.imageSize - Optional CareAlign logo image size. Uses Bootstrap size strings. Only 'lg'
 *   changes size.
 * @return {Object}
 */
function FullPageCareAlignCard(props) {
  const imageStyle = getImageStyle(props);

  return <FullPageContent>
    <Card className="text-center">
      <CardImg title="CareAlign logo" src="/carealign.svg" style={imageStyle} />
      <CardBody>
        {props.children}
      </CardBody>
    </Card>
  </FullPageContent>;
}

FullPageCareAlignCard.propTypes = { children: PropTypes.node,
  imageSize: PropTypes.string };

export default FullPageCareAlignCard;
