// Project
import { DEFAULT_VIEW } from "../constants/DetailViewPanes";
import {
  DRAFT_MOVE_OUTBOX_SUCCESS
} from "../constants/ActionTypes";
import initialState from "../config/initialState";

/**
 * Set the detailViewPane to the default view.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated state.
*/
function draftMoveOutboxSuccess(state) {
  return {
    ...state,
    detailViewPane: DEFAULT_VIEW,
    draftList: {
      ...state.draftList,
      currentDraftId: ""
    }
  };
}

/**
 * Reducers related to moving the draft to the outbox.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Action Object
 * @return {Object} Application state.
 */
function draftMoveToOutboxReducers(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_MOVE_OUTBOX_SUCCESS: return draftMoveOutboxSuccess(state);

      default: return state;
    }
  } else {
    return state;
  }
}

export default draftMoveToOutboxReducers;
