// Third party
import isFunction from "lodash/fp/isFunction";
import isString from "lodash/fp/isString";

/**
 * Executes the getFn with mailboxAddress and draftIdentity, if they are provided.
 *
 * @param {Function} putFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} body - Draft body to update.
 * @return {Promise.<DraftBody>}
 */
function putMailboxDraftBody(putFn, mailboxAddress, draftIdentity, body) {
  return mailboxAddress && draftIdentity && isString(body) ?
    putFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/body`, { body: body })
      .then(() => body) :
    Promise.reject(new Error("Mailbox address, draft identity, and body are required"));
}

/**
 * Creates a function that will PUT (update) a draft message body.
 *
 * @param {Function} putFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which puts a draft message body when executed with a mailbox address and draft identity.
 */
function createPutDraftBody(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, draftIdentity, body) => (hasPutFn ?
    putMailboxDraftBody(putFn, mailboxAddress, draftIdentity, body) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutDraftBody;
