// Third Party
import { CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";

// Project
import { unauthenticated } from "../constants/PageRoutes";
import FullPageCareAlignCard from "../components/FullPageCareAlignCard";

// NOTE(jeremiah.sanders): Since we're rendering a react-router-dom Link, rather than a reactstrap Button, we must
//   define the Bootstrap classes ourselves.
const largePrimaryBootstrapButtonClasses = "btn btn-primary btn-lg";

/**
 * A 404-like page.
 * @return {Object}
 */
function NotFound() {
  return <FullPageCareAlignCard>
    <CardTitle tag="h2">The requested page was not found.</CardTitle>
    <Link aria-label="Return home link" to={unauthenticated} className={largePrimaryBootstrapButtonClasses}>Return to CareAlign Messaging home</Link>
  </FullPageCareAlignCard>;
}

export default NotFound;
