// Third Party
import { isNumber } from "lodash";

// Project
import { NOTIFICATION_ADD, NOTIFICATION_DISMISS, NOTIFICATION_DISMISS_DELAYED, NOTIFICATION_DISMISS_DELAYED_ALLOW, NOTIFICATION_DISMISS_DELAYED_PREVENT } from "../constants/ActionTypes";
import { ERROR } from "../constants/Severity";

/**
 *  Add a notification.
 *
 * @param {String} message - User-facing notification message.
 * @param {String} severity - Severity of the notification.
 * @param {?Object} triggeringAction - Action which triggered the notification.
 * @param {?Number} [timestamp=Date.now()] - Notification timestamp.
 * @return {Object} Action object.
 */
export function notificationAdd(message, severity, triggeringAction, timestamp) {
  return {
    message,
    severity,
    timestamp: isNumber(timestamp) ? timestamp : Date.now(),
    triggeringAction,
    type: NOTIFICATION_ADD
  };
}

/**
 *  Add an ERROR severity notification.
 *
 * @param {String} message - User-facing notification message.
 * @param {?Object} triggeringAction - Action which triggered the notification.
 * @param {?Number} [timestamp=Date.now()] - Notification timestamp.
 * @return {Object} Action object.
 */
export function notificationAddError(message, triggeringAction, timestamp) {
  return notificationAdd(message, ERROR, triggeringAction, timestamp);
}

/**
 *  Signal the dismissal of a notification.
 *
 * @param {String} notificationActionType - Type of the action that triggered the notification.
 * @param {Number} notificationTimestamp - Notification timestamp.
 * @return {Object} Action object.
 */
export function notificationDismiss(notificationActionType, notificationTimestamp) {
  return {
    notificationActionType: notificationActionType,
    notificationTimestamp: notificationTimestamp,
    type: NOTIFICATION_DISMISS
  };
}

/**
 * Creates an action that requests a specific notification be dismissed after a delay.
 * @param {String} triggeringActionType - Triggering action's type string.
 * @param {Number} timestamp - Notification timestamp.
 * @return {{timestamp: Number, triggeringActionType: String, type: String}}
 */
export function notificationDismissDelayed(triggeringActionType, timestamp) {
  return {
    timestamp,
    triggeringActionType,
    type: NOTIFICATION_DISMISS_DELAYED
  };
}

/**
 * Creates an action that requests a specific notification be included in automatic delayed dismissal.
 * @param {String} triggeringActionType - Triggering action's type string.
 * @param {Number} timestamp - Notification timestamp.
 * @return {{timestamp: Number, triggeringActionType: String, type: String}}
 */
export function notificationDismissDelayedAllow(triggeringActionType, timestamp) {
  return {
    timestamp,
    triggeringActionType,
    type: NOTIFICATION_DISMISS_DELAYED_ALLOW
  };
}

/**
 * Creates an action that requests a specific notification be excluded from automatic delayed dismissal.
 * @param {String} triggeringActionType - Triggering action's type string.
 * @param {Number} timestamp - Notification timestamp.
 * @return {{timestamp: Number, triggeringActionType: String, type: String}}
 */
export function notificationDismissDelayedPrevent(triggeringActionType, timestamp) {
  return {
    timestamp,
    triggeringActionType,
    type: NOTIFICATION_DISMISS_DELAYED_PREVENT
  };
}
