// Third party
import pipe from "lodash/fp/pipe";

// Project
import normalizeMailbox from "./normalizeMailbox";

/**
 * Creates a mailbox folder query URL.
 * @param {Object} queryParameters - Mailbox folder query parameter object.
 * @return {String}
 * @private
 */
function queryParametersToUrl(queryParameters) {
  const mailbox = normalizeMailbox(queryParameters.mailbox);

  return `api/mailbox/${mailbox}/folders`;
}

const defaultQueryParameters = {
  mailbox: null
};

/**
 * Converts a mailbox address or query parameter object to a query parameter object.
 * @param {Object} addressOrQueryParameters - A String or folder list query object.
 * @return {Object} - Query parameter object.
 */
function normalizeQueryParameter(addressOrQueryParameters) {
  return typeof addressOrQueryParameters === "string" ?
    {
      ...defaultQueryParameters,
      mailbox: addressOrQueryParameters
    } :
    addressOrQueryParameters;
}

/**
 * Creates a function which will fetch the mailbox folders.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets the mailbox folders when executed.
 */
function getMailboxFolders(getFn) {
  return mailboxAddress => pipe(normalizeQueryParameter, queryParametersToUrl, getFn)(mailboxAddress)
    .then(response => response.json());
}

export default getMailboxFolders;
