// Third Party
import PropTypes from "prop-types";
import React from "react";

// Project
import MoveFolderDropdown from "./MoveFolderDropdown";
import TrashButton from "../formElements/TrashButton";
import "./MessageListHeader.css";

/**
 * Message-list header container.
 *
 * @param {Object} props - Header properties.
 * @param {String} props.folderId - Current mailbox folderId.
 * @param {String} props.folderName - Current mailbox folder name.
 * @param {Array.<{displayName: String, folderId: String}>} props.folders - Mailbox folders listed in the move to
 *   folder action element.
 * @param {Boolean} props.multiItemActionsDisabled - Flag indicating whether the multi-item action elements should be
 *   disabled.
 * @param {Function} props.onMoveFolderClick - Click handler for move folder button.
 * @param {Function} props.onTrashClick - Click handler for trash button.
 * @constructor
 */
function MessageListHeader(props) {
  return (
    <header id="message-list-header">
      <h5 className="folder-name text-truncate align-self-center">{props.folderName}</h5>
      <div className="multi-message-command-buttons">
        <MoveFolderDropdown
          disabled={props.multiItemActionsDisabled}
          folders={props.folders.filter(folder => folder.folderId !== props.folderId)}
          onMoveFolderClick={props.onMoveFolderClick}
        />
        <TrashButton
          disabled={props.multiItemActionsDisabled}
          onClick={props.onTrashClick}
          title="Delete selected message(s)"
        />
      </div>
    </header>
  );
}

MessageListHeader.propTypes = {
  folderId: PropTypes.string.isRequired,
  folderName: PropTypes.string.isRequired,
  folders: PropTypes.array,
  multiItemActionsDisabled: PropTypes.bool,
  onMoveFolderClick: PropTypes.func,
  onTrashClick: PropTypes.func
};

export default MessageListHeader;
