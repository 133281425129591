// Project
import * as types from "../constants/ActionTypes.js";

/**
 *  On click action for toAddressee element
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} toAddressee  - Addressee to remove
 * @return {Object} Action object.
 */
export function removeToAddressee(mailboxAddress, draftIdentity, toAddressee) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    toAddresseeToRemove: toAddressee,
    type: types.DRAFT_REMOVE_TO_ADDRESSEE
  };
}

/**
 * On blur action for the to addresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingToAddress - Draft to text.
 * @return {Object} Action object.
*/
export function toAddressChanged(mailboxAddress, draftIdentity, workingToAddress) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_TO_CHANGED,
    workingToAddress: workingToAddress
  };
}

/**
 * On change action for the to addresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {String} workingToAddress - Draft to text.
 * @return {Object} Action object.
 */
export function toWorkingAddressInProgressChange(mailboxAddress, draftIdentity, workingToAddress) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_TO_WORKING_ADDRESS_IN_PROGRESS_CHANGE,
    workingToAddress: workingToAddress
  };
}

/**
 *  Action for signaling the start of the toAddress update process.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft Identity.
 * @param {Boolean} isAutosaving - Flag denoting an automatic versus a user-generated save.
 * @return {Object} Action object.
 */
export function toAddressUpdateStart(mailboxAddress, draftIdentity, isAutosaving) {
  /* eslint-disable no-unneeded-ternary */
  return {
    draftAutosaving: Boolean(isAutosaving),
    draftIdentity: draftIdentity,

    mailboxAddress: mailboxAddress,
    type: types.DRAFT_TO_ADDRESSEES_UPDATE_START
  };
}

/**
 * Action for signaling a failure to update the toAddresses.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} error - Error object
 * @return {Object} Action object.
 */
export function toAddressUpdateFailure(mailboxAddress, draftIdentity, error) {
  return {
    draftIdentity: draftIdentity,
    error: error,
    mailboxAddress: mailboxAddress,
    type: types.DRAFT_TO_ADDRESSEES_UPDATE_FAILURE
  };
}

/**
 * Action for signaling a successful toAddress update.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {String} cachedToAddressees - To text from the API.
 * @return {Object} Action object.
 */
export function toAddressUpdateSuccess(mailboxAddress, draftIdentity, cachedToAddressees) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    toAddressees: cachedToAddressees,
    type: types.DRAFT_TO_ADDRESSEES_UPDATE_SUCCESS
  };
}

