/**
 * Requires that the response's .ok property is truthy.
 * Response.ok is a boolean property which Fetch sets to 'true' when the status is 200-299
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
 * @param {Response} response - A Response object.
 * @return {Promise.<Response>}
 */
function requireOk(response) {
  // eslint-disable-next-line implicit-arrow-linebreak
  return response.ok ?
    Promise.resolve(response) :
    Promise.reject(new Error(`Received: ${response.status} ${response.statusText}`));
}

/**
 * Executes a Fetch request and requires a successful response.
 *
 * @param {Function} fetchFn - A function matching the fetch API (Request -> Promise<Response>).
 * @param {Request} request - A Request object.
 * @return {Promise.<Response>} A Response promise.
 */
function apiRequest(fetchFn, request) {
  return fetchFn(request).then(requireOk);
}

export default apiRequest;
