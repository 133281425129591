// Third Party
import { Input } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";

/**
 * Text input for the new-folder display name.
 *
 * @constructor
 * @param {Object} props - Input properties.
 * @param {Function} props.onChange - Change handler for the text input.
 * @param {String} props.value - New folder name.
 */
function NewFolderTextInput(props) {
  const folderNameMaxLength = 100;
  /* eslint-disable arrow-body-style */
  const handleInputChange = event => {
    return props.onChange(event.target.value);
  };

  return (
    <Input
      aria-label="New folder display name"
      type="text"
      maxLength={folderNameMaxLength}
      name="new_folder_display_name"
      onChange={handleInputChange}
      placeholder="New Folder"
      value={ props.value ?? "" }
    />
  );
}

NewFolderTextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default NewFolderTextInput;
