// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MAILBOX_EXITED } from "../../constants/ActionTypes.js";
import { userMailboxesRequested } from "../../actions/userActions.js";

const mailboxExitedEpic = action$ => action$.pipe(
  ofType(MAILBOX_EXITED),
  map(() => userMailboxesRequested())
);

export default mailboxExitedEpic;
