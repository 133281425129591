// Third Party
import { catchError, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";


// Project
import { DRAFT_ATTACHMENT_REMOVE_START } from "../../constants/ActionTypes";
import {
  draftAttachmentRemoveFailure,
  draftAttachmentRemoveSuccess
} from "../../actions/draftAttachmentsActions";

/**
 * Create an Observable which responds to DRAFT_ATTACHMENT_REMOVE_START, removing draft attachments from
 * Messaging Service.
 * @param {Observable<Action>} action$ - An action stream.
 * @param {Observable<Object>} state$ - A state stream.
 * @param {Object} dependencies - Epic dependencies.
 * @return {Observable<Action>}
 */
function draftAttachmentRemoveStartEpic(action$, state$, { messagingClient }) {
  return action$.pipe(
    ofType(DRAFT_ATTACHMENT_REMOVE_START),
    mergeMap(action => from(messagingClient.deleteDraftAttachment(
      action.mailboxAddress,
      action.draftIdentity,
      action.attachmentIdentifier))
      .pipe(
        mergeMap(() => of(draftAttachmentRemoveSuccess(action.mailboxAddress, action.draftIdentity,
          action.attachmentIdentifier))),
        catchError(error => of(draftAttachmentRemoveFailure(action.mailboxAddress, action.draftIdentity,
          action.attachmentIdentifier, error)))
      )
    )
  );
}

export default draftAttachmentRemoveStartEpic;
