// Project
import { MAILBOX_CHANGED, MAILBOX_EXITED } from "../constants/ActionTypes.js";
import baseMailbox from "../config/baseMailbox";
import initialState from "../config/initialState";

/**
 * Handles mailbox changed actions.
 *
 * @param {Object} state - An application state.
 * @param {Object} action - An action.
 * @return {Object} An application state.
 */
function handleMailboxChanged(state, action) {
  return {
    ...state,
    mailbox: {
      ...baseMailbox,
      ...state.mailbox,
      address: action.mailboxAddress
    }
  };
}

/**
 * Handles mailbox exited actions.
 *
 * @param {Object} state - An application state.
 * @return {Object} An application state.
 */
function handleMailboxExited(state) {
  return {
    ...state,
    mailbox: null
  };
}

/**
 * Handles all mailbox-related actions.
 *
 * @param {Object} state - An application state.
 * @param {Object} action - An action.
 * @return {Object} An application state.
 */
function mailboxReducer(state = initialState, action) {
  if (state) {
    if (action && action.type) {
      switch (action.type) {
        case MAILBOX_CHANGED:
          return handleMailboxChanged(state, action);

        case MAILBOX_EXITED:
          return handleMailboxExited(state);

        default:
          return state;
      }
    }

    return state;
  }

  return initialState;
}

export default mailboxReducer;
