// Project
import * as types from "../constants/ActionTypes";

/**
 * Creates an action representing failure to download a message attachment.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @param {Error} error - An error causing failure.
 * @return {Object}
 */
export function messageAttachmentDownloadFailed(mailboxAddress, messageIdentity, attachmentIdentifier, error) {
  return {
    attachmentIdentifier,
    error,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_DOWNLOAD_FAILED
  };
}

/**
 * Creates an action representing a request to download a message attachment.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentDownloadRequest(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_DOWNLOAD_REQUEST
  };
}

/**
 * Creates an action representing the beginning of a message attachment download.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentDownloadStart(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_DOWNLOAD_START
  };
}

/**
 * Creates an action representing the successful completion of a message attachment download.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentDownloadSucceeded(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_DOWNLOAD_SUCCEEDED
  };
}

/**
 * Creates an action representing failure to open a message attachment.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @param {Error} error - An error causing failure.
 * @return {Object}
 */
export function messageAttachmentOpenFailed(mailboxAddress, messageIdentity, attachmentIdentifier, error) {
  return {
    attachmentIdentifier,
    error,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_OPEN_FAILED
  };
}

/**
 * Creates an action representing a request to open a message attachment.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentOpenRequest(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_OPEN_REQUEST
  };
}

/**
 * Creates an action representing the beginning of a message attachment open.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentOpenStart(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_OPEN_START
  };
}

/**
 * Creates an action representing the successful completion of a message attachment open.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} attachmentIdentifier - A message attachment identifier.
 * @return {Object}
 */
export function messageAttachmentOpenSucceeded(mailboxAddress, messageIdentity, attachmentIdentifier) {
  return {
    attachmentIdentifier,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_ATTACHMENT_OPEN_SUCCEEDED
  };
}

/**
 * Creates a message loading end action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @return {Object} Action.
 */
export function messageLoadingEnd(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_LOADING_END
  };
}

/**
 * Creates a message loading erred action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {Error} error - An error.
 * @return {Object} Action.
 */
export function messageLoadingErred(mailboxAddress, messageIdentity, error) {
  return {
    error,
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_LOADING_ERRED
  };
}

/**
 * Creates a message loading start action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @return {Object} Action.
 */
export function messageLoadingStart(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.MESSAGE_LOADING_START
  };
}

/**
 * Creates a message retrieved action.
 *
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {Object} message - Mailbox message.
 * @return {Object} Action
 */
export function messageRetrieved(mailboxAddress, messageIdentity, message) {
  return {
    mailboxAddress,
    message: message,
    messageIdentity,
    type: types.MESSAGE_RETRIEVED
  };
}

/**
 * Creates a Message State Changed action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {String} state - A message state.
 * @return {Object} Action.
 */
export function messageStateChanged(mailboxAddress, messageIdentity, state) {
  return {
    mailboxAddress,
    messageIdentity,
    state,
    type: types.MESSAGE_STATE_CHANGED
  };
}

/**
 * Creates a Request Forward Derived Draft action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @return {Object} Action.
 */
export function requestForwardDerivedDraft(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.REQUEST_FORWARD_DERIVED_DRAFT
  };
}

/**
 * Creates a Request Reply All Derived Draft action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @return {Object} Action.
 */
export function requestReplyAllDerivedDraft(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.REQUEST_REPLY_ALL_DERIVED_DRAFT
  };
}

/**
 * Creates a Request Reply Derived Draft action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @return {Object} Action.
 */
export function requestReplyDerivedDraft(mailboxAddress, messageIdentity) {
  return {
    mailboxAddress,
    messageIdentity,
    type: types.REQUEST_REPLY_DERIVED_DRAFT
  };
}

/**
 * Creates a Set Message State action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {Boolean} isSeen - A message is seen indicator.
 * @return {Object} Action.
 */
export function setMessageState(mailboxAddress, messageIdentity, isSeen) {
  return {
    mailboxAddress,
    messageIdentity,
    state: isSeen,
    type: types.SET_MESSAGE_STATE
  };
}

/**
 * Creates a Set Message State Erred action.
 * @param {String} mailboxAddress - A mailbox address.
 * @param {String} messageIdentity - A message identity.
 * @param {Error} error - An error.
 * @return {Object} Action.
 */
export function setMessageStateErred(mailboxAddress, messageIdentity, error) {
  return {
    error,
    mailboxAddress,
    messageIdentity,
    type: types.SET_MESSAGE_STATE_ERRED
  };
}
