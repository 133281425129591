// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { mailboxChanged } from "../../actions/mailboxActions.js";
import { USER_MAILBOXES_RETRIEVED } from "../../constants/ActionTypes.js";

/**
 * Checks to see if the user profile has exactly one mailbox assignment.
 * @param {Array} mailboxes - Some mailboxes.
 * @return {Boolean}
 * @private
 */
function hasOnlyOneMailbox(mailboxes) {
  return mailboxes && mailboxes.length === 1;
}

/**
 * Selects the first mailbox from the mailboxes.
 * @param {Object} mailboxes - Some mailboxes.
 * @return {String}
 * @private
 */
function selectFirstMailboxAddress(mailboxes) {
  return mailboxes[0].address;
}

const userMailboxesRetrievedEpic = action$ => action$.pipe(
  ofType(USER_MAILBOXES_RETRIEVED),
  filter(action => hasOnlyOneMailbox(action.mailboxes)),
  map(action => mailboxChanged(selectFirstMailboxAddress(action.mailboxes)))
);

export default userMailboxesRetrievedEpic;
