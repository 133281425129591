// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the getFn with mailboxAddress, messageIdentity, and attachmentIdentifier, if they are provided.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} messageIdentity - Message identity containing the attachment.
 * @param {String} attachmentIdentifier - Attachment to retrieve.
 * @return {Promise.<Response>} Promise which resolves to a Response.
 */
function getMailboxMessageAttachment(getFn, mailboxAddress, messageIdentity, attachmentIdentifier) {
  // NOTE (jeremiah.sanders): For more details about reading a Fetch request's Response as a Blob and converting it
  // to an ObjectURL:
  //   See: https://developer.mozilla.org/en-US/docs/Web/API/Body/blob#example
  return mailboxAddress && messageIdentity && attachmentIdentifier ?
    getFn(`api/mailbox/${mailboxAddress}/message/${messageIdentity}/attachments/${attachmentIdentifier}`) :
    Promise.reject(new Error("Mailbox address, message identity, and attachment identifier are required"));
}

/**
 * Creates a function which will get a mailbox message attachment.
 *
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which gets a mailbox message attachment when executed with a mailbox address, message
 * identity, and attachment identifier.
 */
function createGetMailboxMessageAttachment(getFn) {
  const hasGetFn = getFn && isFunction(getFn);

  return (mailboxAddress, messageIdentity, attachmentIdentifier) => (hasGetFn ?
    getMailboxMessageAttachment(getFn, mailboxAddress, messageIdentity, attachmentIdentifier) :
    Promise.reject(new Error("HTTP GET function is required but was not provided")));
}

export default createGetMailboxMessageAttachment;
