// Project
import {
  CREATE_MESSAGES_MOVE_FOLDER_REQUEST,
  MESSAGES_MOVE_FOLDER_FAILURE,
  MESSAGES_MOVE_FOLDER_REQUEST,
  MESSAGES_MOVE_FOLDER_SUCCESS
} from "../../constants/ActionTypes";

/**
 *  Signals the intent to move messages between folders.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} folderId - Folder identity.
 * @return {Object} Action object.
 */
export function createMessagesMoveFolderRequest(mailboxAddress, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    type: CREATE_MESSAGES_MOVE_FOLDER_REQUEST
  };
}

/**
 * Signals a failure to move messages between folders.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messagesIdentities - Message identities to be moved.
 * @param {String} folderId - Folder identity.
 * @param {Object} error - Error object.
 * @return {Object} Action Object
 */
export function messagesMoveFolderFailure(mailboxAddress, messagesIdentities, folderId, error) {
  return {
    error: error,
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    messageIdentities: messagesIdentities,
    type: MESSAGES_MOVE_FOLDER_FAILURE
  };
}

/**
 * Signals the intent to move messages to a new folder.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Message identities to be moved.
 * @param {String} folderId - Target-folder identity.
 * @return {Object} Action object.
 *
 */
export function messagesMoveFolderRequest(mailboxAddress, messageIdentities, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGES_MOVE_FOLDER_REQUEST
  };
}

/**
 * Signals the successful moving of messages to a new folder.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Message identities to be moved.
 * @param {String} folderId - Target-folder identity.
 * @return {Object} Action object.
 *
 */
export function messagesMoveFolderSuccess(mailboxAddress, messageIdentities, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGES_MOVE_FOLDER_SUCCESS
  };
}
