// Third Party
import PropTypes from "prop-types";
import React from "react";

// Project
import "./FullPageContent.css";

/**
 * A container component which displays centered, uncluttered content.
 * Intended for top-level pages.
 * @param {Object} props - Rendering properties.
 * @param {Object} props.children - React element children.
 * @return {Object}
 */
function FullPageContent(props) {
  return <div className="full-page-content-wrapper">
    {props.children}
  </div>;
}

FullPageContent.propTypes = { children: PropTypes.node };

export default FullPageContent;
