// Third party
import isFunction from "lodash/fp/isFunction";
import isArray from "lodash/fp/isArray";

/**
 * Executes the putFn with the CC addressees API URL and a body object containing the CC addressees.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft to modify.
 * @param {String} ccAddressees - New CC addressees.
 * @return {Promise.<Object>} Promise of object with draftIdentity, mailboxAddress, and ccAddresses.
 */
function putMailboxDraftCcAddressees(putFn, mailboxAddress, draftIdentity, ccAddressees) {
  return mailboxAddress && draftIdentity && isArray(ccAddressees) ?
    putFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/cc`, { cc: ccAddressees })
      // Addressee property name chosen to match property returned by GET /api/mailbox/{mailbox}/draft/{draftIdentity}
      .then(() => ({ ccAddresses: ccAddressees,
        draftIdentity,
        mailboxAddress }
      )) :
    Promise.reject(new Error("Mailbox address, draft identity, and CC addressees are required"));
}

/**
 * Creates a function which will update a mailbox draft CC addressees.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which updates a mailbox draft CC addressees when executed and returns a Promise
 * containing the same mailbox address, draft identity, and CC addressees.
 */
function createPutMailboxDraftCcAddressees(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, draftIdentity, ccAddressees) => (hasPutFn ?
    putMailboxDraftCcAddressees(putFn, mailboxAddress, draftIdentity, ccAddressees) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutMailboxDraftCcAddressees;
