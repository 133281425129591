// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { toAddressUpdateFailure, toAddressUpdateSuccess } from "../../actions/draftToActions";
import { DRAFT_TO_ADDRESSEES_UPDATE_START } from "../../constants/ActionTypes";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const toAddressUpdateStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_TO_ADDRESSEES_UPDATE_START),
  mergeMap(action => from(
    messagingClient.putMailboxDraftToAddressees(
      action.mailboxAddress,
      action.draftIdentity,
      state$.value.messaging.draftView.to.workingAddressees
    ))
    .pipe(
      map(response => toAddressUpdateSuccess(action.mailboxAddress, action.draftIdentity, response.toAddresses)),
      catchError(error => of(toAddressUpdateFailure(action.mailboxAddress, action.draftIdentity, error)))
    )
  )
);

export default toAddressUpdateStartEpic;
