// Third Party
import { constant } from "lodash";

// Project
import { FOLDER_CREATE_FAILURE } from "../constants/ActionTypes";
import { FOLDER_CREATE_FAILURE_DISPLAY } from "../constants/Errors";
import fromFailureAction from "./util/fromFailureAction";

const folderCreateFailureEpic = fromFailureAction(
  FOLDER_CREATE_FAILURE,
  constant(FOLDER_CREATE_FAILURE_DISPLAY)
);

export default folderCreateFailureEpic;
