/**
 * Find the last index of a regular expression.
 *
 * @param {String} targetString - String to be examined.
 * @param {String} regex - Regex to match.
 * @param {Number} startPos - starting position to check.
 * @return {Number} Last index of regex.
 */
export default function lastIndexOfRegex(targetString, regex) {
  const myRegex = regex.global ?
    regex :
    new RegExp(regex.source, `g${regex.ignoreCase ? "i" : ""}`);
  let lastIndexOf = -1;
  let result = -1;

  while ((result = myRegex.exec(targetString)) !== null) {
    lastIndexOf = result.index;
  }

  return lastIndexOf;
}
