// Third Party
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./Header.css";
import CcAddressees, { ccAddressInputId } from "./CcAddressees";
import FromAddress from "./FromAddress";
import SendButton from "../formElements/SendButton";
import Subject, { subjectInputId } from "./Subject";
import ToAddressees, { toAddressInputId } from "./ToAddressees";
import TrashButton from "../formElements/TrashButton";

// NOTE (jeremiah.sanders): Bootstrap provides .align-self-center for flex- and grid- children vertical alignment,
//   and .col-form-label for label-to-input alignment. Since our addressees are both a layout of existing addressees
//   and an input, we must use the former when addressees are present, and the latter when they are not, to ensure
//   expected label alignment.
const getAddresseeLabelClass = addressees => (addressees && addressees.length ? "align-self-center" : "col-form-label");

/* eslint-disable max-lines-per-function */

/**
 * Draft editor header.
 * @param {Object} props - Rendering properties
 * @return {Object} - JSX element.
 */
function Header(props) {
  return <div aria-label="Draft header" className="draft-header">
    <div className="draft-actions">
      <SendButton onClick={props.onSendClick} />
      <TrashButton onClick={props.onDiscardClick} title="Delete draft" />
    </div>
    <Label className="draft-sender-label">From:</Label>
    <div className="draft-sender">
      <FromAddress mailbox={props.mailbox} />
    </div>
    <Label className={`draft-to-addressees-label ${getAddresseeLabelClass(props.toAddressees)}`} for={toAddressInputId}>To:</Label>
    <div className="draft-to-addressees">
      <ToAddressees
        addressees={props.toAddressees}
        onBlur={props.onToAddressBlur}
        onChange={props.onToAddressChange}
        onToAddresseeClick={props.onToAddresseeClick}
        workingToAddress={props.workingToAddress}
      />
    </div>
    <Label className={`draft-cc-addressees-label ${getAddresseeLabelClass(props.ccAddressees)}`} for={ccAddressInputId}>CC:</Label>
    <div className="draft-cc-addressees">
      <CcAddressees
        addressees={props.ccAddressees}
        onBlur={props.onCcAddressBlur}
        onCcAddresseeClick={props.onCcAddresseeClick}
        onChange={props.onCcAddressChange}
        workingCcAddress={props.workingCcAddress}
      />
    </div>
    <Label className="draft-subject-label col-form-label" for={subjectInputId}>Subject:</Label>
    <Subject
      className="draft-subject"
      onChange={props.onSubjectChange}
      subject={props.subject}
    />
  </div>;
}

/* eslint-enable max-lines-per-function */

Header.propTypes = {
  ccAddressees: PropTypes.array,
  mailbox: PropTypes.object,
  onCcAddressBlur: PropTypes.func,
  onCcAddressChange: PropTypes.func,
  onCcAddresseeClick: PropTypes.func,
  onDiscardClick: PropTypes.func,
  onSendClick: PropTypes.func,
  onSubjectChange: PropTypes.func,
  onToAddressBlur: PropTypes.func,
  onToAddressChange: PropTypes.func,
  onToAddresseeClick: PropTypes.func,
  subject: PropTypes.string,
  toAddressees: PropTypes.array,
  workingCcAddress: PropTypes.string,
  workingToAddress: PropTypes.string
};

export default Header;
