// Project
import createApplyCommonRequestOptions from "./createApplyCommonRequestOptions";

/**
 * Create an API GET function (url -> Promise<Response>).
 *
 * @param {Function} getFn - Function to perform GET request.
 * Expected to match the Fetch API (Request -> Promise<Response>).
 * @param {Object} clientParameters - Object containing API client parameters.
 * @param {String} clientParameters.rootUrl - Root URL for API.
 * @param {String} clientParameters.token - Bearer token for API authentication.
 * @return {Function} Function which accepts a URI and returns a request promise.
 */
function createGet(getFn, clientParameters) {
  const optionApplicator = createApplyCommonRequestOptions(clientParameters);

  return url => {
    const getRequest = optionApplicator(
      new Request(
        `${clientParameters.rootUrl}${url}`,
        {
          method: "GET"
        }
      )
    );

    return getFn(getRequest);
  };
}

export default createGet;
