// Project
import { isTargetNotification } from "../selectors/messaging/notifications";
import { NOTIFICATION_ADD, NOTIFICATION_DISMISS, NOTIFICATION_DISMISS_DELAYED_ALLOW, NOTIFICATION_DISMISS_DELAYED_PREVENT } from "../constants/ActionTypes";

/**
 *  Add notification to state
 *
 * @param {Object} state - Messaging slice state.
 * @param {Object} action - A notification add action.
 * @param {String} action.message - User-facing notification message.
 * @param {String} action.severity - Severity of the notification.
 * @param {Number} action.timestamp - Notification timestamp.
 * @param {?Object} action.triggeringAction - Action which triggered the notification.
 *
 * @return {Object} Updated Messaging slice state.
 */
function notificationAdd(state, { message, severity, timestamp, triggeringAction }) {
  const notificationObject = {
    message,
    severity,
    timestamp,
    triggeringAction
  };

  return {
    ...state,
    notifications: [notificationObject, ...state.notifications]
  };
}

/**
 *  Remove selected notification from state
 *
 * @param {Object} state - Messaging slice state.
 * @param {Object} action - An action.
 *
 * @return {Object} Updated Messaging slice state.
 */
function notificationDismiss(state, action) {
  const checkNotificationId = value => value.triggeringAction.type !== action.notificationActionType ||
    value.timestamp !== action.notificationTimestamp;

  return {
    ...state,
    notifications: state.notifications.filter(checkNotificationId)
  };
}

/**
 * Set excludeFromDelayedDismissal for all matching notifications in state.
 * @param {Boolean} exclude - Flag indicating if the notification should be excluded from delayed dismissal.
 * @private
 * @param {String?} triggeringActionType - Target triggering action type.
 * @param {Number} timestamp - Target triggering action timestamp.
 * @param {Array.<Object>} notifications - Existing notifications.
 * @return {Object}
 */
function setExcludeFromDelayedDismissalForMatchingActions(exclude, triggeringActionType, timestamp, notifications) {
  return notifications.map(notification => (isTargetNotification(notification, triggeringActionType, timestamp) ?
    { ...notification,
      excludeFromDelayedDismissal: exclude } :
    notification));
}

/**
 * Allow notification to be dismissed automatically.
 *
 * @param {Object} state - Messaging slice state.
 * @param {{timestamp:Number, triggeringActionType:String?, type: String}} action - An action.
 *
 * @return {Object} Updated Messaging slice state.
 */
function notificationDismissDelayedAllow(state, action) {
  return {
    ...state,
    notifications: setExcludeFromDelayedDismissalForMatchingActions(
      false,
      action.triggeringActionType,
      action.timestamp,
      state.notifications
    )
  };
}

/**
 * Prevent notification from being dismissed automatically.
 *
 * @param {Object} state - Messaging slice state.
 * @param {{timestamp:Number, triggeringActionType:String?, type: String}} action - An action.
 *
 * @return {Object} Updated Messaging slice state.
 */
function notificationDismissDelayedPrevent(state, action) {
  return {
    ...state,
    notifications: setExcludeFromDelayedDismissalForMatchingActions(
      true,
      action.triggeringActionType,
      action.timestamp,
      state.notifications
    )
  };
}

/**
 * Combine the notification reducers.
 *
 * @param {Object} state - Messaging slice state.
 * @param {Object} action - Action type.
 * @return {Object} Messaging slice state.
 */
function notificationReducer(state, action) {
  if (action && action.type) {
    switch (action.type) {
      case NOTIFICATION_ADD: return notificationAdd(state, action);

      case NOTIFICATION_DISMISS: return notificationDismiss(state, action);

      case NOTIFICATION_DISMISS_DELAYED_ALLOW: return notificationDismissDelayedAllow(state, action);

      case NOTIFICATION_DISMISS_DELAYED_PREVENT: return notificationDismissDelayedPrevent(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}

export default notificationReducer;
