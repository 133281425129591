// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { MESSAGE_SELECTED } from "../constants/ActionTypes";
import { messageLoadingStart } from "../actions/messageViewActions";

const messageSelectedEpic = action$ => action$.pipe(
  ofType(MESSAGE_SELECTED),
  map(action => messageLoadingStart(action.mailboxAddress, action.messageIdentity))
);

export default messageSelectedEpic;
