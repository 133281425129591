// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { MESSAGES_MOVE_TRASH_REQUEST } from "../constants/ActionTypes.js";
import messagesMoveTrashFailure from "../actions/messages/messagesMoveTrashFailure.js";
import messagesMoveTrashSuccess from "../actions/messages/messagesMoveTrashSuccess.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const messagesMoveTrashRequestEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MESSAGES_MOVE_TRASH_REQUEST),
  mergeMap(action => from(messagingClient.messagesMoveTrashRequest(action.mailboxAddress, action.messageIdentities))
    .pipe(
      // eslint-disable-next-line max-len
      map(response => messagesMoveTrashSuccess(action.mailboxAddress, response.messageIdentitiesMovedToTrash)),
      catchError(error => of(messagesMoveTrashFailure(action.mailboxAddress, error)))
    )
  )
);

export default messagesMoveTrashRequestEpic;
