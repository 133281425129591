// Third Party
import { constant } from "lodash";

// Project
import { MESSAGES_MOVE_TRASH_FAILURE } from "../constants/ActionTypes";
import { MESSAGES_TRASH_FAILURE_DISPLAY } from "../constants/Errors";
import fromFailureAction from "./util/fromFailureAction";

const messagesMoveTrashFailureEpic = fromFailureAction(
  MESSAGES_MOVE_TRASH_FAILURE,
  constant(MESSAGES_TRASH_FAILURE_DISPLAY)
);

export default messagesMoveTrashFailureEpic;
