// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_MOVE_OUTBOX_REQUESTED } from "../constants/ActionTypes.js";
import { draftMoveOutboxStart } from "../actions/draftMessage/draftMoveToOutboxActions";

const draftMoveOutboxRequestedEpic = action$ => action$.pipe(
  ofType(DRAFT_MOVE_OUTBOX_REQUESTED),
  map(action => draftMoveOutboxStart(action.mailboxAddress, action.draftIdentity))
);

export default draftMoveOutboxRequestedEpic;
