// Project
import * as types from "../constants/ActionTypes.js";

/**
 * Signals creation of a new draft message.
 *
 * @param {String} mailboxIdentity - Mailbox identity for the draft message.
 * @param {String} draftIdentity  - Draft identity return from the API.
 *
 * @return {Object} Action
 */
export function draftCreated(mailboxIdentity, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxIdentity: mailboxIdentity,
    type: types.DRAFT_CREATED
  };
}

/**
 * Signals that a draft message has been retrieved.
 *
 * @param {String} mailboxIdentity - The identity of the user's mailbox.
 * @param {String} draftIdentity - The identity of the draft message.
 * @param {Object} draftMessage - The draft message.
 *
 * @return {Object} Draft retrieved action.
 */
export function draftRetrieved(mailboxIdentity, draftIdentity, draftMessage) {
  return {
    draft: draftMessage,
    draftIdentity: draftIdentity,
    mailboxIdentity: mailboxIdentity,
    type: types.DRAFT_RETRIEVED
  };
}

/**
 * Signals a failure to retrieve a draft message.
 *
 * @param {String} mailboxIdentity - The identity of the user's mailbox
 * @param {String} draftIdentity - The identity of the draft message.
 * @param {String} message - The error message received when trying to retrieve the draft.
 *
 * @return {Object} Draft retrieval failure object.
 */
export function draftRetrievalFailure(mailboxIdentity, draftIdentity, message) {
  return {
    draftIdentity: draftIdentity,
    mailboxIdentity: mailboxIdentity,
    message: message,
    type: types.DRAFT_RETRIEVAL_FAILURE
  };
}

/**
 * Signals the selection of a new draft message.
 *
 * @param {String} mailboxIdentity - Identity of the user's mailbox.
 * @param {String} draftIdentity - Identity of the new draft message.
 *
 * @return {Object} Draft selected action
 */
export function draftSelected(mailboxIdentity, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxIdentity: mailboxIdentity,
    type: types.DRAFT_SELECTED
  };
}

/**
 * Create a new blank draft message.
 *
 * @param {String} mailboxIdentity - Mailbox identifier.
 * @return {Object} Action.
 */
export function requestNewBlankDraft(mailboxIdentity = "") {
  return {
    mailboxIdentity: mailboxIdentity,
    type: types.REQUEST_NEW_BLANK_DRAFT
  };
}

/**
 * Signals error occurred during draft-message creation.
 *
 * @param {String} mailboxIdentity - Mailbox identity for the draft message.
 * @param {String} message - Error message.
 *
 * @return {Object} Action
 */
export function draftCreationFailed(mailboxIdentity, message) {
  return {
    mailboxIdentity: mailboxIdentity,
    message: message,
    type: types.DRAFT_CREATION_FAILED
  };
}
