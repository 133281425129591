// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";


// Project
import { DRAFT_LIST_LOADING_START } from "../constants/ActionTypes";
import { draftListLoadingFailure, draftListLoadingSuccess } from "../actions/draftsActions";

const draftListLoadingStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_LIST_LOADING_START),
  mergeMap(action => from(messagingClient.getDrafts(action.mailboxAddress))
    .pipe(
      map(response => draftListLoadingSuccess(action.mailboxAddress, response.drafts)),
      catchError(error => of(draftListLoadingFailure(action.mailboxAddress, error)))
    )
  )
);

export default draftListLoadingStartEpic;
