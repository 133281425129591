// Project
import createStore, { defaultHistory } from "./createStore";

/**
 * History library object wired into exposed Redux store.
 * @remarks According to https://github.com/supasate/connected-react-router#step-3, the same reference must be passed
 * to routerMiddleware, connectRouter, and the ConnectedRouter component loaded in index.jsx.
 */
export const history = defaultHistory;

// eslint-disable-next-line no-undefined
const store = createStore(undefined, undefined, history);

export default store;
