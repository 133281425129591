// Third Party
import { Button } from "reactstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Creates a Unseen button.
 *
 * @constructor
 * @param {Object} props - Unseen button properties
 */
function UnseenButton(props) {
  return (
    <Button color="primary" onClick={props.onClick} outline title="Mark as unread">
      <FontAwesomeIcon icon={faEnvelope} />
    </Button>
  );
}

UnseenButton.propTypes = {
  onClick: PropTypes.func
};

export default UnseenButton;
