// Project
import * as types from "../constants/ActionTypes.js";

/**
 *  Action for signaling the start of the body-text update process.
 *
 * @param {String} mailboxId - Mailbox identity.
 * @param {String} draftId - Draft Identity.
 * @param {String} workingBody - Unsaved body of draft message.
 * @param {String} autosave - Flag denoting an automatic versus a user-generated save.
 * @return {Object} Action object.
 */
export function bodyUpdateStart(mailboxId, draftId, workingBody, autosave) {
  /* eslint-disable no-unneeded-ternary */
  return {
    draftAutosaving: Boolean(autosave),
    draftIdentity: draftId,
    mailboxIdentity: mailboxId,
    type: types.DRAFT_BODY_UPDATE_START,
    workingBody: workingBody
  };
}

/**
 * Action for signaling a failure to update the body text.
 *
 * @param {String} mailboxId - Mailbox identity.
 * @param {String} draftId - Draft identity.
 * @param {String} message - Error message.
 * @return {Object} Action object.
 */
export function bodyUpdateFailure(mailboxId, draftId, message) {
  return {
    draftIdentity: draftId,
    mailboxIdentity: mailboxId,
    message: message,
    type: types.DRAFT_BODY_UPDATE_FAILURE
  };
}

/**
 * Action for signaling a successful body-text update.
 *
 * @param {String} mailboxId - Mailbox identity.
 * @param {String} draftId - Draft identity.
 * @param {String} cachedBody - Body text from the API.
 * @return {Object} Action object.
 */
export function bodyUpdateSuccess(mailboxId, draftId, cachedBody) {
  return {
    body: cachedBody,
    draftIdentity: draftId,
    mailboxIdentity: mailboxId,
    type: types.DRAFT_BODY_UPDATE_SUCCESS
  };
}

/**
 * On change action for the body text.
 *
 * @param {String} mailboxId - Mailbox identity.
 * @param {String} draftId - Draft Identity.
 * @param {String} workingBody - Draft body text.
 * @return {Object} Action object.
 */
export function bodyChanged(mailboxId, draftId, workingBody) {
  return {
    draftIdentity: draftId,
    mailboxIdentity: mailboxId,
    type: types.DRAFT_BODY_CHANGED,
    workingBody: workingBody
  };
}
