// Project
import { DRAFT_CC_ADDRESSEES_UPDATE_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_INVALID_CC_EMAIL_ADDRESS_DISPLAY, DRAFT_UPDATE_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";
import isApiStatusCodeError from "../../reducers/draft/util/isApiStatusCodeError";

const messageGenerator = action => {
  const invalidEmailCode = "400";

  return isApiStatusCodeError(invalidEmailCode, action.error) ?
    DRAFT_INVALID_CC_EMAIL_ADDRESS_DISPLAY :
    DRAFT_UPDATE_FAILURE_DISPLAY;
};

const draftCcUpdateFailureEpic = fromFailureAction(
  DRAFT_CC_ADDRESSEES_UPDATE_FAILURE,
  messageGenerator
);

export default draftCcUpdateFailureEpic;
