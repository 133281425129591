// Project
import { MESSAGES_MOVE_FOLDER_SUCCESS } from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Clear the selected messages.
 *
 * @param {Object} state - Application state.
 * @return {Object} Application state.
 */
function messagesMoveFolderSuccess(state) {
  return {
    ...state,
    messageList: {
      ...state.messageList,
      selectedMessages: []
    }
  };
}

/**
 * Messages move folders reducer.
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action.
 * @return {Object} Application state.
 */
function messagesMoveFolderReducers(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case MESSAGES_MOVE_FOLDER_SUCCESS: return messagesMoveFolderSuccess(state);

      default: return state;
    }
  }

  return state;
}

export default messagesMoveFolderReducers;
