// Third Party
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { faArrowRight, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./MoveFolderDropdown.css";

/* eslint-disable max-lines-per-function */
/**
 * Creates a trash button.
 *
 * @constructor
 * @param {Object} props - Trash button properties.
 * @param {Boolean} props.disabled - Flag indicating if the button should be disabled.
 * @param {Array.<{displayName: String, folderId: String}>} props.folders - Available mailbox folders.
 * @param {Function} props.onClick - On click function.
 * @param {String} title - Trash button title.
 */
function MoveFolderDropdown(props) {
  return (
    <UncontrolledDropdown
      disabled={props.disabled}
      id="moveFolderDropdown"
      size="sm"
      title="Move message(s) to a new folder"
    >
      <DropdownToggle caret disabled={props.disabled} outline>
        <span className="fa-layers fa-fw fa-2x">
          <FontAwesomeIcon icon={faFolder} />
          <FontAwesomeIcon icon={faArrowRight} className="move-right-arrow" transform={"shrink-8 down-1"}/>
        </span>
      </DropdownToggle>
      <DropdownMenu>
        {/* eslint-disable implicit-arrow-linebreak */}
        { props.folders.map(folder =>
          <DropdownItem
            key={`${folder.folderId}-${folder.displayName}`}
            aria-label={`Move to ${folder.displayName}`}
            onClick={ () => props.onMoveFolderClick(folder.folderId) }
          >
            {folder.displayName}
          </DropdownItem>
        )}
        {/* eslint-enable implicit-arrow-linebreak */}
      </DropdownMenu>
    </UncontrolledDropdown>);
}

MoveFolderDropdown.propTypes = {
  disabled: PropTypes.bool,
  folders: PropTypes.array,
  onMoveFolderClick: PropTypes.func,
  title: PropTypes.string
};

export default MoveFolderDropdown;
