// Third party
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import FolderListItem from "./FolderListItem";

const draftsFolderId = "drafts";

/**
 *  Build a folder list item.
 *
 * @param {Object} props - Function properties.
 * @param {String} props.displayName - Folder display name.
 * @param {String} props.folderId - Folder identity string.
 * @param {Boolean} props.isSelected - Flag denoting whether the folder is selected or not.
 * @return {Object} FolderListItem.
 */
function SystemFolderListItem(props) {
  const folderClickHandler = () => props.onFolderClick(props.folderId);

  return (
    <FolderListItem
      displayName={props.displayName}
      folderId={props.folderId}
      isSelected={props.isSelected}
      key={props.folderId}
      onFolderClick={folderClickHandler}
    />
  );
}

SystemFolderListItem.propTypes = {
  displayName: PropTypes.string.isRequired,
  folderId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onFolderClick: PropTypes.func
};

const DraftsAsSystemFolderListItem = props => <FolderListItem
  displayName="Drafts"
  folderId={draftsFolderId}
  isSelected={props.isSelected}
  onFolderClick={props.onClick}
/>;

DraftsAsSystemFolderListItem.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};


/**
 *  Build the system folder list.
 *
 * @constructor
 * @param {Object} props - System folder properties.
 * @param {String} props.currentFolderId - Currently-selected mailbox folder.
 * @param {Boolean} props.isDraftsSelected - Flag indicating that Drafts, rather than a message folder, is selected.
 * @param {Function} props.onDraftsClick - Callback when Drafts is selected.
 * @param {Function} props.onFolderClick - Callback when a system message folder is selected.
 * @param {Object} props.style - Optional style.
 * @param {Array} props.systemFolders - Non-user-generated mailbox folder list.
 *
 */
function SystemFolders(props) {
  const isDraftsSelected = Boolean(props.isDraftsSelected);
  const folderItems = props.systemFolders
    .map(selectedFolder => (
      { ...selectedFolder,
        isSelected: !isDraftsSelected && props.currentFolderId === selectedFolder.folderId,
        onFolderClick: props.onFolderClick }
    ))
    .map(SystemFolderListItem)
    .concat([
      <DraftsAsSystemFolderListItem
        isSelected={isDraftsSelected}
        key={draftsFolderId}
        onClick={props.onDraftsClick}
      />
    ]);

  return (
    <ListGroup aria-label="System mailbox message folders" flush style={props.style}>{ folderItems }</ListGroup>
  );
}

SystemFolders.propTypes = {
  currentFolderId: PropTypes.string.isRequired,
  isDraftsSelected: PropTypes.bool,
  onDraftsClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  style: PropTypes.object,
  systemFolders: PropTypes.array.isRequired
};

export default SystemFolders;
