// Third Party
import { ignoreElements, tap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { USER_SIGN_IN_REQUESTED } from "../../constants/ActionTypes.js";

// NOTE(jeremiah.sanders): Because Observable.tap doesn't return an Observable, which is required by
//   redux-observable, we use ignoreElements() to create an observable which "Ignores all items emitted by the source
//   Observable and only passes calls of complete or error." That enables a side-effect-only epic.
//   See: https://redux-observable.js.org/docs/Troubleshooting.html#it-seems-like-your-epics-are-not-responding-to-actions
const userSignInRequestedEpic = (action$, state$, { userManager }) => action$.pipe(
  ofType(USER_SIGN_IN_REQUESTED),
  tap(() => userManager.signinRedirect()),
  ignoreElements()
);

export default userSignInRequestedEpic;
