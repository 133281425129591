// Third Party
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

// Project
import { isUserSignedIn } from "../selectors/oidc";
import { unauthenticated } from "../constants/PageRoutes";

/**
 * Redirect users from protected pages to home page before login.
 * @param {Object} props - Rendering properties.
 * @param {Object} props.component - JSX component to render.
 * @param {Object} props.isAuthenticated - Flag indicating whether user is authenticated.
 * @return {Object} Route.
 */
function ProtectedRoute({ component: Component, isAuthenticated, ...rest }) {
  return <Route
    {...rest}
    render={routerProps => (
      isAuthenticated ?
        <Component {...routerProps}/> :
        <Redirect
          to={{ pathname: unauthenticated }}
        />
    )}
  />;
}

// NOTE(jeremiah.sanders): .component is defined as a PropTypes.object to avoid React.StrictMode validation errors
//   when the protected route is accessed and the rendering result is <Redirect/>, rather than the expected component.
ProtectedRoute.propTypes = {
  component: PropTypes.object,
  isAuthenticated: PropTypes.bool
};

const mapDispatchToProps = () => ({});

const mapStateToProps = state => ({
  isAuthenticated: isUserSignedIn(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
