// Third Party
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

/**
 * Creates a derived draft button.
 *
 * @constructor
 * @param {Object} props - Derived draft button properties
 */
function DerivedDraftButton(props) {
  return (
    <Button color="primary" onClick={props.onClick} outline title={props.title}>
      {props.children}
    </Button>);
}

DerivedDraftButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default DerivedDraftButton;
