// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the deleteFn with mailboxAddress and draftIdentity, if they are provided.
 *
 * @param {Function} deleteFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft identity to retrieve.
 * @return {Promise}
 */
function deleteDraft(deleteFn, mailboxAddress, draftIdentity) {
  return mailboxAddress && draftIdentity ?
    deleteFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}`) :
    Promise.reject(new Error("Mailbox address and draft identity are required"));
}

/**
 * Creates a function which will delete the draft.
 *
 * @param {Function} deleteFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which deletes a draft when executed with a mailbox address and draft identity.
 */
function createDeleteDraft(deleteFn) {
  const hasDeleteFn = deleteFn && isFunction(deleteFn);

  return (mailboxAddress, draftIdentity) => (hasDeleteFn ?
    deleteDraft(deleteFn, mailboxAddress, draftIdentity) :
    Promise.reject(new Error("HTTP DELETE function is required but was not provided")));
}

export default createDeleteDraft;
