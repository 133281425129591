const locationHeaderName = "location";

/**
 * Parses the
 * @param {Response} response - A Response object.
 * @return {String}
 */
function getResponseHeaderLocation(response) {
  return response.headers.get(locationHeaderName);
}

export default getResponseHeaderLocation;
