// Project
import * as applicationConfig from "../../../config/application";

const delimiterRegex = new RegExp(applicationConfig.draftView.addressDelimiters, "u");

/**
 *  Splits a string based upon the address-field delimiters.
 *
 * @param {String} toBeParsed - String to be parsed.
 * @return {Array} String elements.
 */
export default function parseDelimitedString(toBeParsed) {
  return toBeParsed.split(delimiterRegex);
}
