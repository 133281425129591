// Project
import {
  MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD,
  MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR,
  MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE
} from "../constants/ActionTypes";

/**
 * Signals that a message or messages have been multi-selected.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @param {Array} messageIdentities - Array of message identities.
 * @return {Object} Action object.
 */
export function messageListMultiSelectItemsAdd(mailboxAddress, messageIdentities) {
  return {
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGE_LIST_MULTI_SELECT_ITEMS_ADD
  };
}

/**
 * Signal the intent to clear the selected messages.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @return {Object} Action object.
 */
export function messageListMultiSelectItemsClear(mailboxAddress) {
  return {
    mailboxAddress: mailboxAddress,
    type: MESSAGE_LIST_MULTI_SELECT_ITEMS_CLEAR
  };
}

/**
 * Signals the intent to deselect a message or message that has been multi-selected.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Array of message identities.
 * @return {Object} Action object.
 */
export function messageListMultiSelectItemsRemove(mailboxAddress, messageIdentities) {
  return {
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGE_LIST_MULTI_SELECT_ITEMS_REMOVE
  };
}
