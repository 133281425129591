// Third Party
import { Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./MessageView.css";
import { formatEmailAddressDisplay } from "../../util/FormatEmailAddressDisplay";
import { messagesDeleteStart } from "../../actions/messages/messagesDeleteActions";
import {
  messageAttachmentDownloadRequest,
  messageAttachmentOpenRequest,
  requestForwardDerivedDraft,
  requestReplyAllDerivedDraft,
  requestReplyDerivedDraft,
  setMessageState
} from "../../actions/messageViewActions";
import AttachmentListItem from "../formElements/AttachmentListItem";
import ForwardButton from "./ForwardButton";
import getFullDate from "../../util/getFullDate";
import messagesMoveTrashRequest from "../../actions/messages/messagesMoveTrashRequest";
import ReplyAllButton from "./ReplyAllButton";
import ReplyButton from "./ReplyButton";
import TrashButton from "../formElements/TrashButton";
import UnseenButton from "./UnseenButton";

/* eslint-disable max-lines-per-function */
/**
 * Message view header component.
 * @param {Object} props - Rendering properties.
 * @return {Object} - JSX element.
 */
function MessageViewHeader(props) {
  // TODO(jeremiah.sanders): Add CC addressees. DEV-3915
  const messageDate = props.receivedDateTime ? getFullDate(new Date(props.receivedDateTime)) : "";

  return <div className="message-view-header">
    <div className="message-subject"><CardTitle tag="h5">{props.subject}</CardTitle></div>
    <div className="message-from"><CardSubtitle tag="h6">{props.fromAddress}</CardSubtitle></div>
    <div className="message-date">{messageDate}</div>
    <div className="message-actions d-flex flex-wrap">
      <ReplyButton onClick={props.onReplyClick} />
      <ReplyAllButton onClick={props.onReplyAllClick} />
      <ForwardButton onClick={props.onForwardClick} />
      <UnseenButton onClick={props.onUnseenClick} />
      <TrashButton onClick={props.onTrashClick} title="Move message to trash" />
    </div>
  </div>;
}
/* eslint-enable max-lines-per-function */

MessageViewHeader.propTypes = {
  fromAddress: PropTypes.string,
  onForwardClick: PropTypes.func,
  onReplyAllClick: PropTypes.func,
  onReplyClick: PropTypes.func,
  onTrashClick: PropTypes.func,
  onUnseenClick: PropTypes.func,
  receivedDateTime: PropTypes.string,
  subject: PropTypes.string
};

/* eslint-disable max-lines-per-function */
/**
 * Build the message view.
 *
 * @constructor
 * @param {Object} props - Message view properties.
 */
function MessageView(props) {
  const forwardClickHandler = () => props.onForwardClick(props.mailboxAddress, props.messageIdentity);
  const replyClickHandler = () => props.onReplyClick(props.mailboxAddress, props.messageIdentity);
  const replyAllClickHandler = () => props.onReplyAllClick(props.mailboxAddress, props.messageIdentity);
  const trashClickHandler = props.folderId === "trash" ?
    () => props.onTrashDeleteClick(props.mailboxAddress, props.messageIdentity) :
    () => props.onTrashDiscardClick(props.mailboxAddress, props.messageIdentity);
  const unseenClickHandler = () => props.onUnseenClick(props.mailboxAddress, props.messageIdentity);
  const attachmentClickHandler = attachmentIdentifier => props.onAttachmentClick(props.mailboxAddress,
    props.messageIdentity, attachmentIdentifier);
  const attachmentDownloadClickHandler = attachmentIdentifier => props.onAttachmentDownloadClick(props.mailboxAddress,
    props.messageIdentity, attachmentIdentifier);

  const attachmentComponents = props.attachments
    .map(attachment => {
      const openHandler = () => attachmentClickHandler(attachment.attachmentIdentifier);
      const downloadHandler = () => attachmentDownloadClickHandler(attachment.attachmentIdentifier);

      return <div
        className="attachment-wrapper"
        key={attachment.attachmentIdentifier}
      >
        <AttachmentListItem
          fileName={attachment.fileName}
          onAttachmentClick={openHandler}
          onDownloadClick={downloadHandler}
        /></div>;
    });
  const possibleFooter = isEmpty(props.attachments) ?
    null :
    <CardFooter className="message-view-footer">
      <CardSubtitle tag="h6">Attachments</CardSubtitle>
      <div className="d-flex flex-wrap">
        {attachmentComponents}
      </div>
    </CardFooter>;

  return (
    <Card className="message-view-wrapper">
      <CardHeader>
        <MessageViewHeader
          fromAddress={formatEmailAddressDisplay(props.fromAddress)}
          onForwardClick={forwardClickHandler}
          onReplyAllClick={replyAllClickHandler}
          onReplyClick={replyClickHandler}
          onTrashClick={trashClickHandler}
          onUnseenClick={unseenClickHandler}
          receivedDateTime={props.receivedDateTime}
          subject={props.subject}
        />
      </CardHeader>
      <CardBody className="message-body">
        {props.body}
      </CardBody>
      {possibleFooter}
    </Card>
  );
}

MessageView.propTypes = {
  attachments: PropTypes.array,
  body: PropTypes.string,
  folderId: PropTypes.string,
  fromAddress: PropTypes.object,
  mailboxAddress: PropTypes.string,
  messageIdentity: PropTypes.string,
  onAttachmentClick: PropTypes.func,
  onAttachmentDownloadClick: PropTypes.func,
  onForwardClick: PropTypes.func,
  onReplyAllClick: PropTypes.func,
  onReplyClick: PropTypes.func,
  onTrashDeleteClick: PropTypes.func,
  onTrashDiscardClick: PropTypes.func,
  onUnseenClick: PropTypes.func,
  receivedDateTime: PropTypes.string,
  subject: PropTypes.string
};

// Component Setup
/* eslint-disable max-len */
const mapDispatchToProps = dispatch => ({
  onAttachmentClick: (mailboxAddress, messageIdentity, attachmentIdentifier) => dispatch(
    messageAttachmentOpenRequest(mailboxAddress, messageIdentity, attachmentIdentifier)),
  onAttachmentDownloadClick: (mailboxAddress, messageIdentity, attachmentIdentifier) => dispatch(
    messageAttachmentDownloadRequest(mailboxAddress, messageIdentity, attachmentIdentifier)),
  onForwardClick: (mailboxAddress, messageIdentity) => dispatch(requestForwardDerivedDraft(mailboxAddress, messageIdentity)),
  onReplyAllClick: (mailboxAddress, messageIdentity) => dispatch(requestReplyAllDerivedDraft(mailboxAddress, messageIdentity)),
  onReplyClick: (mailboxAddress, messageIdentity) => dispatch(requestReplyDerivedDraft(mailboxAddress, messageIdentity)),
  onTrashDeleteClick: (mailboxAddress, messageIdentity) => dispatch(messagesDeleteStart(mailboxAddress, [messageIdentity])),
  onTrashDiscardClick: (mailboxAddress, messageIdentity) => dispatch(messagesMoveTrashRequest(mailboxAddress, [messageIdentity])),
  onUnseenClick: (mailboxAddress, messageIdentity) => dispatch(setMessageState(mailboxAddress, messageIdentity, false))
});
/* eslint-enable max-len */

const mapStateToProps = state => ({
  attachments: state.messaging.messageView.message.attachments,
  body: state.messaging.messageView.message.body,
  ccAddresses: state.messaging.messageView.message.ccAddresses,
  dateSent: state.messaging.messageView.message.dateSent,
  folderId: state.messaging.messageView.message.folder.folderId,
  fromAddress: state.messaging.messageView.message.fromAddress,
  mailboxAddress: state.messaging.mailbox.address,
  messageIdentity: state.messaging.messageView.message.messageIdentity,
  receivedDateTime: state.messaging.messageView.message.receivedDateTime,
  sentDateTime: state.messaging.messageView.message.sentDateTime,
  state: state.messaging.messageView.message.state,
  subject: state.messaging.messageView.message.subject,
  toAddresses: state.messaging.messageView.message.toAddresses
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageView);
