// Third Party
import { combineEpics } from "redux-observable";

// Project
import messagesDeleteSuccessNoClearSelectedMessagesEpic from "./messagesDeleteSuccessNoClearSelectedMessagesEpic";
import messagesDeleteSuccessClearSelectedMessagesEpic from "./messagesDeleteSuccessClearSelectedMessagesEpic";

const messagesDeleteSuccessEpic = combineEpics(
  messagesDeleteSuccessClearSelectedMessagesEpic,
  messagesDeleteSuccessNoClearSelectedMessagesEpic
);

export default messagesDeleteSuccessEpic;
