// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the deleteFn with mailboxAddress, draftIdentity, and attachment identity, if they are provided.
 *
 * @param {Function} deleteFn - Function which accepts a URI and returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft identity to alter.
 * @param {String} attachmentIdentity - Attachment identity to delete.
 * @return {Promise}
 */
function deleteDraft(deleteFn, mailboxAddress, draftIdentity, attachmentIdentity) {
  return mailboxAddress && draftIdentity && attachmentIdentity ?
    deleteFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/attachments/${attachmentIdentity}`)
      .then(response => response) :
    Promise.reject(new Error("Mailbox address, draft identity, and attachment identity are required"));
}

/**
 * Creates a function which will delete a draft attachment.
 *
 * @param {Function} deleteFn - Function which accepts a URI and returns a Promise.
 * @return {Function} Function which deletes a draft attachment when executed with a mailbox address, draft identity,
 * and attachment identity.
 */
function createDeleteDraftAttachment(deleteFn) {
  const hasDeleteFn = deleteFn && isFunction(deleteFn);

  return (mailboxAddress, draftIdentity, attachmentIdentity) => (hasDeleteFn ?
    deleteDraft(deleteFn, mailboxAddress, draftIdentity, attachmentIdentity) :
    Promise.reject(new Error("HTTP DELETE function is required but was not provided")));
}

export default createDeleteDraftAttachment;
