// Third Party
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

// Project
import { DRAFT_LIST } from "../../constants/ListViewPanes";
import DraftList from "../DraftList";
import MessageList from "../MessageList";

const mapDispatchToProps = {};
const mapStateToProps = state => ({
  pane: state.messaging.listViewPane
});

/**
 * Determines which detailed view pane to display.
 *
 * @param {Object} props - Detail view properties.
 * @return {Object} React object containing the default-view contents.
 */
function ListView(props) {
  switch (props.pane) {
    case DRAFT_LIST: return <DraftList />;

    default: return <MessageList/>;
  }
}

ListView.propTypes = {
  pane: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
