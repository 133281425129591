// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_MOVE_OUTBOX_SUCCESS } from "../constants/ActionTypes.js";
import { DRAFT_LIST } from "../constants/ListViewPanes";
import { draftListLoadingStart } from "../actions/draftsActions";

const draftMoveOutboxSuccessEpic = (action$, state$) => action$.pipe(
  ofType(DRAFT_MOVE_OUTBOX_SUCCESS),
  filter(() => state$.value.messaging.listViewPane === DRAFT_LIST),
  map(action => draftListLoadingStart(action.mailboxAddress))
);

export default draftMoveOutboxSuccessEpic;
