// Third party
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import FolderListItem from "./FolderListItem";

/* eslint-disable max-lines-per-function */
/**
 *  Build a folder list item.
 *
 * @param {Object} props - Function properties.
 * @param {String} props.displayName - Folder display name.
 * @param {String} props.folderId - Folder identity string.
 * @param {Boolean} props.isSelected - Flag denoting whether the folder is selected or not.
 * @return {Object} FolderListItem.
 */
function toMailboxFolderItem(props) {
  /* eslint-disable arrow-body-style */
  const handleCancelEditClick = () => {
    return props.onEditFolderAbortClick(props.folderId);
  };
  const handlerEditClick = () => {
    return props.onEditFolderClick(props.folderId, props.displayName);
  };
  const handleFolderClick = () => {
    return props.onFolderClick(props.folderId);
  };
  const handleInputChange = workingName => {
    return props.onCurrentFolderNameChange(props.folderId, workingName);
  };
  const handleSaveClick = () => {
    return props.onEditFolderSaveClick(props.folderId, props.currentFolderWorkingDisplayName);
  };
  /* eslint-enable arrow-body-style */

  return (
    <FolderListItem
      currentFolderWorkingDisplayName={props.currentFolderWorkingDisplayName}
      displayName={props.displayName}
      onEditFolderAbortClick={handleCancelEditClick}
      folderId={props.folderId}
      isSelected={props.isSelected}
      key={props.folderId}
      onCurrentFolderNameChange={handleInputChange}
      onEditClick={handlerEditClick}
      onEditFolderSaveClick={handleSaveClick}
      onFolderClick={handleFolderClick}
      showButtons={props.isSelected}
      showFolderNameEditor={props.showFolderNameEditor}
    />
  );
}

toMailboxFolderItem.propTypes = {
  currentFolderWorkingDisplayName: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  folderClickHandler: PropTypes.func,
  folderId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCurrentFolderNameChange: PropTypes.func,
  onEditFolderAbortClick: PropTypes.func,
  onEditFolderClick: PropTypes.func,
  onEditFolderSaveClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  showFolderNameEditor: PropTypes.bool
};

/**
 *  Build the mailbox folder list.
 *
 * @constructor
 * @param {Object} props - Mailbox folder properties.
 * @param {String} props.currentFolderId - Currently-selected mailbox folder.
 * @param {Array} props.mailboxFolders - List of user-created mailbox folders.
 *
 */
function MailboxFolders(props) {
  const mailboxFolderItems = props.mailboxFolders
    .map(selectedFolder => (
      { ...selectedFolder,
        currentFolderWorkingDisplayName: props.currentFolderWorkingDisplayName,
        isSelected: props.currentFolderId === selectedFolder.folderId,
        onCurrentFolderNameChange: props.onCurrentFolderNameChange,
        onEditFolderAbortClick: props.onEditFolderAbortClick,
        onEditFolderClick: props.onEditFolderClick,
        onEditFolderSaveClick: props.onEditFolderSaveClick,
        onFolderClick: props.onFolderClick,
        showFolderNameEditor: props.showFolderNameEditor }
    ))
    .map(toMailboxFolderItem);

  return (
    <ListGroup flush id="mailbox-folder-list">{ mailboxFolderItems }</ListGroup>
  );
}

MailboxFolders.propTypes = {
  currentFolderId: PropTypes.string.isRequired,
  currentFolderWorkingDisplayName: PropTypes.string,
  mailboxFolders: PropTypes.array.isRequired,
  onCurrentFolderNameChange: PropTypes.func,
  onEditFolderAbortClick: PropTypes.func,
  onEditFolderClick: PropTypes.func,
  onEditFolderSaveClick: PropTypes.func,
  onFolderClick: PropTypes.func,
  showFolderNameEditor: PropTypes.bool
};

export default MailboxFolders;
