/**
 * Creates a function which will get the user's assigned mailboxes.
 * @param {Function} getFn - Function which accepts a URI and returns a Promise.
 * @return {Promise.<UserProfile>}
 */
function createGetMyMailboxes(getFn) {
  return () => getFn("api/my/mailboxes").then(response => response.json());
}

export default createGetMyMailboxes;
