// Third party
import isFunction from "lodash/fp/isFunction";
import isString from "lodash/fp/isString";

/**
 * Executes the putFn with the subject API URL and a body object containing the new subject.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft to modify.
 * @param {String} subject - New draft subject.
 * @return {Promise.<Object>} Promise of object with draftIdentity, mailboxAddress, and subject.
 */
function putMailboxDraftSubject(putFn, mailboxAddress, draftIdentity, subject) {
  // Using isString, rather than truthy validation, because empty strings are falsy but are valid Subject values.
  return mailboxAddress && draftIdentity && isString(subject) ?
    putFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/subject`, { subject })
      .then(() => (
        { draftIdentity,
          mailboxAddress,
          subject }
      )) :
    Promise.reject(new Error("Mailbox address, draft identity, and subject are required"));
}

/**
 * Creates a function which will update a mailbox draft subject.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which updates a mailbox draft subject when executed and returns a Promise containing the
 * same mailbox address, draft identity, and subject.
 */
function createPutMailboxDraftSubject(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, draftIdentity, subject) => (hasPutFn ?
    putMailboxDraftSubject(putFn, mailboxAddress, draftIdentity, subject) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutMailboxDraftSubject;
