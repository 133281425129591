const initialMessageViewState = {
  message: {
    attachments: [],
    body: "",
    ccAddresses: [],
    composedDateTime: "",
    folder: {
      displayName: "",
      folderId: ""
    },
    fromAddress: {
      address: "",
      name: ""
    },
    messageIdentity: "",
    receivedDateTime: "",
    sentDateTime: "",
    state: "",
    subject: "",
    toAddresses: []
  },
  messageLoading: false,
  messageLoadingError: false
};

export default initialMessageViewState;
