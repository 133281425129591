// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_DISCARD_REQUESTED } from "../../constants/ActionTypes.js";
import { draftDiscardStart } from "../../actions/draftMessage/draftDeleteActions";

const draftDiscardRequestedEpic = action$ => action$.pipe(
  ofType(DRAFT_DISCARD_REQUESTED),
  map(action => draftDiscardStart(action.mailboxAddress, action.draftIdentities))
);

export default draftDiscardRequestedEpic;
