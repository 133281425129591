// Project
import {
  DRAFT_MOVE_OUTBOX_FAILURE,
  DRAFT_MOVE_OUTBOX_REQUESTED,
  DRAFT_MOVE_OUTBOX_START,
  DRAFT_MOVE_OUTBOX_SUCCESS
} from "../../constants/ActionTypes";


/**
 * Signals the failure to move a draft message to the outbox.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function draftMoveOutboxFailure(mailboxAddress, draftIdentity, error) {
  return {
    draftIdentity: draftIdentity,
    error: error,
    mailboxAddress: mailboxAddress,
    type: DRAFT_MOVE_OUTBOX_FAILURE
  };
}

/**
 * Signals the intent to "send" a draft message.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @return {Object} Action object.
 */
export function draftMoveOutboxRequested(mailboxAddress, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: DRAFT_MOVE_OUTBOX_REQUESTED
  };
}

/**
 * Signals the start of moving a draft message to the outbox.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @return {Object} Action object.
 */
export function draftMoveOutboxStart(mailboxAddress, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: DRAFT_MOVE_OUTBOX_START
  };
}

/**
 * Signals a successful move of a draft message to the outbox.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} draftIdentity - Draft identity.
 * @return {Object} Action object.
 */
export function draftMoveOutboxSuccess(mailboxAddress, draftIdentity) {
  return {
    draftIdentity: draftIdentity,
    mailboxAddress: mailboxAddress,
    type: DRAFT_MOVE_OUTBOX_SUCCESS
  };
}
