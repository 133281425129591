/**
 * Gets the size of a File object.
 * @param {File} file - A File.
 * @return {Number} - The file's size.
 */
function getFileSize(file) {
  return file.size;
}

export default getFileSize;
