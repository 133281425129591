// Third Party
import Either from "data.either";
import get from "lodash/fp/get";
import isBlank from "@jlmorgan/lodash-extras/src/isBlank";
import isString from "lodash/fp/isString";
import pipe from "lodash/fp/pipe";

// Project
import createDelete from "./createDelete";
import createGet from "./createGet";
import createPost from "./createPost";
import createPut from "./createPut";

/**
 * Returns possibleString if it is a String, or an empty String.
 *
 * @param {Object} possibleString - A possible string.
 * @return {String}
 * @private
 */
function coerceToString(possibleString) {
  return isString(possibleString) ? possibleString : "";
}

/**
 * Returns the API root URL, adding a trailing '/' if it is not present.
 *
 * @param {String} apiRoot - API root URL.
 * @return {String} The normalized root URL if given a string, or an empty string if not.
 * @private
 */
function normalizeApiRoot(apiRoot) {
  return isBlank(apiRoot) || apiRoot.endsWith("/") ? apiRoot : `${apiRoot}/`;
}

/**
 * Normalize validated client parameters.
 *
 * @param {Object} apiRequestParameters - Object containing API client parameters.
 * @param {String} apiRequestParameters.rootUrl - Root URL for API.
 * @param {String} apiRequestParameters.token - Bearer token for API authentication.
 * @return {Object}
 * @private
 */
function normalizeClientParameters(apiRequestParameters) {
  return {
    fetch: get("fetch", apiRequestParameters),
    rootUrl: pipe(get("rootUrl"), coerceToString, normalizeApiRoot)(apiRequestParameters),
    token: pipe(get("token"), coerceToString)(apiRequestParameters)
  };
}

/**
 * Facade for interacting with an API.
 */
class ApiClient {
  /**
   * Creates an ApiClient without validating parameters.
   *
   * @param {Object} apiRequestParameters - Object containing API client parameters.
   * @param {Function} apiRequestParameters.fetch - Function for performing HTTP communication.
   * Expected to match the Fetch API (Request -> Promise<Response>).
   * @param {String} apiRequestParameters.rootUrl - Root URL for API.
   * @param {String} apiRequestParameters.token - Bearer token for API authentication.
   */
  constructor(apiRequestParameters) {
    if (!apiRequestParameters) {
      throw new Error("ApiClient parameters required");
    }

    if (!apiRequestParameters.fetch) {
      throw new Error("ApiClient parameters must have .fetch property.");
    }

    this.delete = createDelete(apiRequestParameters.fetch, apiRequestParameters);
    this.get = createGet(apiRequestParameters.fetch, apiRequestParameters);
    this.post = createPost(apiRequestParameters.fetch, apiRequestParameters);
    this.put = createPut(apiRequestParameters.fetch, apiRequestParameters);
  }

  // Disable command below addresses conflict between ESLint settings and Either API.
  /* eslint-disable new-cap */

  /**
   * Attempts to create an ApiClient.
   *
   * @param {Object} apiRequestParameters - Object containing API client parameters.
   * @param {Function} apiRequestParameters.fetch - Function for performing HTTP communication.
   * Expected to match the Fetch API (Request -> Promise<Response>).
   * @param {String} apiRequestParameters.rootUrl - Root URL for API.
   * @param {String} apiRequestParameters.token - Bearer token for API authentication.
   * @return {Either.<Error,ApiClient>} Either an error or the API client.
   */
  static tryCreate(apiRequestParameters) {
    return Either.try(() => new ApiClient(normalizeClientParameters(apiRequestParameters)))();
  }
}

export default ApiClient;
