// Project
import {
  DRAFT_LIST_LOADING_FAILURE,
  DRAFT_LIST_LOADING_START,
  DRAFT_LIST_LOADING_SUCCESS,
  DRAFTS_SELECTED
} from "../constants/ActionTypes";

/**
 * Signals a failure to load the drafts list.
 *
 * @param {String} mailboxAddress - Mailbox identifier.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function draftListLoadingFailure(mailboxAddress, error) {
  return {
    error: error,
    mailboxAddress: mailboxAddress,
    type: DRAFT_LIST_LOADING_FAILURE
  };
}

/**
 * Signals to start loading the drafts list.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @return {Object} Action object.
 */
export function draftListLoadingStart(mailboxAddress) {
  return {
    mailboxAddress: mailboxAddress,
    type: DRAFT_LIST_LOADING_START
  };
}

/**
 * Signals a successful draft-message list retrieval.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} drafts - Draft message list.
 * @return {Object} Action object.
 */
export function draftListLoadingSuccess(mailboxAddress, drafts) {
  return {
    drafts: drafts,
    mailboxAddress: mailboxAddress,
    type: DRAFT_LIST_LOADING_SUCCESS
  };
}

/**
 * Signals that the drafts navigation item has been selected.
 *
 * @param {String} mailboxAddress - Mailbox identity
 * @return {Object} Action object.
 */
export function draftsSelected(mailboxAddress) {
  return {
    mailboxAddress: mailboxAddress,
    type: DRAFTS_SELECTED
  };
}
