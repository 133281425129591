// Third Party
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import AddressPillbox from "../AddressPills/AddressPillbox";

export const ccAddressInputId = "draft-cc-address";

/* eslint-disable max-lines-per-function */
/**
 * Draft CC addresses.
 *
 * @constructor
 * @param {Object} props - Rendering properties.
 */
function CcAddressees(props) {
  /* eslint-disable arrow-body-style */
  const handleInputBlur = event => {
    return props.onBlur(event.target.value);
  };

  /* eslint-disable arrow-body-style */
  const handleInputChange = event => {
    return props.onChange(event.target.value);
  };

  return (
    <div id="draft-cc-address-wrapper">
      <AddressPillbox
        addressees={ props.addressees }
        onAddresseeClick={ props.onCcAddresseeClick }
      />
      <Input
        value={ props.workingCcAddress }
        id={ccAddressInputId}
        onBlur = { handleInputBlur }
        onChange={ handleInputChange }
        onKeyUp={ handleInputChange }
        type="text"
      />
    </div>
  );
}
/* eslint-enable max-lines-per-function */

CcAddressees.propTypes = {
  addressees: PropTypes.array,
  onBlur: PropTypes.func,
  onCcAddresseeClick: PropTypes.func,
  onChange: PropTypes.func,
  workingCcAddress: PropTypes.string
};

export default CcAddressees;
