// Project
import {
  CURRENT_FOLDER_WORKING_NAME_CHANGED,
  EDIT_FOLDER_NAME_ABORT,
  EDIT_FOLDER_NAME_BEGIN,
  EDIT_FOLDER_NAME_SAVE,
  FOLDER_NAME_UPDATE_FAILURE,
  FOLDER_NAME_UPDATE_START,
  FOLDER_NAME_UPDATE_SUCCESS
} from "../../constants/ActionTypes";

/**
 * Signal that the folder's working display name has changed.
 *
 * @param {String} mailboxAddr - Mailbox address.
 * @param {String} folderId - Folder identity.
 * @param {String} currentFolderWorkingDisplayName  - New folder display name.
 * @return {Object} Action object.
 */
export function currentFolderWorkingNameChanged(mailboxAddr, folderId, currentFolderWorkingDisplayName) {
  return {
    currentFolderWorkingDisplayName: currentFolderWorkingDisplayName,
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: CURRENT_FOLDER_WORKING_NAME_CHANGED
  };
}

/**
 * Signal the intent to close the folder-editor window.
 *
 * @param {String} mailboxAddr - Mailbox address.
 * @param {String} folderId - Folder identity.
 * @return {Object} Action object.
 */
export function editFolderNameAbort(mailboxAddr, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: EDIT_FOLDER_NAME_ABORT
  };
}

/**
 *  Signals start of folder-edit process.
 *
 * @param {String} mailboxAddr - Mailbox address.
 * @param {String} folderId - Current folder identity.
 * @param {String} currentFolderName - Current folder name.
 * @return {Object} Action object.
 */
export function editFolderNameBegin(mailboxAddr, folderId, currentFolderName) {
  return {
    currentFolderName: currentFolderName,
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: EDIT_FOLDER_NAME_BEGIN
  };
}

/**
 * Signals the start of the folder-name save process.
 *
 * @param {String} mailboxAddr - Mailbox address.
 * @param {String} folderId - Folder identity.
 * @return {Object} Action object.
 */
export function editFolderNameSave(mailboxAddr, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: EDIT_FOLDER_NAME_SAVE
  };
}

/**
 * Signal that the folder-name update failed.
 *
 * @param {String} mailboxAddr - Mailbox address;
 * @param {String} folderId - Folder identity.
 * @param {Object} error - Error object.
 * @return {Objet} Action object.
 */
export function folderNameUpdateFailure(mailboxAddr, folderId, error) {
  return {
    error: error,
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: FOLDER_NAME_UPDATE_FAILURE
  };
}

/**
 * Signal the start of the API call to update the folder name.
 *
 * @param {String} mailboxAddr - Mailbox address;
 * @param {String} folderId - Folder identity.
 * @param {String} displayName - Current folder's new display name.
 * @return {Object} Action object.
 */
export function folderNameUpdateStart(mailboxAddr, folderId, displayName) {
  return {
    displayName: displayName,
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: FOLDER_NAME_UPDATE_START
  };
}

/**
 * Signals the successful folder-name update.
 *
 * @param {String} mailboxAddr - Mailbox address;
 * @param {String} folderId - Folder identity.
 * @param {String} displayName - Current folder's new display name.
 * @return {Object} Action object.
 */
export function folderNameUpdateSuccess(mailboxAddr, folderId, displayName) {
  return {
    displayName: displayName,
    folderId: folderId,
    mailboxAddress: mailboxAddr,
    type: FOLDER_NAME_UPDATE_SUCCESS
  };
}
