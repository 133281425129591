// Third Party
import { debounceTime, groupBy, map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { ccAddressUpdateStart } from "../../actions/draftCcActions";
import { DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE } from "../../constants/ActionTypes";
import * as applicationConfig from "../../config/application";

const debounceLength = applicationConfig.draftView.debounceTime;
const isAutosaving = true;

/* eslint-disable arrow-body-style */
const ccWorkingAddressInProgressChangedEpic = action$ => action$.pipe(
  ofType(DRAFT_CC_WORKING_ADDRESS_IN_PROGRESS_CHANGE),
  groupBy(action => {
    return `${action.mailboxAddress}-${action.draftIdentity}`;
  }),
  mergeMap(group$ => group$.pipe(
    debounceTime(debounceLength),
    map(action => ccAddressUpdateStart(action.mailboxAddress, action.draftIdentity, isAutosaving)))
  )
);

export default ccWorkingAddressInProgressChangedEpic;
