// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { DRAFT_ATTACHMENT_REMOVE_SUCCESS } from "../../constants/ActionTypes.js";
import { draftSelected } from "../../actions/draftMessageActions";

const draftAttachmentRemoveSuccessEpic = action$ => action$.pipe(
  ofType(DRAFT_ATTACHMENT_REMOVE_SUCCESS),
  map(action => draftSelected(action.mailboxAddress, action.draftIdentity))
);

export default draftAttachmentRemoveSuccessEpic;
