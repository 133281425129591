// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the postFn with mailboxAddress and draftIdentity, if they are provided.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft identity.
 * @return {Promise}
 */
function postDraftToOutbox(postFn, mailboxAddress, draftIdentity) {
  return mailboxAddress && draftIdentity ?
    postFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/outbox`, {}) :
    Promise.reject(new Error("Mailbox address and draft identity are required"));
}

/**
 * Creates a function which will move a draft message to the outbox.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests draft be moved to the outbox when executed with mailbox address
 * and draft identity and returns a Promise containing the moved draft identity.
 */
function createRequestMoveDraftOutbox(postFn) {
  const hasGetFn = postFn && isFunction(postFn);

  return (mailboxAddress, draftIdentity) => (hasGetFn ?
    postDraftToOutbox(postFn, mailboxAddress, draftIdentity) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createRequestMoveDraftOutbox;
