import { postSignOutUnauthenticatedPath, responseHandlingPath } from "./OidcPaths";

/**
 * Page route handling OpenID Connect sign in responses.
 */
export const signInResponseProcessing = `/${responseHandlingPath}`;

/**
 * Page route for interacting with mailboxes and their messages. This is the primary application route during use.
 */
export const mailbox = "/mailbox";

/**
 * Page route for unauthenticated requests. Can be considered the 'home page'.
 */
export const unauthenticated = `/${postSignOutUnauthenticatedPath}`;

/**
 * Page route for SignInFailed page. Users are directed here when authentication fails.
 */
export const signInFailed = "/signin-failed";

/**
 * Page route for SignInRequested page. Users are directed here when authentication begins (prior to IdP redirect).
 */
export const signInRequested = "/signin-requested";
