// Third Party
import { Button } from "reactstrap";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/**
 * Create a button for adding a new folder.
 *
 * @constructor
 * @param {Object} props - New folder button properties.
 * @param {Function} props.onNewFolderClick - Click handler.
 */
function NewFolderButton(props) {
  const onClickHandler = () => props.onNewFolderClick();

  return (
    <Button
      disabled={props.disabledState}
      id="new-folder-button"
      aria-label="Create new folder"
      onClick={onClickHandler} >
      <FontAwesomeIcon icon={faPlusSquare} />
    </Button>
  );
}

NewFolderButton.propTypes = {
  disabledState: PropTypes.bool,
  onNewFolderClick: PropTypes.func
};

export default NewFolderButton;
