// Third Party
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import { currentFolderWorkingNameChanged, editFolderNameAbort, editFolderNameBegin, folderNameUpdateStart } from "../../actions/messageNavigation/folderEditActions";
import { DRAFT_LIST } from "../../constants/ListViewPanes";
import { draftsSelected } from "../../actions/draftsActions";
import { folderCreateStart, newFolderNameChanged } from "../../actions/messageNavigation/folderCreationActions";
import { mailboxExited } from "../../actions/mailboxActions";
import { mailboxFolderSelected } from "../../actions/messageNavigationActions";
import ActiveMailbox from "./ActiveMailbox";
import ComposeMessageButton from "./ComposeMessageButton";
import Folders from "./Folders";

/* eslint-disable max-lines-per-function */
/**
 * Message navigation container.
 *
 * @constructor
 * @param {Object} props - Message Navigation properties.
 * @param {String} props.currentFolderId - Currently select mailbox folder.
 * @param {String} props.mailboxAddress - Current mailbox identifier of the user.
 * @param {Array} props.mailboxFolders - User-created mailbox folders.
 * @param {Array} props.systemFolders - Non-user-created mailbox folders.
 * @param {String} props.workingFolderName - User-entered, in-progress, name for a new folder.
 */
function MessageNavigation(props) {
  const onCurrentFolderNameChangeHandler = (folderId, workingName) => props.onCurrentFolderNameChange(
    props.mailboxAddress,
    folderId,
    workingName
  );
  const onDraftsClickHandler = () => props.onDraftsSelected(props.mailboxAddress);
  const onEditFolderAbortClickHandler = folderId => props.onFolderEditAbort(props.mailboxAddress, folderId);
  const onEditFolderClickHandler = (folderId, displayName) => props.onFolderEdit(
    props.mailboxAddress,
    folderId,
    displayName
  );
  const onEditFolderSaveClickHandler = (folderId, workingName) => props.onFolderNameUpdateStart(
    props.mailboxAddress,
    folderId,
    workingName
  );
  const onFolderClickHandler = folderId => props.onFolderSelected(props.mailboxAddress, folderId);
  const onNewFolderHandler = () => props.onFolderCreate(props.mailboxAddress, props.newFolderName);
  const onNewFolderNameChangeHandler = displayName => props.onNewFolderNameChange(props.mailboxAddress, displayName);

  return (
    <div id="message-navigation">
      <Card>
        <CardHeader onClick={props.onMailboxClick} className="selectable">
          <CardTitle tag="h5" style={({ margin: "0" })}>
            <ActiveMailbox className="align-self-center" mailbox={props.mailboxAddress} />
          </CardTitle>
        </CardHeader>
        <CardBody>
          <ComposeMessageButton/>
        </CardBody>
        <Folders
          currentFolderId={props.currentFolderId}
          currentFolderWorkingDisplayName={props.currentFolderWorkingDisplayName}
          draftsItemSelected={props.draftsItemSelected}
          onEditFolderAbortClick={onEditFolderAbortClickHandler}
          onEditFolderClick={onEditFolderClickHandler}
          mailboxFolders={props.mailboxFolders}
          onCurrentFolderNameChange={onCurrentFolderNameChangeHandler}
          onDraftsClick={onDraftsClickHandler}
          onEditFolderSaveClick={onEditFolderSaveClickHandler}
          onFolderClick={onFolderClickHandler}
          onNewFolderClick={onNewFolderHandler}
          onNewFolderNameChange={onNewFolderNameChangeHandler}
          systemFolders={props.systemFolders}
          showFolderNameEditor={props.editingFolderName}
          newFolderName = {props.newFolderName}
        />
      </Card>
    </div>
  );
}

MessageNavigation.propTypes = {
  currentFolderId: PropTypes.string.isRequired,
  currentFolderWorkingDisplayName: PropTypes.string,
  draftsItemSelected: PropTypes.bool,
  editingFolderName: PropTypes.bool,
  mailboxAddress: PropTypes.string,
  mailboxFolders: PropTypes.array.isRequired,
  newFolderName: PropTypes.string,
  onCurrentFolderNameChange: PropTypes.func,
  onDraftsSelected: PropTypes.func,
  onFolderCreate: PropTypes.func,
  onFolderEdit: PropTypes.func,
  onFolderEditAbort: PropTypes.func,
  onFolderNameUpdateStart: PropTypes.func,
  onFolderSelected: PropTypes.func,
  onMailboxClick: PropTypes.func,
  onNewFolderNameChange: PropTypes.func,
  systemFolders: PropTypes.array.isRequired
};

const mapDispatchToProps = {
  onCurrentFolderNameChange: currentFolderWorkingNameChanged,
  onDraftsSelected: draftsSelected,
  onFolderCreate: folderCreateStart,
  onFolderEdit: editFolderNameBegin,
  onFolderEditAbort: editFolderNameAbort,
  onFolderNameUpdateStart: folderNameUpdateStart,
  onFolderSelected: mailboxFolderSelected,
  onMailboxClick: mailboxExited,
  onNewFolderNameChange: newFolderNameChanged
};

const mapStateToProps = state => ({
  currentFolderId: state.messaging.messageNavigation.currentFolderId,
  currentFolderWorkingDisplayName: state.messaging.messageNavigation.currentFolderWorkingDisplayName,
  draftsItemSelected: state.messaging.listViewPane === DRAFT_LIST,
  editingFolderName: state.messaging.messageNavigation.editingFolderName,
  mailboxAddress: state.messaging.mailbox.address,
  mailboxFolders: state.messaging.messageNavigation.mailboxFolders,
  newFolderName: state.messaging.messageNavigation.newFolderDisplayName,
  systemFolders: state.messaging.messageNavigation.systemFolders
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageNavigation);
