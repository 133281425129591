// Project
import {
  MESSAGES_DELETE_FAILURE,
  MESSAGES_DELETE_START,
  MESSAGES_DELETE_SUCCESS
} from "../../constants/ActionTypes";

/**
 * Signals failure to delete messages.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Message identities.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function messagesDeleteFailure(mailboxAddress, messageIdentities, error) {
  return {
    error: error,
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGES_DELETE_FAILURE
  };
}

/**
 * Signals the start of multiple-message deletion.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Message identities.
 * @return {Object} Action object.
 */
export function messagesDeleteStart(mailboxAddress, messageIdentities) {
  return {
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGES_DELETE_START
  };
}

/**
 * Signals successful multiple-message deletion.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} messageIdentities - Messages identities.
 * @return {Object} Action object.
 */
export function messagesDeleteSuccess(mailboxAddress, messageIdentities) {
  return {
    mailboxAddress: mailboxAddress,
    messageIdentities: messageIdentities,
    type: MESSAGES_DELETE_SUCCESS
  };
}
