// Third Party
import { connect } from "react-redux";
import { ListGroup } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

// Project
import "./MessageList.css";
import { createMessagesMoveFolderRequest } from "../../actions/messages/messagesMoveFolderActions";
import { messageListMultiSelectItemsAdd, messageListMultiSelectItemsRemove } from "../../actions/messageListMultiSelectActions";
import { messageListRequested, messageSelected } from "../../actions/messageListActions";
import { messagesDeleteStart } from "../../actions/messages/messagesDeleteActions";
import formatEmailAddressDisplayAsArray from "../../util/formatEmailAddressDisplayAsArray";
import getFolderNameFromId from "../../util/getFolderNameFromId";
import getFullDate from "../../util/getFullDate";
import getListItemDisplayDate from "../../util/getListItemDisplayDate";
import ListViewItem from "../formElements/ListViewItem";
import MessageListFooter from "./MessageListFooter";
import MessageListHeader from "./MessageListHeader";
import messagesMoveTrashRequest from "../../actions/messages/messagesMoveTrashRequest";
import RequestNextPageButton from "./RequestNextPageButton";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

// Component Setup
const mapDispatchToProps = {
  onListViewItemsChecked: messageListMultiSelectItemsAdd,
  onListViewItemsUnchecked: messageListMultiSelectItemsRemove,
  onMessageListRequested: messageListRequested,
  onMessageSelected: messageSelected,
  onMessagesMoveFolderRequest: createMessagesMoveFolderRequest,
  onTrashDeleteClicked: messagesDeleteStart,
  onTrashDiscardClicked: messagesMoveTrashRequest
};

const inboxFolderItem = { displayName: "Inbox",
  folderId: "inbox" };

const mapStateToProps = state => {
  // NOTE(jeremiah.sanders): The .mailboxFolders array is used to display the move-to-folder dropdown.
  //   The order of array elements determines display order.
  //   The mailbox folders in state are ordered by messageNavigationReducer; adding the Inbox system folder as the
  //   initial element ensures it is the leading item, followed by the sorted mailbox-specific folders.
  const folders = [inboxFolderItem, ...state.messaging.messageNavigation.mailboxFolders];

  return {
    currentFolderId: state.messaging.messageNavigation.currentFolderId,
    currentFolderName: getFolderNameFromId(
      state.messaging.messageNavigation.currentFolderId,
      state.messaging.messageNavigation.systemFolders.concat(state.messaging.messageNavigation.mailboxFolders)),
    currentMessageId: state.messaging.messageList.currentMessageId,
    isListInferredComplete: state.messaging.messageList.isListInferredComplete,
    mailboxAddress: state.messaging.mailbox.address,
    mailboxFolders: folders,
    messageHeaders: state.messaging.messageList.messageHeaders,
    messageListLoading: state.messaging.messageList.messageListLoading,
    messageListLoadingError: state.messaging.messageList.messageListLoadingError,
    selectedMessages: state.messaging.messageList.selectedMessages
  };
};

/* eslint-disable max-lines-per-function */
/**
 * Create the list of messages.
 *
 * @private
 * @constructor
 * @param {Object} props - Message array element.
 */
function ToMessageListItem(props) {
  const checkElement = value => value === props.messageIdentity;
  const checkboxIsSelected = props.selectedMessages.find(checkElement);
  const checkboxTitle = checkboxIsSelected ? "Select message" : "Deselect message";
  const checkChangedHandler = () => (checkboxIsSelected ?
    props.onListViewItemsUnchecked(props.mailboxAddress, [props.messageIdentity]) :
    props.onListViewItemsChecked(props.mailboxAddress, [props.messageIdentity]));
  const clickHandler = () => props.onMessageSelected(props.mailboxAddress, props.messageIdentity);

  return <ListViewItem
    checkboxTitle={checkboxTitle}
    displayAddresses={formatEmailAddressDisplayAsArray(props.fromAddress)}
    dateTimeDisplay={props.dateTimeDisplay}
    dateTimeFull={props.dateTimeFull}
    hasAttachments={props.hasAttachments}
    itemSelected={props.messageSelected}
    key={props.messageIdentity + props.subject}
    onCheckChanged={checkChangedHandler}
    onClick={clickHandler}
    shortBody={props.shortBody}
    state={props.state}
    subject={props.subject}
  />;
}

ToMessageListItem.propTypes = {
  dateTimeDisplay: PropTypes.string.isRequired,
  dateTimeFull: PropTypes.string.isRequired,
  fromAddress: PropTypes.object.isRequired,
  hasAttachments: PropTypes.bool,
  key: PropTypes.string,
  mailboxAddress: PropTypes.string.isRequired,
  messageIdentity: PropTypes.string.isRequired,
  messageSelected: PropTypes.bool,
  onListViewItemsChecked: PropTypes.func,
  onListViewItemsUnchecked: PropTypes.func,
  onMessageSelected: PropTypes.func,
  receivedDateTime: PropTypes.string,
  selectedMessages: PropTypes.array,
  shortBody: PropTypes.string,
  state: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired
};

/**
 * Build the message List container.
 *
 * @param {Object} props - Message list properties.
 * @constructor
 */
function MessageList(props) {
  const messageComparisonDate = new Date(); // NOTE(jeremiah.sanders): This is equivalent to C# DateTime.UtcNow
  const messageListItems = props.messageHeaders
    .map(header => {
      const receivedDate = new Date(header.receivedDateTime);

      return (
        { ...header,
          dateTimeDisplay: getListItemDisplayDate(receivedDate, messageComparisonDate),
          dateTimeFull: getFullDate(receivedDate),
          hasAttachments: header.numberOfAttachments > 0,
          mailboxAddress: props.mailboxAddress,
          messageSelected: props.currentMessageId === header.messageIdentity,
          onListViewItemsChecked: props.onListViewItemsChecked,
          onListViewItemsUnchecked: props.onListViewItemsUnchecked,
          onMessageSelected: props.onMessageSelected,
          selectedMessages: props.selectedMessages }
      );
    })
    .map(ToMessageListItem);
  const messageMoveFolderClickHandler = folderId => props.onMessagesMoveFolderRequest(
    props.mailboxAddress,
    folderId
  );
  const trashClickHandler = props.currentFolderId === "trash" ?
    () => props.onTrashDeleteClicked(props.mailboxAddress, props.selectedMessages) :
    () => props.onTrashDiscardClicked(props.mailboxAddress, props.selectedMessages);
  const multiItemActionsDisabled = !(props.selectedMessages || []).length;

  // NOTE(jeremiah.sanders): These "istanbul ignore next" comments exclude the lines from code coverage by Jest.
  //   Jest wraps Istanbul, according to: https://jestjs.io/docs/troubleshooting#coveragepathignorepatterns-seems-to-not-have-any-effect
  //   The ignored variables depend upon IntersectionObserver, a browser-only API.
  /* istanbul ignore next */
  const requestNextPage = () => {
    props.onMessageListRequested(props.mailboxAddress, props.currentFolderId, props.messageHeaders.length);
  };

  const { setObservedElementReference } = useIntersectionObserver({
    callback: requestNextPage,
    dependencies: [
      props.currentFolderId,
      props.mailboxAddress,
      props.messageHeaders,
      props.onMessageListRequested
    ],
    options: {
      threshold: 0
    }
  });

  /* istanbul ignore next */
  const nextPage = props.isListInferredComplete ?
    <RequestNextPageButton /> :
    <div ref={setObservedElementReference}><RequestNextPageButton /></div>;

  // NOTE(jeremiah.sanders): The `ref` below must be on a JSX.IntrinsicElement to receive a valid DOM element reference.
  return (
    <div id="message-list-wrapper">
      <MessageListHeader
        folderId={props.currentFolderId}
        folderName={props.currentFolderName}
        folders={props.mailboxFolders}
        multiItemActionsDisabled={multiItemActionsDisabled}
        onMoveFolderClick={messageMoveFolderClickHandler}
        onTrashClick={trashClickHandler} />
      <ListGroup id="message-list">{messageListItems}</ListGroup>
      { nextPage }
      <MessageListFooter messageCount={messageListItems.length} />
    </div>
  );
}

MessageList.propTypes = {
  currentFolderId: PropTypes.string,
  currentFolderName: PropTypes.string,
  currentMessageId: PropTypes.string,
  isListInferredComplete: PropTypes.bool,
  mailboxAddress: PropTypes.string.isRequired,
  mailboxFolders: PropTypes.array,
  messageHeaders: PropTypes.array.isRequired,
  onListViewItemsChecked: PropTypes.func,
  onListViewItemsUnchecked: PropTypes.func,
  onMessageListRequested: PropTypes.func,
  onMessageSelected: PropTypes.func.isRequired,
  onMessagesMoveFolderRequest: PropTypes.func,
  onTrashDeleteClicked: PropTypes.func,
  onTrashDiscardClicked: PropTypes.func,
  selectedMessages: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
