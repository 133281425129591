// Third Party
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { ofType } from "redux-observable";
import isString from "lodash/fp/isString";

// Project
import { MESSAGE_RETRIEVED } from "../constants/ActionTypes";
import { messageLoadingEnd, setMessageState } from "../actions/messageViewActions";

/**
 * Converts state reported by messaging service to a Boolean.
 * @param {String} apiSeenState - Seen state reported by messaging service. Should be either "Seen" or "Unseen".
 * @return {Boolean} - True if message is seen or False if not. Unexpected API values convert to False.
 */
function isSeen(apiSeenState) {
  return isString(apiSeenState) && apiSeenState.toLowerCase() === "seen";
}

const messageRetrievedEpic = action$ => action$.pipe(
  ofType(MESSAGE_RETRIEVED),
  mergeMap(action => (isSeen(action.message.state) ?
    of(messageLoadingEnd(action.mailboxAddress, action.messageIdentity)) :
    of(
      messageLoadingEnd(action.mailboxAddress, action.messageIdentity),
      setMessageState(action.mailboxAddress, action.messageIdentity, true)
    ))
  )
);

export default messageRetrievedEpic;
