/**
 * Extracts the draft identity from a draft URL.
 *
 * @param {String} location - A draft URL (api/mailbox/${mailboxAddress}/draft/${draftIdentity}).
 * @return {String}
 * @private
 */
function getDraftIdentityFromLocation(location) {
  return location.substring(location.lastIndexOf("/") + 1);
}

export default getDraftIdentityFromLocation;
