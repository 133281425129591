// Third Party
import { faFileDownload, faPaperclip, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";

/**
 * Build attachment-list item.
 *
 * @constructor
 * @param {Object} props - Function properties.
 */
function AttachmentListItem({ fileName, hideDownload, onRemoveClick, onAttachmentClick, onDownloadClick, ...rest }) {
  const showDownloadButton = !hideDownload;

  // NOTE (jeremiah.sanders): The logical && operators below enable conditional rendering.
  // See: https://reactjs.org/docs/conditional-rendering.html#inline-if-with-logical--operator
  return (
    <ButtonGroup {...rest}>
      <Button outline onClick={onAttachmentClick} aria-label={fileName} title={fileName}>
        <FontAwesomeIcon icon={faPaperclip} /> {fileName}
      </Button>
      {showDownloadButton &&
        <Button outline color="primary" onClick={onDownloadClick}
          aria-label={`Download ${fileName}`} title={`Download ${fileName}`}>
          <FontAwesomeIcon icon={faFileDownload} />
        </Button>}
      {onRemoveClick &&
        <Button outline color="danger" onClick={onRemoveClick}
          aria-label={`Remove ${fileName}`} title={`Remove ${fileName}`}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>}
    </ButtonGroup>
  );
}

AttachmentListItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  hideDownload: PropTypes.bool,
  onAttachmentClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onRemoveClick: PropTypes.func
};

export default AttachmentListItem;
