// Third party
import isFunction from "lodash/fp/isFunction";
import isArray from "lodash/fp/isArray";

/**
 * Executes the putFn with the TO addressees API URL and a body object containing the TO addressees.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} draftIdentity - Draft to modify.
 * @param {String} toAddressees - New TO addressees.
 * @return {Promise.<Object>} Promise of object with draftIdentity, mailboxAddress, and toAddresses.
 */
function putMailboxDraftToAddressees(putFn, mailboxAddress, draftIdentity, toAddressees) {
  return mailboxAddress && draftIdentity && isArray(toAddressees) ?
    putFn(`api/mailbox/${mailboxAddress}/draft/${draftIdentity}/to`, { to: toAddressees })
      .then(() => (
        { draftIdentity,
          mailboxAddress,
          // Property name chosen to match property returned by GET /api/mailbox/{mailbox}/draft/{draftIdentity}
          toAddresses: toAddressees }
      )) :
    Promise.reject(new Error("Mailbox address, draft identity, and to addressees are required"));
}

/**
 * Creates a function which will update a mailbox draft TO addressees.
 *
 * @param {Function} putFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which updates a mailbox draft TO addressees when executed and returns a Promise
 * containing the same mailbox address, draft identity, and TO addressees.
 */
function createPutMailboxDraftToAddressees(putFn) {
  const hasPutFn = putFn && isFunction(putFn);

  return (mailboxAddress, draftIdentity, toAddressees) => (hasPutFn ?
    putMailboxDraftToAddressees(putFn, mailboxAddress, draftIdentity, toAddressees) :
    Promise.reject(new Error("HTTP PUT function is required but was not provided")));
}

export default createPutMailboxDraftToAddressees;
