// Third party
import isString from "lodash/isString";

/**
 * Default mailbox value when mailbox was not specified.
 *
 * Value is intentionally formatted incorrectly (i.e., not an email address) so that the failure will be captured as a
 * 400 Bad Request, rather than requiring additional failure handling on the request creation.
 *
 * This should never be reached in live application flow, as it means that our actions contained insufficient data or
 * there was a code error which should have been caught in tests.
 */
const defaultMailbox = "mailbox-not-provided";

/**
 * Ensures that a potential mailbox value is a string. Intended for use with mailbox API URL creation functions.
 * @param {Object} possibleMailbox - A possible mailbox string.
 * @return {String} The initial mailbox value, if a truthy string, or a default mailbox string if not.
 */
function normalizeMailbox(possibleMailbox) {
  return possibleMailbox && isString(possibleMailbox) ? possibleMailbox : defaultMailbox;
}

export default normalizeMailbox;
