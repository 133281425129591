// Third party
import isFunction from "lodash/fp/isFunction";

/**
 * Executes the postFn with a new folder URL and an API request body, if all parameters are provided.
 *
 * @param {Function} postFn - Function which accepts a URL and body object and then returns a Promise.
 * @param {String} mailboxAddress - Mailbox to query.
 * @param {String} displayName - Folder display name..
 * @return {Promise.<NewFolder>}
 */
function postNewFolder(postFn, mailboxAddress, displayName) {
  return mailboxAddress && displayName ?
    postFn(`api/mailbox/${mailboxAddress}/folders`, { displayName: displayName })
      .then(response => response.json()) :
    Promise.reject(new Error("Mailbox address, and display name are required"));
}

/**
 * Creates a function which will create a new mailbox folder.
 *
 * @param {Function} postFn - Function which accepts a URI and body object and then returns a Promise.
 * @return {Function} Function which requests a new folder be created when executed with a mailbox address and folder
 * display name and returns a Promise containing the created folder.
 */
function createRequestNewFolder(postFn) {
  const hasGetFn = postFn && isFunction(postFn);

  return (mailboxAddress, displayName) => (hasGetFn ?
    postNewFolder(postFn, mailboxAddress, displayName) :
    Promise.reject(new Error("HTTP POST function is required but was not provided")));
}

export default createRequestNewFolder;
