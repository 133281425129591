// Third Party
import { constant } from "lodash";

// Project
import { MESSAGE_LOADING_ERRED } from "../../../constants/ActionTypes";
import { MESSAGE_LOADING_ERROR_DISPLAY } from "../../../constants/Errors";
import fromFailureAction from "../../util/fromFailureAction";

const messageLoadingErredEpic = fromFailureAction(
  MESSAGE_LOADING_ERRED,
  constant(MESSAGE_LOADING_ERROR_DISPLAY)
);

export default messageLoadingErredEpic;
