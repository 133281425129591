// Third Party
import { map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { bodyUpdateStart } from "../../actions/draftBodyActions";
import { DRAFT_BODY_CHANGED } from "../../constants/ActionTypes";
/* eslint-disable arrow-body-style */
const bodyChanged = action$ => action$.pipe(
  ofType(DRAFT_BODY_CHANGED),
  map(action => bodyUpdateStart(action.mailboxIdentity, action.draftIdentity, action.workingBody))
);

export default bodyChanged;
