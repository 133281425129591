/**
 * Format and email address object for display
 *
 * @private
 * @param  {Object} emailAddress - From address object
 * @param  {String} emailAddress.displayName - "From" name
 * @param  {String} emailAddress.address - "From" email address
 * @return {String} Formatted display of name or address
 */
export function formatEmailAddressDisplay(emailAddress) {
  if (emailAddress && emailAddress.address) {
    return emailAddress.displayName ? emailAddress.displayName : emailAddress.address;
  }

  return "";
}
