// Project
import {
  MAILBOX_FOLDER_SELECTED,
  MAILBOX_FOLDERS_LOADING_FAILURE,
  MAILBOX_FOLDERS_LOADING_START,
  MAILBOX_FOLDERS_LOADING_SUCCESS
} from "../constants/ActionTypes";

/**
 * Signal the selection of a mailbox folder.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {String} folderId - Folder Identity.
 * @return {Object} Action object.
 */
export function mailboxFolderSelected(mailboxAddress, folderId) {
  return {
    folderId: folderId,
    mailboxAddress: mailboxAddress,
    type: MAILBOX_FOLDER_SELECTED
  };
}

/**
 * Signals an error retrieving the mailbox folders list.
 *
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Object} error - Error object.
 * @return {Object} Action object.
 */
export function mailboxFoldersLoadingFailure(mailboxAddress, error) {
  return {
    error: error,
    mailboxAddress: mailboxAddress,
    type: MAILBOX_FOLDERS_LOADING_FAILURE
  };
}

/**
 * Signals the intent to fetch the mailbox folders list.
 *
 * @param {String} mailboxAddress - Mailbox identifier
 * @return {Object} Action object.
 */
export function mailboxFoldersLoadingStart(mailboxAddress) {
  return {
    mailboxAddress: mailboxAddress,
    type: MAILBOX_FOLDERS_LOADING_START
  };
}

/**
 *  Signals successful retrieval of the mailbox folders list.
 * @param {String} mailboxAddress - Mailbox identity.
 * @param {Array} mailboxFolders - List of mailbox folders.
 * @param {Array} systemFolders - List of system folders.
 * @return {Object} Action object.
 */
export function mailboxFoldersLoadingSuccess(mailboxAddress, mailboxFolders, systemFolders) {
  return {
    mailboxAddress: mailboxAddress,
    mailboxFolders: mailboxFolders,
    systemFolders: systemFolders,
    type: MAILBOX_FOLDERS_LOADING_SUCCESS
  };
}

