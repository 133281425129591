// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { messagesDeleteFailure, messagesDeleteSuccess } from "../actions/messages/messagesDeleteActions";
import { MESSAGES_DELETE_START } from "../constants/ActionTypes.js";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const messagesDeleteStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MESSAGES_DELETE_START),
  mergeMap(action => from(messagingClient.deleteMessages(action.mailboxAddress, action.messageIdentities))
    .pipe(
      map(() => messagesDeleteSuccess(action.mailboxAddress, action.messageIdentities)),
      catchError(error => of(messagesDeleteFailure(action.mailboxAddress, action.messageIdentities, error)))
    )
  )
);

export default messagesDeleteStartEpic;
