// Third Party
import { delay, filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import * as applicationConfiguration from "../../config/application.json";
import { NOTIFICATION_DISMISS_DELAYED } from "../../constants/ActionTypes";
import { notificationDismiss } from "../../actions/notificationActions";
import { tryGetNotification } from "../../selectors/messaging/notifications";

/**
 * Determines if a delayed dismissal should request a dismissal.
 * @private
 * @param {{triggeringActionType: String, timestamp: Number}} delayedDismissAction - An action.
 * @param {Object} state - Current state.
 * @return {Boolean} - Returns true if the notification still exists and has not been requested for exclusion from
 * delayed dismissal.
 */
function shouldRequestDismissal(delayedDismissAction, state) {
  const notifications = tryGetNotification(
    state,
    delayedDismissAction.triggeringActionType,
    delayedDismissAction.timestamp
  );
  const anyTargetNotifications = Boolean(notifications.length);
  const excludeFromDismissal = notifications.reduce(
    (shouldExclude, notification) => shouldExclude || Boolean(notification.excludeFromDelayedDismissal),
    false
  );

  return anyTargetNotifications && !excludeFromDismissal;
}

// The workflow here is:
//   - Receive "dismiss after delay".
//   - Wait for a minimum display period.
//   - Check to see if the notification still exists (e.g., hasn't been dismissed)
//     and that we're not avoiding automatic dismissal (e.g., by hovering over notification).
//   - If we're good, emit a dismissal.

const notificationDismissDelayedEpic = (action$, state$) => action$.pipe(
  ofType(NOTIFICATION_DISMISS_DELAYED),
  delay(applicationConfiguration.notifications.dismissalDelay),
  filter(action => shouldRequestDismissal(action, state$.value)),
  map(action => notificationDismiss(action.triggeringActionType, action.timestamp))
);

export default notificationDismissDelayedEpic;
