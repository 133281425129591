// Third Party
import { filter, map } from "rxjs/operators";
import { ofType } from "redux-observable";

// Project
import { messageListMultiSelectItemsClear } from "../../actions/messageListMultiSelectActions";
import { MESSAGES_MOVE_TRASH_SUCCESS } from "../../constants/ActionTypes.js";

/**
 * Emits a request to (re)load the current message folder headers after trashing messages.
 * @param {Observable} action$ - An action observable.
 * @param {Observable} state$ - State observable.
 * @return {Observable} - An arranged observable.
 */
const messagesMoveTrashSuccessClearSelectedMessagesEpic = (action$, state$) => action$.pipe(
  ofType(MESSAGES_MOVE_TRASH_SUCCESS),
  filter(() => state$.value.messaging.messageList.selectedMessages.length !== 1 ||
    state$.value.messaging.messageList.selectedMessages[0] !== state$.value.messaging.messageList.currentMessageId),
  map(action => messageListMultiSelectItemsClear(action.mailboxAddress))
);

export default messagesMoveTrashSuccessClearSelectedMessagesEpic;
