// Project
import {
  DRAFT_SUBJECT_CHANGED,
  DRAFT_SUBJECT_UPDATE_FAILURE,
  DRAFT_SUBJECT_UPDATE_START,
  DRAFT_SUBJECT_UPDATE_SUCCESS
} from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Add update error notification to application state.
 *
 * @param {Object} state - Application state.
 * @return {Object} Updated application state.
 */
function subjectUpdateFailure(state) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: false }
  };
}

/**
 * Update the draft autosave flag in application state.
 *
 * @param {String} state - Application stated.
 * @param {Object} action - Update start action.
 * @return {Object} Updated application state.
 */
function subjectUpdateStart(state, action) {
  return {
    ...state,
    draftView: { ...state.draftView,
      draftAutosaving: action.draftAutosaving }
  };
}

/**
 * Updated application state with subject from API
 *
 * @param {Object} state - Application state.
 * @param {Object} action - Subject updated action.
 * @return {Objet} Updated application state.
 */
function subjectUpdateSuccess(state, action) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      draftAutosaving: false,
      subject: action.subject
    }
  };
}

/**
 * Update the state with the changed subject.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Updated application state.
 */
function subjectChanged(state, action) {
  return {
    ...state,
    draftView: {
      ...state.draftView,
      subject: action.workingSubject
    }
  };
}

/**
 * Combine the draft message reducers.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action type.
 * @return {Object} Updated application state.
 */
export function draftSubjectReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case DRAFT_SUBJECT_CHANGED: return subjectChanged(state, action);

      case DRAFT_SUBJECT_UPDATE_FAILURE: return subjectUpdateFailure(state, action);

      case DRAFT_SUBJECT_UPDATE_SUCCESS: return subjectUpdateSuccess(state, action);

      case DRAFT_SUBJECT_UPDATE_START: return subjectUpdateStart(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}
