// Project
import {
  FOLDER_CREATE_SUCCESS,
  NEW_FOLDER_NAME_CHANGED
} from "../../constants/ActionTypes";
import initialState from "../../config/initialState";

/**
 * Blank the new-folder name in the application state.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object.
 * @return {Object} Application state.
 */
function folderCreateSuccess(state) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      newFolderDisplayName: ""
    }
  };
}

/**
 * Update the newFolderDisplayName in the application state
 *
 * @param {Object} state - Application state.
 * @param {Object} action - An action.
 * @return {Object} Application state.
 */
function newFolderNameChanged(state, action) {
  return {
    ...state,
    messageNavigation: {
      ...state.messageNavigation,
      newFolderDisplayName: action.displayName
    }
  };
}


/**
 * Combine folder-creation reducers.
 *
 * @param {Object} state - Current application state.
 * @param {Object} action - Action object;
 * @return {Object} Application state.
 */
function folderCreationReducer(state = initialState, action) {
  if (action && action.type) {
    switch (action.type) {
      case FOLDER_CREATE_SUCCESS: return folderCreateSuccess(state);

      case NEW_FOLDER_NAME_CHANGED: return newFolderNameChanged(state, action);

      default: return state;
    }
  } else {
    return state;
  }
}

export default folderCreationReducer;
