// Third Party
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Project
import "./Mailbox.css";
import { tryGetMailboxAddress } from "../selectors/messaging/mailbox";
import DetailView from "../components/DetailView";
import ListView from "../components/ListView";
import MailboxSelector from "../components/MailboxSelector";
import MessageNavigation from "../components/MessageNavigation";
import NotificationView from "../components/NotificationView";
import PageHeader from "../components/PageHeader";

// NOTE(jeremiah.sanders): "m-4" is a Bootstrap utility class applying a margin of "$spacer * 1.5".
//  See: https://getbootstrap.com/docs/4.0/utilities/spacing/
const NoMailboxSelected = () => <div className="m-4"><MailboxSelector /></div>;

const MailboxSelected = () => <div aria-label="Mailbox contents" className="mailbox-page-body-container">
  <div className="mailbox-page-body-navigation">
    <MessageNavigation />
  </div>
  <div className="mailbox-page-body-list">
    <ListView />
  </div>
  <div className="mailbox-page-body-detail">
    <DetailView />
  </div>
</div>;

/**
 * Mailbox page.
 * @constructor
 * @param {Object} props - Rendering properties.
 * @param {Boolean} props.hasMailbox - Flag indicating whether an active mailbox is selected.
 */
function Mailbox(props) {
  const bodyContent =
    props.hasMailbox ?
      <MailboxSelected /> :
      <NoMailboxSelected />;

  return (
    <div className="mailbox-page-container">
      <div className="mailbox-page-header">
        <PageHeader />
      </div>
      <div className="mailbox-page-body">
        <div className="notification-container"><NotificationView /></div>
        {bodyContent}
      </div>
    </div>
  );
}

Mailbox.propTypes = {
  hasMailbox: PropTypes.bool
};

const mapStateToProps = state => ({
  hasMailbox: Boolean(tryGetMailboxAddress(state))
});
const mapDispatchToProps = () => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(Mailbox);
