// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";


// Project
import { MAILBOX_FOLDERS_LOADING_START } from "../../constants/ActionTypes";
import { mailboxFoldersLoadingFailure, mailboxFoldersLoadingSuccess } from "../../actions/messageNavigationActions";

const mailboxFoldersLoadingStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(MAILBOX_FOLDERS_LOADING_START),
  mergeMap(action => from(messagingClient.getMailboxFolders(action.mailboxAddress))
    .pipe(
      map(response => mailboxFoldersLoadingSuccess(
        action.mailboxAddress,
        response.mailboxFolders,
        response.systemFolders)
      ),
      catchError(error => of(mailboxFoldersLoadingFailure(action.mailboxAddress, error)))
    )
  )
);

export default mailboxFoldersLoadingStartEpic;
