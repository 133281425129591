// Third Party
import { trimEnd, trimStart } from "lodash";

// Project
import getFullDate from "./getFullDate";

const relativeDate = (date, comparisonDate) => {
  const localeDateString = date.toLocaleString("en-us");
  const [dateString, timeString] = localeDateString
    .split(",")
    .map(trimStart)
    .map(trimEnd);
  const isToday = date.getFullYear() === comparisonDate.getFullYear() &&
  date.getMonth() === comparisonDate.getMonth() &&
  date.getDate() === comparisonDate.getDate();

  return isToday ? timeString : dateString;
};

/**
 * Converts a list item date to display format. Dates not on the same date as 'comparisonDate' are presented as a date,
 * as are all dates when 'comparisonDate' is not provided. Those on the same day are presented as a time.
 * @param {Date} date - A Date to display.
 * @param {Date} comparisonDate - A date representing a relative point.
 * @return {String}
 */
function getListItemDisplayDate(date, comparisonDate) {
  return comparisonDate ? relativeDate(date, comparisonDate) : getFullDate(date);
}

export default getListItemDisplayDate;
