// Third Party
import { catchError, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { ofType } from "redux-observable";

// Project
import { bodyUpdateFailure, bodyUpdateSuccess } from "../../actions/draftBodyActions";
import { DRAFT_BODY_UPDATE_START } from "../../constants/ActionTypes";

// Third parameter of epic is the "dependencies" property on the epic options passed to createEpicMiddleware()
const bodyUpdateStartEpic = (action$, state$, { messagingClient }) => action$.pipe(
  ofType(DRAFT_BODY_UPDATE_START),
  mergeMap(action => from(
    messagingClient.putMailboxDraftBody(action.mailboxIdentity, action.draftIdentity, action.workingBody))
    .pipe(
      map(response => bodyUpdateSuccess(action.mailboxIdentity, action.draftIdentity, response)),
      catchError(error => of(bodyUpdateFailure(action.mailboxIdentity, action.draftIdentity, error)))
    )
  )
);

export default bodyUpdateStartEpic;
