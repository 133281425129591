// Third Party
import { constant } from "lodash";

// Project
import { DRAFT_MOVE_OUTBOX_FAILURE } from "../../constants/ActionTypes";
import { DRAFT_MOVE_OUTBOX_FAILURE_DISPLAY } from "../../constants/Errors";
import fromFailureAction from "../util/fromFailureAction";

const draftMoveOutboxFailureEpic = fromFailureAction(
  DRAFT_MOVE_OUTBOX_FAILURE,
  constant(DRAFT_MOVE_OUTBOX_FAILURE_DISPLAY)
);

export default draftMoveOutboxFailureEpic;
