/**
 * Validate the size of an attachment.
 *
 * @param {Number} size - A file size.
 * @return {Boolean}
 */
function validAttachmentSize(size) {
  // 25 megabytes.
  const maxAttachmentSize = 26214400;

  return size > 0 && size <= maxAttachmentSize;
}

export default validAttachmentSize;
