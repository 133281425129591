// Third Party
import { constant } from "lodash";

// Project
import { MESSAGES_DELETE_FAILURE } from "../constants/ActionTypes";
import { MESSAGES_DELETE_FAILURE_DISPLAY } from "../constants/Errors";
import fromFailureAction from "./util/fromFailureAction";

const messagesDeleteFailureEpic = fromFailureAction(
  MESSAGES_DELETE_FAILURE,
  constant(MESSAGES_DELETE_FAILURE_DISPLAY)
);

export default messagesDeleteFailureEpic;
